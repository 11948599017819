import * as task12 from '../types/task12';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : task12.TASK12_RESET,
    });
  };
};

export const updateChoices = (payload) => {
  return dispatch => {
    dispatch({
      type : task12.TASK12_UPDATE_CHOICES,
      payload: payload
    });
  };
};

export const updateAnswers = (payload) => {
  return dispatch => {
    dispatch({
      type : task12.TASK12_UPDATE_ANSWERS,
      payload: payload
    });
  };
};

export const updateChosenAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type : task12.TASK12_UPDATE_CHOSEN_ANSWER,
      payload: payload
    });
  };
};

export const updateChoices2 = (payload) => {
  return dispatch => {
    dispatch({
      type : task12.TASK12_UPDATE_CHOICES2,
      payload: payload
    });
  };
};

export const updateAnswers2 = (payload) => {
  return dispatch => {
    dispatch({
      type : task12.TASK12_UPDATE_ANSWERS2,
      payload: payload
    });
  };
};