import React from "react";
import { connect } from "react-redux";
import "./includes/css/app.css";
import { Route, Switch } from "react-router-dom";

import Login from "./components/Login";
import Register from "./components/Register";
import PrivateRoute from "./components/PrivateRoute";
import Lost from "./components/Lost";
import Logout from "./components/Logout";

import Dashboard from "./components/main/Dashboard";
import Exam from "./components/Main";
import Review from "./components/main/Review";
import TextEditor from "./components/TextEditor";
import JsonGenerator from "./components/JsonGenerator";
import Paragraph from "./components/Paragraph";

import { withRouter } from "react-router-dom";

import IncompatibleBrowser from "./components/IncompatibleBrowser";
import { isMobile } from "react-device-detect";

class App extends React.Component {
  preventRightClick = (e) => {
    e.preventDefault();
  };
  render() {
    let browserAllowed = true;

    let osAllowed = true;
    if (isMobile) {
      osAllowed = false;
    }
    if (!browserAllowed || !osAllowed) {
      return <IncompatibleBrowser />;
    }
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/register" component={Register} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/logout" component={Logout} />
        <PrivateRoute path="/exam" component={Exam} />
        <PrivateRoute path="/review" component={Review} />
        <Route path="/wysiwyg" component={TextEditor} />
        <Route path="/json" component={JsonGenerator} />
        <Route path="/paragraph" component={Paragraph} />
        <Route component={Lost} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.app.currentPage,
  };
};

export default withRouter(connect(mapStateToProps)(App));
