import * as task1 from '../types/task1';

export const reset = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_RESET,
      payload : payload,
    });
  };
};

export const updateRecording = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_UPDATE_RECORDING,
      payload : payload,
    });
  };
};

export const updateReading = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_UPDATE_READING,
      payload : payload,
    });
  };
};

export const updateReadingCountdown = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_UPDATE_RECORDING_COUNTDOWN,
      payload : payload,
    });
  };
};

export const updateFinishedReading = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_UPDATE_FINISHED_READING,
      payload : payload,
    });
  };
};

export const updateTask1Started = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_UPDATE_TASK1_STARTED,
      payload : payload,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task1.TASK1_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};