import * as task5 from '../types/task5';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updatePlayBeep = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_PLAY_BEEP,
      payload : payload,
    });
  };
};

export const updateRecordingStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_RECORDING_STATUS,
      payload : payload,
    });
  };
};

export const updateRecordingTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_RECORDING_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_RECORDING_DURATION,
      payload : payload,
    });
  };
};

export const updateSequence = () => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_RESET_SEQUENCE,
    });
  };
};

export const updatePlayStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_PLAY_STATUS,
      payload : payload,
    });
  };
};

export const updatePlayDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_PLAY_DURATION,
      payload : payload,
    });
  };
};

export const updatePlayProgress = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_PLAY_PROGRESS,
      payload : payload,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};

export const updateBeginTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_BEGIN_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingComplete = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_RECORDING_COMPLETE,
      payload : payload,
    });
  };
};

export const updatePrepareTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_PREPARE_TIMER,
      payload : payload,
    });
  };
};

export const updatePreparing = (payload) => {
  return dispatch => {
    dispatch({
      type    : task5.TASK5_UPDATE_PREPARING,
      payload : payload,
    });
  };
};