import * as task10 from '../types/task10';

export const updateAnswers = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_ANSWERS,
      payload : payload,
    });
  };
};

export const updateCurrentAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_CURRENT_ANSWER,
      payload : payload,
    });
  };
};

export const updateCurrentIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_CURRENT_INDEX,
      payload : payload,
    });
  };
};

export const updateSentence = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_SENTENCE,
      payload : payload,
    });
  };
};

export const updateSentenceCon = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_SENTENCE_CON,
      payload : payload,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_ANSWER,
      payload : payload,
    });
  };
};

export const updateExamScore = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_EXAM_SCORE,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_RESET,
    });
  };
};

export const updateAnswers2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_ANSWERS2,
      payload : payload,
    });
  };
};

export const updateSentenceCon2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task10.TASK10_UPDATE_SENTENCE_CON2,
      payload : payload,
    });
  };
};