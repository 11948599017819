import * as actions from '../actions/types/listeningReview';

const defaultState = {
  exam13Count:0,
  exam14Count:0,
  exam15Count:0,
  exam16Count:0,
  exam17Count:0,
  exam18Count:0,
  exam19Count:0,
  exam20Count:0,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.LISTENING_REVIEW_UPDATE_QUESTION_COUNTS: {
      return {...action.payload};
    }
    default: return {...state};
  }
};