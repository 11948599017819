import React from "react";
import { Row, Col, Icon, Button, Collapse } from "antd";
import parse from "html-react-parser";
import { connect } from "react-redux";
import * as Common from "../common";
import * as actions from "../../actions/app/task11";
import * as strategyActions from "../../actions/app/strategy";
import * as appActions from "../../actions/app/app";
import * as EXAM_TYPES from "../../constants/exams";
import * as apiActions from "../../actions/app/api";
import * as api from "../../actions/api/requests";
import { Link } from "react-router-dom";
import * as stratActions from "../../actions/app/strategy";

const Panel = Collapse.Panel;

class FillInBlanks extends React.Component {
  componentDidMount() {
    const { dispatch, task, currentContent, strat_mode_on } = this.props;
    dispatch(apiActions.updateApiSending(false));
    this.clearTimer();
    if (!strat_mode_on) {
      dispatch(
        actions.updateAnswers(new Array(task[currentContent].blanks).fill(null))
      );
      const choices = task[currentContent].answers;
      dispatch(actions.updateChoices(choices));
    }
    if (!strat_mode_on) {
      this.startLearningStratTimer();
    }
    dispatch(
      actions.updateAnswers2(new Array(task[currentContent].blanks).fill(null))
    );
    const choices2 = task[currentContent].answers;
    dispatch(actions.updateChoices2(choices2));
  }
  componentWillUnmount() {
    this.props.dispatch(strategyActions.reset());
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  startLearningStratTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { dispatch, reading_timer, max_reading_timer } = this.props;
    const new_timer = reading_timer + 1;
    if (new_timer <= max_reading_timer) {
      dispatch(strategyActions.updateReadingTImer(new_timer));
    } else {
      this.clearTimer();
      this.nextTask();
    }
  }
  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }
  handleDragStart = (answer, e) => {
    this.props.dispatch(actions.updateChosenAnswer(answer));
  };
  onDragOver = (e) => {
    e.preventDefault();
  };
  nextTask() {
    const {
      dispatch,
      task,
      currentContent,
      answers,
      answers2,
      answerInstance,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      const newCurrentTask = currentContent + 1;
      this.updateAnswer(answers, answers2);
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "reading"
            )
          );
        }, 1500);
      } else {
        if (task[newCurrentTask].exam_type === EXAM_TYPES.TASK11) {
          dispatch(actions.reset());
          const choices = task[newCurrentTask].answers;
          dispatch(actions.updateChoices(choices));
          dispatch(
            actions.updateAnswers(
              new Array(task[newCurrentTask].blanks).fill(null)
            )
          );
          dispatch(actions.updateChoices2(choices));
          dispatch(
            actions.updateAnswers2(
              new Array(task[newCurrentTask].blanks).fill(null)
            )
          );
          dispatch(strategyActions.reset());
          this.clearTimer();
          this.startLearningStratTimer();
        }
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(stratActions.updateLearningStratModeOn(false));
      }
    } else {
      this.clearTimer();
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  }
  handleDrop = (e) => {
    const { dispatch, chosenAnswer, answers, choices } = this.props;
    const newAnswers = answers.slice();
    newAnswers[newAnswers.indexOf(chosenAnswer)] = null;
    const newChoices = choices.slice();
    if (newChoices.indexOf(chosenAnswer) === -1) {
      newChoices.push(chosenAnswer);
      dispatch(actions.updateChoices(newChoices));
      dispatch(actions.updateAnswers(newAnswers));
      dispatch(actions.updateChosenAnswer(""));
    }
  };
  handleDrop2 = (e) => {
    const { dispatch, chosenAnswer, answers2, choices2 } = this.props;
    const newAnswers = answers2.slice();
    newAnswers[newAnswers.indexOf(chosenAnswer)] = null;
    const newChoices = choices2.slice();
    if (newChoices.indexOf(chosenAnswer) === -1) {
      newChoices.push(chosenAnswer);
      dispatch(actions.updateChoices2(newChoices));
      dispatch(actions.updateAnswers2(newAnswers));
      dispatch(actions.updateChosenAnswer(""));
    }
  };
  onDragEnter = (e) => {
    e.preventDefault();
  };
  onDragOver = (e) => {
    e.preventDefault();
  };
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const newAnswerInstance = { ...answerInstance };
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "reading"));
  }
  resetAnswer() {
    const { dispatch, task, currentContent } = this.props;
    dispatch(actions.reset());
    const choices = task[currentContent].answers;
    dispatch(actions.updateChoices(choices));
    dispatch(
      actions.updateAnswers(new Array(task[currentContent].blanks).fill(null))
    );
  }
  render() {
    const { task, currentContent, strat_mode_on } = this.props;
    let content = task[currentContent].content;
    if (
      content.indexOf("<p>") !== 0 &&
      content.indexOf("</p>") !== content.length - 4
    ) {
      content = "<p>" + content + "</p>";
    }
    const choices = this.props.choices.map((item, index) => {
      return (
        <Col
          draggable
          span={4}
          className={
            strat_mode_on ? "no-pointer fill-blank-answer" : "fill-blank-answer"
          }
          key={"fill-blank-answer" + index}
          onDragStart={this.handleDragStart.bind(this, item)}
        >
          <p>
            <Icon type="appstore" /> {item}
          </p>
        </Col>
      );
    });

    const choices2 = this.props.choices2.map((item, index) => {
      return (
        <Col
          draggable
          span={4}
          className={"fill-blank-answer"}
          key={"fill-blank-answer" + index}
          onDragStart={this.handleDragStart.bind(this, item)}
        >
          <p>
            <Icon type="appstore" /> {item}
          </p>
        </Col>
      );
    });

    let error = false;
    let paragraph;
    try {
      const htmlContent = this.createElementFromHTML(content);
      const inputs = htmlContent.getElementsByTagName("input");
      let n = inputs.length;
      while (n--) {
        inputs[n].id = n;
      }

      paragraph = parse(htmlContent.outerHTML, {
        replace: function(domNode, index) {
          if (domNode.attribs && domNode.attribs.class === "replace") {
            return (
              <Common.Blank itemNumber={domNode.attribs.id} index={index} />
            );
          }
        },
      });
    } catch (e) {
      error = true;
    }
    let paragraph2;
    try {
      const htmlContent2 = this.createElementFromHTML(content);
      const inputs2 = htmlContent2.getElementsByTagName("input");
      let n = inputs2.length;
      while (n--) {
        inputs2[n].id = n;
      }

      paragraph2 = parse(htmlContent2.outerHTML, {
        replace: function(domNode, index) {
          if (domNode.attribs && domNode.attribs.class === "replace") {
            return (
              <Common.BlankTwo
                itemNumber={domNode.attribs.id}
                index={index}
                second_answer={true}
              />
            );
          }
        },
      });
    } catch (e) {
      error = true;
    }
    if (error) {
      return (
        <h3>
          Something went wrong. Please go back to the{" "}
          <Link to="/dashboard/speaking">home page</Link>.
        </h3>
      );
    }
    return (
      <div>
        <Row className="text-left height-50 gray-border">
          <Row className="height-80" onDragOver={(e) => this.onDragOver(e)}>
            <Col span={24}>
              <p className="fill-blank-content margin-auto">{paragraph}</p>
            </Col>
          </Row>
          <Row
            onDragEnter={this.onDragEnter.bind(this)}
            onDragOver={this.onDragOver.bind(this)}
            onDrop={this.handleDrop.bind(this)}
            type="flex"
            className="text-center height-20 fill-blank-container"
            justify="center"
          >
            {choices}
          </Row>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="1">
                    <Row className="text-left height-50 gray-border">
                      <Row
                        className="height-80"
                        onDragOver={(e) => this.onDragOver(e)}
                      >
                        <Col span={24}>
                          <p className="fill-blank-content margin-auto">
                            {paragraph2}
                          </p>
                        </Col>
                      </Row>
                      <Row
                        onDragEnter={this.onDragEnter.bind(this)}
                        onDragOver={this.onDragOver.bind(this)}
                        onDrop={this.handleDrop2.bind(this)}
                        type="flex"
                        className="text-center height-20 fill-blank-container"
                        justify="center"
                      >
                        {choices2}
                      </Row>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending}
              onClick={this.nextTask.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    choices: state.task11.choices,
    chosenAnswer: state.task11.chosenAnswer,
    answers: state.task11.answers,
    blanks: state.task11.blanks,
    user: state.profile,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    reading_timer: state.strategy.reading_timer,
    max_reading_timer: state.strategy.max_reading_timer,
    strat_mode_on: state.strategy.strat_mode_on,
    choices2: state.task11.choices2,
    answers2: state.task11.answers2,
  };
};

export default connect(mapStateToProps)(FillInBlanks);
