import React from 'react';
import {
  browserVersion,
  osName,
  browserName,
  osVersion,
} from 'react-device-detect';
import { Layout } from 'antd';
const { Content } = Layout;

class IncompatibleBrowser extends React.Component{
  render() {
    return (
      <React.Fragment>
        <Layout className="layout">
          <div className="main-content">
          <Content className="content-container text-center">
            Your browser is currently incompatible to use this app.
            <br/>
            <br/>
            You are currently using <b>{osName} {osVersion}</b> as your operating system and <b>{browserName} {browserVersion}</b> as your browser.
            <br/>
            <br/>
            This test requires you to use non-mobile or desktop Chrome (version 51 or higher) or Firefox (version 31 or higher).
          </Content>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default IncompatibleBrowser;