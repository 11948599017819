export const UPDATE_START_EXAM = 'UPDATE_START_EXAM';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const UPDATE_CURRENT_CONTENT = 'UPDATE_CURRENT_CONTENT';
export const UPDATE_RECORD_COUNTDOWN = 'UPDATE_RECORD_COUNTDOWN';
export const UPDATE_RECORD_COUNTDOWN_STARTED = 'UPDATE_RECORD_COUNTDOWN_STARTED';
export const UPDATE_RECORDING_STATUS = 'UPDATE_RECORDING_STATUS';
export const UPDATE_READING_COUNTDOWN = 'UPDATE_READING_COUNTDOWN';
export const UPDATE_READING_COUNTDOWN_STATUS = 'UPDATE_READING_COUNTDOWN_STATUS';
export const UPDATE_READING_STATUS = 'UPDATE_READING_STATUS';
export const UPDATE_FINISHED_READING = 'UPDATE_FINISHED_READING';
export const UPDATE_BEEP = 'UPDATE_BEEP';
export const RESET_AUDIO_RECORDER = 'RESET_AUDIO_RECORDER';
export const APP_UPDATE_MAIN_TIMER = 'APP_UPDATE_MAIN_TIMER';
export const APP_UPDATE_EXAM_TIME = 'APP_UPDATE_EXAM_TIME';

export const APP_UPDATE_EXAM_CONTENT = 'APP_UPDATE_EXAM_CONTENT';
export const APP_RESET = 'APP_RESET_123';
export const UPDATE_APP_LOADING = 'UPDATE_APP_LOADING';