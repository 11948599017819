import * as actions from '../actions/types/speakingReview';

const defaultState = {
  answerInstances:[],
  exam1Count:0,
  exam2Count:0,
  exam3Count:0,
  exam4Count:0,
  exam5Count:0,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.SPEAKING_REVIEW_UPDATE_ANSWER_INSTANCES: {
      return {...state, answerInstances:action.payload};
    }
    case actions.SPEAKING_REVIEW_UPDATE_EXAM1_COUNT: {
      return {...state, exam1Count:action.payload};
    }
    case actions.SPEAKING_REVIEW_UPDATE_EXAM2_COUNT: {
      return {...state, exam2Count:action.payload};
    }
    case actions.SPEAKING_REVIEW_UPDATE_EXAM3_COUNT: {
      return {...state, exam3Count:action.payload};
    }
    case actions.SPEAKING_REVIEW_UPDATE_EXAM4_COUNT: {
      return {...state, exam4Count:action.payload};
    }
    case actions.SPEAKING_REVIEW_UPDATE_EXAM5_COUNT: {
      return {...state, exam5Count:action.payload};
    }
    default: return {...state};
  }
};