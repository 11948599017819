import * as task15 from '../types/task15';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : task15.TASK15_RESET,
    });
  };
};

export const updateChoices = (payload) => {
  return dispatch => {
    dispatch({
      type : task15.TASK15_UPDATE_CHOICES,
      payload: payload
    });
  };
};

export const updateAnswers = (payload) => {
  return dispatch => {
    dispatch({
      type : task15.TASK15_UPDATE_ANSWERS,
      payload: payload
    });
  };
};

export const updateChosenAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type : task15.TASK15_UPDATE_CHOSEN_ANSWER,
      payload: payload
    });
  };
};

export const updatePlayStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_UPDATE_PLAY_STATUS,
      payload : payload,
    });
  };
};

export const updatePlayProgress = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_UPDATE_PLAY_PROGRESS,
      payload : payload,
    });
  };
};

export const updatePlayEnded = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_UPDATE_PLAY_ENDED,
      payload : payload,
    });
  };
};

export const updateDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_DURATION,
      payload : payload,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_TIMER,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task15.TASK15_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateChoices2 = (payload) => {
  return dispatch => {
    dispatch({
      type : task15.TASK15_UPDATE_CHOICES2,
      payload: payload
    });
  };
};

export const updateAnswers2 = (payload) => {
  return dispatch => {
    dispatch({
      type : task15.TASK15_UPDATE_ANSWERS2,
      payload: payload
    });
  };
};
