import * as app from '../types/auth';

export const updateIsAuthenticated = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_IS_AUTHENTICATED,
      payload : payload,
    });
  };
};

export const updateToken = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_TOKEN,
      payload : payload,
    });
  };
};

export const updateUsername = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_USERNAME,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : app.RESET_AUTH,
    });
  };
};

export const updatePermissions = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.AUTH_UPDATE_PERMISSIONS,
      payload : payload,
    });
  };
};