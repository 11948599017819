import * as actions from '../types/speakingReview';

export const updateAnswerInstances = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.SPEAKING_REVIEW_UPDATE_ANSWER_INSTANCES,
      payload : payload,
    });
  };
};

export const updateExam1Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.SPEAKING_REVIEW_UPDATE_EXAM1_COUNT,
      payload : payload,
    });
  };
};

export const updateExam2Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.SPEAKING_REVIEW_UPDATE_EXAM2_COUNT,
      payload : payload,
    });
  };
};

export const updateExam3Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.SPEAKING_REVIEW_UPDATE_EXAM3_COUNT,
      payload : payload,
    });
  };
};

export const updateExam4Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.SPEAKING_REVIEW_UPDATE_EXAM4_COUNT,
      payload : payload,
    });
  };
};

export const updateExam5Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.SPEAKING_REVIEW_UPDATE_EXAM5_COUNT,
      payload : payload,
    });
  };
};