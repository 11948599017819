export const TASK19_UPDATE_STARTED = "TASK19_UPDATE_STARTED";
export const TASK19_RESET = "TASK19_RESET";
export const TASK19_DURATION = "TASK19_DURATION";
export const TASK19_TIMER = "TASK19_TIMER";
export const TASK19_UPDATE_ANSWER = "TASK19_UPDATE_ANSWER";
export const TASK19_UPDATE_CURRENT_INDEX = "TASK19_UPDATE_CURRENT_INDEX";
export const TASK19_UPDATE_CURRENT_ANSWER = "TASK19_UPDATE_CURRENT_ANSWER";
export const TASK19_UPDATE_ANSWERS = "TASK19_UPDATE_ANSWERS";
export const TASK19_UPDATE_COUNTDOWN_STARTED = "TASK19_UPDATE_COUNTDOWN_STARTED";
export const TASK19_UPDATE_COUNTDOWN_TIMER = "TASK19_UPDATE_COUNTDOWN_TIMER";
export const TASK19_UPDATE_PLAY_STATUS = "TASK19_UPDATE_PLAY_STATUS";
export const TASK19_UPDATE_PLAY_PROGRESS = "TASK19_UPDATE_PLAY_PROGRESS";
export const TASK19_UPDATE_PLAY_ENDED = "TASK19_UPDATE_PLAY_ENDED";

export const TASK19_UPDATE_CURRENT_ANSWER2 = "TASK19_UPDATE_CURRENT_ANSWER2";