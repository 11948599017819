import * as actions from '../actions/types/tooling';

const defaultState = {
  wysiwyg_text: '',
  json:[{
    value:"",
  }]
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_WYSIWYG_TEXT: {
      return {...state, wysiwyg_text:action.payload};
    }
    case actions.UPDATE_WYSIWYG_JSON: {
      return {...state, json:action.payload};
    }
    default: return {...state};
  }
};