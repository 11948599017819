import * as actions from '../types/answerInstance';

export const updateAnswerInstance = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_ANSWER_INSTANCE,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : actions.RESET_ANSWER_INSTANCE,
    });
  };
};