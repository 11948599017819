import * as actions from '../actions/types/task11';

const defaultState = {
  choices:[],
  answers: [null],
  choices2:[],
  answers2: [null],
  chosenAnswer:"",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK11_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK11_UPDATE_CHOICES: {
      return {...state, choices:action.payload};
    }
    case actions.TASK11_UPDATE_ANSWERS2: {
      return {...state, answers2:action.payload};
    }
    case actions.TASK11_UPDATE_CHOICES2: {
      return {...state, choices2:action.payload};
    }
    case actions.TASK11_UPDATE_CHOSEN_ANSWER: {
      return {...state, chosenAnswer:action.payload};
    }
    case actions.TASK11_RESET: {
      return {...defaultState};
    }
    default: return {...state};
  }
};