import React from "react";
import { Row, Col, Progress } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/app/task0";
import * as appActions from "../../actions/app/app";
import * as Common from "../common";
import { uploadFile } from "../../actions/api/storage";

import { ReactMic } from "react-mic";

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

export const Player = (props) => {
  return (
    <Row className="text-center padding-10">
      {props.play_beep ? <Common.Beep {...props} /> : null}
      <Row className="graybox text-center">
        <Col span={24} className="margin-top-10">
          <h3>Recorded Answer</h3>
          <p className="countdown">
            {props.recording_status
              ? "Recording"
              : props.timer > 0
              ? "Beginning in " + props.timer
              : null}
          </p>
        </Col>
        <Col span={24} className="margin-top-10">
          <p>
            <Progress
              className="width-25"
              percent={(props.progress / props.duration) * 100}
              showInfo={false}
              strokeColor={"#0081A4"}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;{format(30 - props.recording_timer)} /{" "}
            {format(30)}
          </p>
        </Col>
        <Col span={24} className="text-center">
          <ReactMic
            className="soundwave"
            record={props.recording_status}
            onStop={props.onStop.bind(this)}
            strokeColor={"#006480"}
            backgroundColor={"#fff"}
            style={{ maxWidth: "90%" }}
          />
        </Col>
      </Row>
    </Row>
  );
};

class ListeningMCQ extends React.Component {
  componentDidMount() {
    const { timer, recording_status } = this.props;
    if (timer > 0) {
      this.startTimer();
    }
    if (recording_status) {
      this.startTimer2();
    }
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  startTimer2() {
    this.timer2 = setInterval(this.tick2.bind(this), 1000);
  }
  tick() {
    const { dispatch, timer } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(actions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      dispatch(actions.updatePlayBeep(true));
    }
  }
  tick2() {
    const { dispatch, recording_timer } = this.props;
    const newTimer = recording_timer - 1;
    if (newTimer >= 0) {
      dispatch(actions.updateRecordingTimer(newTimer));
    } else {
      this.clearTimer();
      dispatch(actions.updateRecordingStatus(false));
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timer2);
    this.timer2 = null;
  }
  nextTask() {
    this.clearTimer();
    const { dispatch, currentContent } = this.props;
    dispatch(appActions.updateCurrentContent(currentContent + 1));
  }
  onStop = (recordBlob) => {
    this.clearTimer();
    const { user, exam_number } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", recordBlob.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.sheet_id;

      const audioAnswer = new File([xhr.response], "answer.webm");
      const key =
        "student-answers/" + userName + "/Task0 " + exam_number + ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          this.updateAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  handleBeepEnd = () => {
    const { dispatch } = this.props;
    dispatch(actions.updatePlayBeep(false));
    dispatch(actions.updateRecordingStatus(true));
    this.startTimer2();
  };
  render() {
    return (
      <div>
        <Row className="margin-top-17">
          <Col span={24} className="padding-17 text-left">
            <p>
              <br />
              <br />
              Please introduce yourself. For example, you could talk about one
              of the following:
              <br />
              <br />
              <ul>
                <li>Your interests</li>
                <li>Your plans for future study</li>
                <li>Why you want to study abroad</li>
                <li>Why you need to learn English</li>
                <li>Why you chose this test</li>
              </ul>
            </p>
          </Col>
          <Col span={24}>
            <p className="content text-left">
              <Player
                onEnded={this.handleBeepEnd.bind(this)}
                onStop={this.onStop.bind(this)}
                {...this.props}
              />
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    user: state.profile,
    timer: state.task0.timer,
    recording_status: state.task0.recording_status,
    recording_timer: state.task0.recording_timer,
    play_beep: state.task0.play_beep,
    beepUrl: state.api.beepUrl,
  };
};

export default connect(mapStateToProps)(ListeningMCQ);
