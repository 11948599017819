import React from "react";
import { Row, Col, Input, Button, Icon, Progress, Collapse } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/app/task13";
import * as appActions from "../../actions/app/app";
import * as EXAM_TYPES from "../../constants/exams";
import wordcount from "wordcount";
import ReactPlayer from "react-player";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as stratActions from "../../actions/app/strategy";

const Panel = Collapse.Panel;
const { TextArea } = Input;

function format(seconds) {
  if (seconds > 0) {
    seconds = Math.round(seconds + 1);
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

export const Player = (props) => {
  return (
    <Row className="text-center padding-10">
      <Row className="graybox text-center">
        <Col span={24} className="margin-top-10">
          <p className="countdown">
            {props.strat_mode_on
              ? "Audio finished playing"
              : props.countdown_started
              ? "Beginning in " + props.timer
              : props.play_ended || props.strat_mode_on
              ? "Audio finished playing"
              : props.play_status
              ? "Playing"
              : null}
          </p>
        </Col>
        <Col span={24} className="margin-top-10">
          <p>
            <Progress
              className="width-25"
              percent={
                props.strat_mode_on
                  ? 100
                  : (props.progress / props.duration) * 100
              }
              showInfo={false}
              strokeColor={"#0081A4"}
            />
            {props.play_ended || props.strat_mode_on ? (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{format(props.duration)} /{" "}
                {format(props.duration)}
              </span>
            ) : (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{format(props.progress)} /{" "}
                {format(props.duration)}
              </span>
            )}
          </p>
        </Col>
      </Row>
    </Row>
  );
};

class SummarizeSpokenText extends React.Component {
  componentDidMount() {
    const {
      task,
      currentContent,
      dispatch,
      started,
      countdown_started,
      play_status,
      progress,
      exam_timer_started,
      strat_mode_on,
    } = this.props;
    this.clearTimer();
    this.clearTimer2();
    this.clearTimer3();
    dispatch(apiActions.updateApiSending(false));
    if (!strat_mode_on) {
      if (!started) {
        dispatch(actions.updateStarted(true));
        dispatch(actions.updateCountdownStarted(true));
        dispatch(actions.updateTimer(12));
        dispatch(
          actions.updateCountdownTimer(task[currentContent].countdown_timer)
        );
        this.startTimer();
      } else if (countdown_started) {
        this.startTimer();
      } else if (play_status) {
        this.player.seekTo(progress);
      } else if (exam_timer_started) {
        this.startTimer3();
      }
      this.startTimer2();
    }
  }
  componentWillUnmount() {
    this.props.dispatch(actions.reset());
    clearInterval(this.timer);
    this.timer = null;
    this.clearTimer();
    this.clearTimer2();
    this.clearTimer3();
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  startTimer3() {
    this.timer3 = setInterval(this.tick3.bind(this), 1000);
  }
  tick3() {
    const { exam_timer, dispatch, task, currentContent } = this.props;
    const newTimer = exam_timer + 1;
    if (task[currentContent].countdown_timer >= newTimer) {
      dispatch(actions.updateExamTimer(newTimer));
    } else {
      this.clearTimer3();
      this.nextTask();
    }
  }
  tick2() {
    // const {
    //   countdown_timer,
    //   dispatch,
    // } = this.props;
    // const newTimer = countdown_timer - 1;
    // if (newTimer >= 0){
    //   dispatch(actions.updateCountdownTimer(newTimer));
    // } else {
    //   this.clearTimer2();
    //   this.nextTask();
    // }
  }
  startTimer2() {
    this.timer2 = setInterval(this.tick2.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(actions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      dispatch(actions.updateCountdownStarted(false));
      dispatch(actions.updatePlayStatus(true));
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer2() {
    clearInterval(this.timer2);
    this.timer2 = null;
  }
  clearTimer3() {
    clearInterval(this.timer3);
    this.timer3 = null;
  }
  inputChange = (e) => {
    const { dispatch } = this.props;
    dispatch(actions.updateAnswer(e.target.value));
  };
  inputChange2 = (e) => {
    const { dispatch } = this.props;
    dispatch(actions.updateAnswer2(e.target.value));
  };
  onDuration = (duration) => {
    const { dispatch } = this.props;
    dispatch(actions.updateDuration(duration));
  };
  nextTask = () => {
    this.clearTimer();
    this.clearTimer2();
    this.clearTimer3();
    const {
      dispatch,
      task,
      currentContent,
      answer,
      answerInstance,
      answer2,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      this.updateAnswer(answer, answer2);
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "listening"
            )
          );
        }, 1500);
      } else {
        dispatch(actions.reset());
        if (task[newCurrentTask].exam_type === EXAM_TYPES.TASK13) {
          dispatch(actions.updateStarted(true));
          dispatch(actions.updateExamTimerStarted(true));
          dispatch(actions.updateCountdownStarted(true));
          dispatch(actions.updateTimer(12));
          dispatch(
            actions.updateCountdownTimer(task[newCurrentTask].countdown_timer)
          );
          this.startTimer();
        }
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(stratActions.updateLearningStratStarted(false));
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  };
  onProgress = (data) => {
    const playedSeconds = data.playedSeconds;
    const { dispatch } = this.props;
    dispatch(actions.updatePlayProgress(playedSeconds));
  };
  ref = (player) => {
    this.player = player;
  };
  ref3 = (text_area) => {
    this.textArea2 = text_area;
  };
  onEnded = (e) => {
    this.props.dispatch(actions.updatePlayProgress(this.props.duration));
    this.props.dispatch(actions.updatePlayEnded(true));
    this.startTimer3();
    this.props.dispatch(actions.updateExamTimerStarted(true));
  };
  copyToClipboard(e) {
    const text_area = this.textArea.textAreaRef;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    this.props.dispatch(actions.updateClipboard(selectedText));
  }
  cutToClipboard(e) {
    const { dispatch } = this.props;
    const text_area = this.textArea.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    dispatch(actions.updateClipboard(selectedText));
    const cut_text = value.substr(0, start) + value.substr(end);
    dispatch(actions.updateAnswer(cut_text));
  }
  paste(e) {
    const { dispatch, clip_board } = this.props;
    const text_area = this.textArea.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const result =
      value.substring(0, start) +
      clip_board +
      value.substring(start, value.length);
    dispatch(actions.updateAnswer(result));
  }
  copyToClipboard2(e) {
    const text_area = this.textArea2.textAreaRef;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    this.props.dispatch(actions.updateClipboard2(selectedText));
  }
  cutToClipboard2(e) {
    const { dispatch } = this.props;
    const text_area = this.textArea2.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    dispatch(actions.updateClipboard2(selectedText));
    const cut_text = value.substr(0, start) + value.substr(end);
    dispatch(actions.updateAnswer2(cut_text));
  }
  paste2(e) {
    const { dispatch, clip_board2 } = this.props;
    const text_area = this.textArea2.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const result =
      value.substring(0, start) +
      clip_board2 +
      value.substring(start, value.length);
    dispatch(actions.updateAnswer2(result));
  }
  ref2 = (text_area) => {
    this.textArea = text_area;
  };
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "listening"));
  }
  render() {
    const {
      task,
      currentContent,
      answer,
      play_status,
      answer2,
      strat_mode_on,
    } = this.props;
    // const max_words = task[currentContent].word_limit;
    const total_words = wordcount(answer);
    const total_words2 = wordcount(answer2);
    return (
      <div>
        <Row className="text-center">
          <Col span={24}>
            <p className="content text-left">
              <Player
                {...this.props}
                audio_url={task[currentContent].audio_url}
              />
            </p>
          </Col>
          <Col span={12} className="text-left">
            <Button
              className="copy-cut-paste"
              size="small"
              type="dashed"
              onClick={this.copyToClipboard.bind(this)}
            >
              Copy
            </Button>
            &nbsp;
            <Button
              className="copy-cut-paste"
              size="small"
              type="dashed"
              onClick={this.cutToClipboard.bind(this)}
            >
              Cut
            </Button>
            &nbsp;
            <Button
              className="copy-cut-paste"
              size="small"
              type="dashed"
              onClick={this.paste.bind(this)}
            >
              Paste
            </Button>
          </Col>
          <Col span={12} className="text-right">
            <p
              className={
                total_words < 50 || 70 < total_words ? "countdown" : ""
              }
            >
              Word Count: {total_words}
            </p>
          </Col>
          <Col span={24}>
            <TextArea
              disabled={strat_mode_on}
              ref={this.ref2}
              rows={10}
              className={
                total_words < 50 || 70 < total_words ? "text-overflow" : ""
              }
              onChange={this.inputChange.bind(this)}
              value={answer}
            />
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Play Recording Again" key="2">
                    <Row>
                      <Col span={8} className="text-center">
                        <audio
                          ref="audioSource"
                          controls="controls"
                          src={task[currentContent]["audio_url"]}
                        ></audio>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="1">
                    <Row>
                      <Col span={12} className="text-left">
                        <Button
                          className="copy-cut-paste"
                          size="small"
                          type="dashed"
                          onClick={this.copyToClipboard2.bind(this)}
                        >
                          Copy
                        </Button>
                        &nbsp;
                        <Button
                          className="copy-cut-paste"
                          size="small"
                          type="dashed"
                          onClick={this.cutToClipboard2.bind(this)}
                        >
                          Cut
                        </Button>
                        &nbsp;
                        <Button
                          className="copy-cut-paste"
                          size="small"
                          type="dashed"
                          onClick={this.paste2.bind(this)}
                        >
                          Paste
                        </Button>
                      </Col>
                      <Col span={12} className="text-right">
                        <p
                          className={
                            total_words2 < 50 || 70 < total_words2
                              ? "countdown"
                              : ""
                          }
                        >
                          Word Count: {total_words2}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="text-center">
                        <TextArea
                          ref={this.ref3}
                          rows={10}
                          className={
                            total_words2 < 50 || 70 < total_words2
                              ? "text-overflow"
                              : ""
                          }
                          onChange={this.inputChange2.bind(this)}
                          value={answer2}
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              onClick={this.nextTask.bind(this)}
              className="next-btn"
              disabled={this.props.sending}
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
        {play_status ? (
          <ReactPlayer
            className="invisible"
            ref={this.ref}
            url={task[currentContent]["audio_url"]}
            playing={play_status && !strat_mode_on}
            onDuration={this.onDuration.bind(this)}
            onProgress={this.onProgress.bind(this)}
            onEnded={this.onEnded.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    started: state.task13.task13_started,
    timer: state.task13.timer,
    answer: state.task13.answer,
    duration: state.task13.duration,
    countdown_started: state.task13.countdown_started,
    countdown_timer: state.task13.countdown_timer,
    play_status: state.task13.play_status,
    progress: state.task13.progress,
    play_ended: state.task13.play_ended,
    exam_number: state.task13.exam_number,
    user: state.profile,
    exam_timer: state.task13.exam_timer,
    clip_board: state.task13.clip_board,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    reading_timer: state.strategy.reading_timer,
    max_reading_timer: state.strategy.max_reading_timer,
    answer2: state.task13.answer2,
    clip_board2: state.task13.clip_board2,
    strat_mode_on: state.strategy.strat_mode_on,
  };
};

export default connect(mapStateToProps)(SummarizeSpokenText);
