import React from 'react';
import {
  Select,
  Row,
  Col,
  Input,
  Button,
  Icon,
} from 'antd';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import * as actions from '../actions/app/tooling';
import beautify from 'json-beautify';

const Option = Select.Option;

class JsonGenerator extends React.Component {
  componentDidMount(){
    this.resetJson();
  }
  state = {
    mode: 'text'
  }
  handleModeChange(value){
    this.setState({mode:value});
    if (value=== 'text'){
      this.props.dispatch(actions.updateWysiwygJSON([{value:""}]));
    } else {
      this.props.dispatch(actions.updateWysiwygJSON([{value:1}]));
    }
  }
  handleInputChange(index, e){
    const { dispatch, json } = this.props;
    const newJson = json.slice();
    let value;
    if (this.state.mode === 'text'){
      value = e.target.value;
    } else {
      value = parseInt(e.target.value, 10);
    }
    newJson[index].value = value;
    dispatch(actions.updateWysiwygJSON(newJson));
  }
  addJson(){
    const { dispatch, json } = this.props;
    const newJson = json.slice();
    if (this.state.mode === 'text'){
      newJson.push({value:""});
    } else {
      newJson.push({value:1});
    }
    dispatch(actions.updateWysiwygJSON(newJson));
  }
  removeJson(index){
    const { dispatch, json } = this.props;
    const newJson = json.slice();
    newJson.splice(index, 1);
    dispatch(actions.updateWysiwygJSON(newJson));
  }
  resetJson(){
    const { dispatch } = this.props;
    const newJson = [];
    if (this.state.mode === 'text'){
      newJson.push({value:""});
    } else {
      newJson.push({value:1});
    }
    dispatch(actions.updateWysiwygJSON(newJson));
  }
  render() {
    const { json } = this.props;
    const inputs = json.map((item, i) => {
      return (
        <div className="padding-10" key={"input-123-" + i}>
          <Input type={this.state.mode}
                 onChange={this.handleInputChange.bind(this, i)}
                 value={item.value}
                 style={{width:"50%"}} /> &nbsp;
          {
            json.length !== 1?
              <Button onClick={this.removeJson.bind(this, i)} type="danger"><Icon type="minus" /></Button>
            :
              null
          }
          &nbsp;
          {
            i === json.length-1?
              <Button onClick={this.addJson.bind(this)} type="primary"><Icon type="plus" /></Button>
            :
              null
          }
          <br/>
        </div>
      );
    });
    const result = json.map(item => {
      return item.value;
    });
    return (
      <Row justify="center">
        <Col span={24} className="padding-10">
          <h3>Select mode String / Number</h3>
          <Select defaultValue={this.state.mode} value={this.state.mode} style={{ width: 120 }} onChange={this.handleModeChange.bind(this)}>
            <Option value={"text"}>Text</Option>
            <Option value={"number"}>Number</Option>
          </Select>
          &nbsp;
          <Button onClick={this.resetJson.bind(this)} type="primary">Reset</Button>
        </Col>
        <Col span={12} className="padding-10">
          {inputs}
        </Col>
        <Col span={12} className="padding-10">
          <h3>JSON Result (Copy this to your excel file): </h3>
          <p>
          {beautify(result, null, 2, 100)}
          </p>
        </Col>
        <Col span={24} className="padding-10">
          <h3>Note: There is no need to use backslash ( \ ) for double quotes ( " )</h3>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    json : state.tooling.json,
  }
};

export default connect(mapStateToProps)(JsonGenerator);