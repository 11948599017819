import * as actions from '../actions/types/readingReview';

const defaultState = {
  answerInstances:[],
  exam8Count:0,
  exam9Count:0,
  exam10Count:0,
  exam11Count:0,
  exam12Count:0,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.READING_REVIEW_UPDATE_ANSWER_INSTANCES: {
      return {...state, answerInstances:action.payload};
    }
    case actions.READING_REVIEW_UPDATE_EXAM8_COUNT: {
      return {...state, exam8Count:action.payload};
    }
    case actions.READING_REVIEW_UPDATE_EXAM9_COUNT: {
      return {...state, exam9Count:action.payload};
    }
    case actions.READING_REVIEW_UPDATE_EXAM10_COUNT: {
      return {...state, exam10Count:action.payload};
    }
    case actions.READING_REVIEW_UPDATE_EXAM11_COUNT: {
      return {...state, exam11Count:action.payload};
    }
    case actions.READING_REVIEW_UPDATE_EXAM12_COUNT: {
      return {...state, exam12Count:action.payload};
    }
    default: return {...state};
  }
};