import React from "react";
import { Row, Col, Button, Icon, Collapse, Modal } from "antd";
import { connect } from "react-redux";
import { Container, Draggable } from "react-smooth-dnd";
import * as actions from "../../actions/app/task10";
import * as strategyActions from "../../actions/app/strategy";
import * as appActions from "../../actions/app/app";
import * as apiActions from "../../actions/app/api";
import * as api from "../../actions/api/requests";
import * as stratActions from "../../actions/app/strategy";

const Panel = Collapse.Panel;

const ButtonGroup = Button.Group;

class ArrangeSentence extends React.Component {
  componentDidMount() {
    const {
      dispatch,
      sentences,
      answers,
      answers2,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(false));
    const newAnswers = [];
    const newAnswers2 = [];
    for (let i = 0; i < sentences.length; i++) {
      if (answers.indexOf(i) === -1) {
        newAnswers.push(i);
      }
      if (answers2.indexOf(i) === -1) {
        newAnswers2.push(i);
      }
    }
    dispatch(actions.updateSentenceCon(newAnswers));
    dispatch(actions.updateSentenceCon2(newAnswers2));
    this.clearTimer();
    if (!strat_mode_on) {
      this.startLearningStratTimer();
    }
  }
  componentWillUnmount() {
    this.props.dispatch(strategyActions.reset());
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  startLearningStratTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { dispatch, reading_timer, max_reading_timer } = this.props;
    const new_timer = reading_timer + 1;
    if (new_timer <= max_reading_timer) {
      dispatch(strategyActions.updateReadingTImer(new_timer));
    } else {
      this.clearTimer();
      this.handleSubmit();
    }
  }
  handleClick = (index, e) => {
    e.stopPropagation();
    const { dispatch } = this.props;
    dispatch(actions.updateSentence(index));
    dispatch(actions.updateAnswer(-1));
  };
  handleContainerClick = (index, e) => {
    e.stopPropagation();
    const { dispatch } = this.props;
    dispatch(actions.updateSentence(-1));
    dispatch(actions.updateAnswer(index));
  };
  reset = () => {
    const { dispatch } = this.props;
    dispatch(actions.updateSentence(-1));
    dispatch(actions.updateAnswer(-1));
  };
  handleContainerDrop = (dropped) => {
    const { dispatch } = this.props;
    const index = parseInt(dropped.droppedElement.id, 10);
    if (dropped.addedIndex !== null && dropped.removedIndex === null) {
      const answers = this.props.answers.slice();
      answers.splice(dropped.addedIndex, 0, index);
      dispatch(actions.updateAnswers(answers));
      const sentenceCon = this.props.sentenceCon.slice();
      sentenceCon.splice(sentenceCon.indexOf(index), 1);
      dispatch(actions.updateSentenceCon(sentenceCon));
    }
    if (dropped.addedIndex === null && dropped.removedIndex !== null) {
      const sentenceCon = this.props.sentenceCon.slice();
      sentenceCon.push(index);
      dispatch(actions.updateSentenceCon(sentenceCon));
      const answers = this.props.answers.slice();
      answers.splice(answers.indexOf(index), 1);
      dispatch(actions.updateAnswers(answers));
    }
  };
  handleContainerDrop2 = (dropped) => {
    const { dispatch } = this.props;
    const index = parseInt(dropped.droppedElement.id, 10);
    if (dropped.addedIndex !== null && dropped.removedIndex === null) {
      const answers = this.props.answers2.slice();
      answers.splice(dropped.addedIndex, 0, index);
      dispatch(actions.updateAnswers2(answers));
      const sentenceCon = this.props.sentenceCon2.slice();
      sentenceCon.splice(sentenceCon.indexOf(index), 1);
      dispatch(actions.updateSentenceCon2(sentenceCon));
    }
    if (dropped.addedIndex === null && dropped.removedIndex !== null) {
      const sentenceCon = this.props.sentenceCon2.slice();
      sentenceCon.push(index);
      dispatch(actions.updateSentenceCon2(sentenceCon));
      const answers = this.props.answers2.slice();
      answers.splice(answers.indexOf(index), 1);
      dispatch(actions.updateAnswers2(answers));
    }
  };
  handleMoveRight = () => {
    const { dispatch, sentence } = this.props;
    const index = sentence;
    const answers = this.props.answers.slice();
    answers.push(index);
    dispatch(actions.updateAnswers(answers));
    const sentenceCon = this.props.sentenceCon.slice();
    sentenceCon.splice(sentenceCon.indexOf(index), 1);
    dispatch(actions.updateSentenceCon(sentenceCon));
  };
  handleMoveRight2 = () => {
    const { dispatch, sentence } = this.props;
    const index = sentence;
    const answers = this.props.answers2.slice();
    answers.push(index);
    dispatch(actions.updateAnswers2(answers));
    const sentenceCon = this.props.sentenceCon2.slice();
    sentenceCon.splice(sentenceCon.indexOf(index), 1);
    dispatch(actions.updateSentenceCon2(sentenceCon));
  };
  handleMoveLeft = () => {
    const { dispatch } = this.props;
    const index = this.props.answer;
    const sentenceCon = this.props.sentenceCon.slice();
    sentenceCon.push(index);
    dispatch(actions.updateSentenceCon(sentenceCon));
    const answers = this.props.answers.slice();
    answers.splice(answers.indexOf(index), 1);
    dispatch(actions.updateAnswers(answers));
  };
  handleMoveLeft2 = () => {
    const { dispatch } = this.props;
    const index = this.props.answer;
    const sentenceCon = this.props.sentenceCon2.slice();
    sentenceCon.push(index);
    dispatch(actions.updateSentenceCon2(sentenceCon));
    const answers = this.props.answers2.slice();
    answers.splice(answers.indexOf(index), 1);
    dispatch(actions.updateAnswers2(answers));
  };
  handleMoveUp = (e) => {
    const answer = this.props.answer;
    const answers = this.props.answers.slice();
    const answerIndex = answers.indexOf(answer);
    const { dispatch } = this.props;
    if (answerIndex > 0) {
      answers.splice(answerIndex, 1);
      answers.splice(answerIndex - 1, 0, answer);
      dispatch(actions.updateAnswers(answers));
    } else {
      e.stopPropagation();
    }
  };
  handleMoveUp2 = (e) => {
    const answer = this.props.answer;
    const answers = this.props.answers2.slice();
    const answerIndex = answers.indexOf(answer);
    const { dispatch } = this.props;
    if (answerIndex > 0) {
      answers.splice(answerIndex, 1);
      answers.splice(answerIndex - 1, 0, answer);
      dispatch(actions.updateAnswers2(answers));
    } else {
      e.stopPropagation();
    }
  };
  handleMoveDown = (e) => {
    const answer = this.props.answer;
    const answers = this.props.answers.slice();
    const answerIndex = answers.indexOf(answer);
    const { dispatch } = this.props;
    if (answerIndex < this.props.answers.length - 1) {
      answers.splice(answerIndex, 1);
      answers.splice(answerIndex + 1, 0, answer);
      dispatch(actions.updateAnswers(answers));
    } else {
      e.stopPropagation();
    }
  };
  handleMoveDown2 = (e) => {
    const answer = this.props.answer;
    const answers = this.props.answers2.slice();
    const answerIndex = answers.indexOf(answer);
    const { dispatch } = this.props;
    if (answerIndex < this.props.answers2.length - 1) {
      answers.splice(answerIndex, 1);
      answers.splice(answerIndex + 1, 0, answer);
      dispatch(actions.updateAnswers2(answers));
    } else {
      e.stopPropagation();
    }
  };
  resetAnswer() {
    const { dispatch, sentences } = this.props;
    const con = [];
    for (let i = 0; i < sentences.length; i++) {
      con.push(i);
    }
    dispatch(actions.reset());
    dispatch(actions.updateSentenceCon(con));
    dispatch(actions.updateSentenceCon2(con));
  }
  checkAnswer(actual_answer, correct_answer) {
    let score = 0;
    const n = correct_answer.length;
    for (let i = 0; i < n - 1; i++) {
      const answer_idx = correct_answer.indexOf(actual_answer[i]);
      if (answer_idx + 1 < n) {
        if (actual_answer[i + 1] === correct_answer[answer_idx + 1]) {
          score++;
        }
      }
    }
    return score;
  }
  handleSubmit = () => {
    const {
      dispatch,
      currentContent,
      task,
      answerInstance,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    let answers = this.props.answers.slice();
    let answers2 = this.props.answers2.slice();
    if (strat_mode_on) {
      for (let i = 0; i < answers.length; i++) {
        answers[i] = answers[i] + 1;
      }
      for (let i = 0; i < answers2.length; i++) {
        answers2[i] = answers2[i] + 1;
      }
      this.updateAnswer(answers, answers2);
      dispatch(actions.reset());
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "reading"
            )
          );
        }, 1500);
      } else {
        dispatch(appActions.updateCurrentContent(currentContent + 1));
        const con = [];
        for (let i = 0; i < task[currentContent + 1].answers.length; i++) {
          con.push(i);
        }
        dispatch(actions.updateSentenceCon(con));
        dispatch(actions.updateSentenceCon2(con));
        dispatch(strategyActions.reset());
        this.clearTimer();
        this.startLearningStratTimer();
      }
      dispatch(stratActions.updateLearningStratModeOn(false));
    } else {
      this.clearTimer();
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  };
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const newAnswerInstance = { ...answerInstance };
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "reading"));
  }
  info() {
    Modal.info({
      title: "Instruction",
      content: (
        <div>
          <p>
            Rearrange the sentences again and click the <b>Next</b> button when
            you are finished answering.
          </p>
        </div>
      ),
      onOk: this.resetAnswer.bind(this),
    });
  }
  render() {
    const {
      sentence,
      sentences,
      answer,
      answers,
      sentenceCon,
      strat_mode_on,
      task,
      currentContent,
      answers2,
      sentenceCon2,
    } = this.props;
    const sentenceBoxes = sentenceCon.map((item) => {
      return (
        <Draggable
          key={"sen" + item}
          className={strat_mode_on ? "no-pointer" : ""}
        >
          <Row
            id={item}
            onClick={this.handleClick.bind(this, item)}
            className={
              "sentence text-center padding " +
              (sentence === item ? "sentence-clicked" : "")
            }
          >
            <p>
              <Icon type="bars" className="sentence-icon" />
              {sentences[item]}
            </p>
          </Row>
        </Draggable>
      );
    });
    const containerBoxes = answers.map((item) => {
      return (
        <Draggable
          key={"con" + item}
          className={strat_mode_on ? "no-pointer" : ""}
        >
          <Row
            id={item}
            onClick={this.handleContainerClick.bind(this, item)}
            className={
              "sentence text-center padding " +
              (answer === item ? "sentence-clicked" : "")
            }
          >
            <p>
              <Icon type="bars" className="sentence-icon" />
              {sentences[item]}
            </p>
          </Row>
        </Draggable>
      );
    });

    const sentenceBoxes2 = sentenceCon2.map((item) => {
      return (
        <Draggable key={"sen" + item}>
          <Row
            id={item}
            onClick={this.handleClick.bind(this, item)}
            className={
              "sentence text-center padding " +
              (sentence === item ? "sentence-clicked" : "")
            }
          >
            <p>
              <Icon type="bars" className="sentence-icon" />
              {sentences[item]}
            </p>
          </Row>
        </Draggable>
      );
    });
    const containerBoxes2 = answers2.map((item) => {
      return (
        <Draggable key={"con" + item}>
          <Row
            id={item}
            onClick={this.handleContainerClick.bind(this, item)}
            className={
              "sentence text-center padding " +
              (answer === item ? "sentence-clicked" : "")
            }
          >
            <p>
              <Icon type="bars" className="sentence-icon" />
              {sentences[item]}
            </p>
          </Row>
        </Draggable>
      );
    });

    return (
      <div>
        <Row className="text-center height-50" onClick={this.reset.bind(this)}>
          <Col span={10} className="sentence-container">
            <Row className="sentence-container-title text-center padding">
              <h4 className="white-font padding-top-4">Source</h4>
            </Row>
            <Row className={"answer"}>
              <Container
                groupName="sentences_containers"
                key={"sencon619"}
                dragClass="sentence-clicked"
              >
                {sentenceBoxes}
              </Container>
            </Row>
          </Col>
          <Col span={2} className="horizontal-btn margin-5">
            <ButtonGroup className="middle">
              <Button
                onClick={this.handleMoveLeft}
                icon="caret-left"
                disabled={!(answer !== -1 && sentence === -1)}
              />
              <Button
                onClick={this.handleMoveRight}
                icon="caret-right"
                disabled={!(answer === -1 && sentence !== -1)}
              />
            </ButtonGroup>
          </Col>
          <Col span={9} className="sentence-container">
            <Row className="sentence-container-title text-center padding">
              <h4 className="white-font padding-top-4">Target</h4>
            </Row>
            <Row className={"answer"}>
              <Container
                groupName="sentences_containers"
                key={"concon619"}
                onDrop={this.handleContainerDrop.bind(this)}
                dragClass="sentence-clicked"
              >
                {containerBoxes}
              </Container>
            </Row>
          </Col>
          <Col span={2} className="horizontal-btn">
            <ButtonGroup className="middle">
              <Button
                onClick={this.handleMoveUp.bind(this)}
                icon="caret-up"
                disabled={answer === -1}
              />
              <Button
                onClick={this.handleMoveDown.bind(this)}
                icon="caret-down"
                disabled={answer === -1}
              />
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="1">
                    <Row
                      className="text-center height-50"
                      onClick={this.reset.bind(this)}
                    >
                      <Col span={10} className="sentence-container">
                        <Row className="sentence-container-title text-center padding">
                          <h4 className="white-font padding-top-4">Source</h4>
                        </Row>
                        <Row className={"answer"}>
                          <Container
                            groupName="sentences_containers"
                            key={"sencon619"}
                            dragClass="sentence-clicked"
                          >
                            {sentenceBoxes2}
                          </Container>
                        </Row>
                      </Col>
                      <Col span={2} className="horizontal-btn margin-5">
                        <ButtonGroup className="middle">
                          <Button
                            onClick={this.handleMoveLeft2.bind(this)}
                            icon="caret-left"
                            disabled={!(answer !== -1 && sentence === -1)}
                          />
                          <Button
                            onClick={this.handleMoveRight2.bind(this)}
                            icon="caret-right"
                            disabled={!(answer === -1 && sentence !== -1)}
                          />
                        </ButtonGroup>
                      </Col>
                      <Col span={9} className="sentence-container">
                        <Row className="sentence-container-title text-center padding">
                          <h4 className="white-font padding-top-4">Target</h4>
                        </Row>
                        <Row className={"answer"}>
                          <Container
                            groupName="sentences_containers"
                            key={"concon619"}
                            onDrop={this.handleContainerDrop2.bind(this)}
                            dragClass="sentence-clicked"
                          >
                            {containerBoxes2}
                          </Container>
                        </Row>
                      </Col>
                      <Col span={2} className="horizontal-btn">
                        <ButtonGroup className="middle">
                          <Button
                            onClick={this.handleMoveUp2.bind(this)}
                            icon="caret-up"
                            disabled={answer === -1}
                          />
                          <Button
                            onClick={this.handleMoveDown2.bind(this)}
                            icon="caret-down"
                            disabled={answer === -1}
                          />
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending}
              onClick={this.handleSubmit.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    answers: state.task10.answers,
    sentences: state.app.examContent[state.app.currentContent].answers,
    sentence: state.task10.sentence,
    sentenceCon: state.task10.sentenceCon,
    answer: state.task10.answer,
    exam_score: state.task10.exam_score,
    user: state.profile,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    reading_timer: state.strategy.reading_timer,
    max_reading_timer: state.strategy.max_reading_timer,
    strat_mode_on: state.strategy.strat_mode_on,
    answers2: state.task10.answers2,
    sentenceCon2: state.task10.sentenceCon2,
  };
};

export default connect(mapStateToProps)(ArrangeSentence);
