import React from 'react';
import {
  Row,
  Col,
} from 'antd';

class Lost extends React.Component {
  render() {
    return (
      <Row justify="center">
        <Col span={24} className="text-center">
          <br/>
          <h2>Ooops. You seemed lost. There is nothing to be found here.</h2>
        </Col>
      </Row>
    );
  }
}

export default Lost;