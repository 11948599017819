export const TASK0 = "TASK0";
export const TASK1 = "SWReadAloud";
export const TASK2 = "SWRepeatSentence";
export const TASK3 = "DescribeImage";
export const TASK4 = "RetellLecture";
export const TASK5 = "AnswerShortQuestions";
export const TASK6 = "SummarizeWrittenText";
export const TASK7 = "Essay";
export const TASK8 = "ReadingMCQ";
export const TASK9 = "ReadingReadingCheckbox";
export const TASK10 = "ReorderParagraph";
export const TASK11 = "FillInBlank";
export const TASK12 = "FillInSelect";
export const TASK13 = "SummarizeSpokenText";
export const TASK14 = "ListeningCheckbox";
export const TASK15 = "ListeningFillInBlank";
export const TASK16 = "ListeningCorrectSummary";
export const TASK17 = "ListeningMCQ";
export const TASK18 = "SelectMissingWord";
export const TASK19 = "HighlightWords";
export const TASK20 = "RepeatDictation";

export const TASK3SUBTYPE1 = "ChartWithTimePeriod";
export const TASK3SUBTYPE2 = "ChartWithoutTimePeriod";
export const TASK3SUBTYPE3 = "Diagram";
export const TASK3SUBTYPE4 = "Map";
export const TASK3SUBTYPE5 = "Photo";

export const TASK4SUBTYPE1 = "Lecture";
export const TASK4SUBTYPE2 = "Interview";
