import * as actions from '../actions/types/task2';

const defaultState = {
  exam_number: 1,
  task2_started:false,
  countdown_timer: 3,
  countdown_started: false,
  play_status: false,
  play_duration: 0,
  play_progress: 0,
  play_beep: false,
  recording_started: false,
  recording_timer: 0,
  recording_duration: 0,
  prepare_timer:1,
  preparing: false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK2_UPDATE_EXAM_NUMBER: {
      return {...state, exam_number:action.payload};
    }
    case actions.TASK2_UPDATE_STARTED: {
      return {...state, task2_started:action.payload};
    }
    case actions.TASK2_UPDATE_COUNTDOWN_TIMER: {
      return {...state, countdown_timer:action.payload};
    }
    case actions.TASK2_UPDATE_COUNTDOWN_STARTED: {
      return {...state, countdown_started:action.payload};
    }
    case actions.TASK2_UPDATE_PLAY_STATUS: {
      return {...state, play_status:action.payload};
    }
    case actions.TASK2_UPDATE_PLAY_DURATION: {
      return {...state, play_duration:action.payload};
    }
    case actions.TASK2_UPDATE_PLAY_PROGRESS: {
      return {...state, play_progress:action.payload};
    }
    case actions.TASK2_UPDATE_PLAY_BEEP: {
      return {...state, play_beep:action.payload};
    }
    case actions.TASK2_UPDATE_RECORDING_STATUS: {
      return {...state, recording_started:action.payload};
    }
    case actions.TASK2_UPDATE_RECORDING_TIMER: {
      return {...state, recording_timer:action.payload};
    }
    case actions.TASK2_UPDATE_RECORDING_DURATION: {
      return {...state, recording_duration:action.payload};
    }
    case actions.TASK2_UPDATE_PREPARE_TIMER:{
      return {...state, prepare_timer:action.payload};
    }
    case actions.TASK2_UPDATE_PREPARING:{
      return {...state, preparing:action.payload};
    }
    case actions.TASK2_RESET_SEQUENCE:{
      return {
        ...state,
        exam_number: 1,
        task2_started:false,
        countdown_timer: 3,
        countdown_started: false,
        play_status: false,
        play_duration: 0,
        play_progress: 0,
        play_beep: false,
        recording_started: false,
        recording_timer: 0,
        recording_duration: 0,
        prepare_timer:1,
        preparing: false,
      }
    }
    default: return {...state};
  }
};