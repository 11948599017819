import * as actions from '../actions/types/sidebar';

const defaultState = {
  defaultSelectedKeys:"1",
  defaultOpenKeys:"sub1",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_SIDEBAR_SELECTED_KEYS: {
      return {...state, defaultSelectedKeys:action.paayload};
    }
    case actions.UPDATE_SIDEBAR_SELECTED_SUB: {
      return {...state, defaultOpenKeys:action.payload};
    }
    default: return {...state};
  }
};