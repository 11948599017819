export const TASK3_UPDATE_STARTED = "TASK3_UPDATE_STARTED";
export const TASK3_UPDATE_COUNTDOWN_TIMER = "TASK3_UPDATE_COUNTDOWN_TIMER";
export const TASK3_UPDATE_COUNTDOWN_STARTED = "TASK3_UPDATE_COUNTDOWN_STARTED";
export const TASK3_UPDATE_PLAY_STATUS = "TASK3_UPDATE_PLAY_STATUS";
export const TASK3_UPDATE_PLAY_DURATION = "TASK3_UPDATE_PLAY_DURATION";
export const TASK3_UPDATE_PLAY_PROGRESS = "TASK3_UPDATE_PLAY_PROGRESS";
export const TASK3_UPDATE_PLAY_BEEP = "TASK3_UPDATE_PLAY_BEEP";
export const TASK3_UPDATE_RECORDING_STATUS = "TASK3_UPDATE_RECORDING_STATUS";
export const TASK3_UPDATE_RECORDING_TIMER = "TASK3_UPDATE_RECORDING_TIMER";
export const TASK3_UPDATE_RECORDING_DURATION = "TASK3_UPDATE_RECORDING_DURATION";
export const TASK3_RESET_SEQUENCE = "TASK3_RESET_SEQUENCE";
export const TASK3_UPDATE_EXAM_NUMBER = "TASK3_UPDATE_EXAM_NUMBER";