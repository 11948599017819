import * as task0 from '../types/task0';

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task0.TASK0_UPDATE_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task0.TASK0_UPDATE_RECORDING_STATUS,
      payload : payload,
    });
  };
};

export const updateRecordingTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task0.TASK0_UPDATE_RECORDING_TIMER,
      payload : payload,
    });
  };
};

export const updatePlayBeep = (payload) => {
  return dispatch => {
    dispatch({
      type    : task0.TASK0_UPDATE_PLAY_BEEP,
      payload : payload,
    });
  };
};