import * as actions from '../types/tooling';

export const updateWysiwygText = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_WYSIWYG_TEXT,
      payload : payload,
    });
  };
};

export const updateWysiwygJSON = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.UPDATE_WYSIWYG_JSON,
      payload : payload,
    });
  };
};