import * as actions from '../actions/types/task4';

const defaultState = {
  task4_started:false,
  countdown_timer: 15,
  countdown_started: false,
  play_beep: false,
  recording_started: false,
  recording_timer: 0,
  recording_duration: 0,
  play_status: false,
  play_progress: 0,
  play_duration: 0,
  exam_number: 1,
  begin_timer: 3,
  recording_complete: false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK4_UPDATE_STARTED: {
      return {...state, task4_started:action.payload};
    }
    case actions.TASK4_UPDATE_BEGIN_TIMER: {
      return {...state, begin_timer:action.payload};
    }
    case actions.TASK4_UPDATE_EXAM_NUMBER: {
      return {...state, exam_number:action.payload};
    }
    case actions.TASK4_UPDATE_COUNTDOWN_TIMER: {
      return {...state, countdown_timer:action.payload};
    }
    case actions.TASK4_UPDATE_COUNTDOWN_STARTED: {
      return {...state, countdown_started:action.payload};
    }
    case actions.TASK4_UPDATE_PLAY_BEEP: {
      return {...state, play_beep:action.payload};
    }
    case actions.TASK4_UPDATE_RECORDING_STATUS: {
      return {...state, recording_started:action.payload};
    }
    case actions.TASK4_UPDATE_RECORDING_TIMER: {
      return {...state, recording_timer:action.payload};
    }
    case actions.TASK4_UPDATE_RECORDING_DURATION: {
      return {...state, recording_duration:action.payload};
    }
    case actions.TASK4_UPDATE_PLAY_STATUS: {
      return {...state, play_status:action.payload};
    }
    case actions.TASK4_UPDATE_PLAY_DURATION: {
      return {...state, play_duration:action.payload};
    }
    case actions.TASK4_UPDATE_PLAY_PROGRESS: {
      return {...state, play_progress:action.payload};
    }
    case actions.TASK4_UPDATE_RECORDING_COMPLETE: {
      return {...state, recording_complete:action.payload};
    }
    case actions.TASK4_RESET_SEQUENCE:{
      return {
        ...state,
        task4_started:false,
        countdown_timer: 15,
        countdown_started: false,
        play_beep: false,
        recording_started: false,
        recording_timer: 0,
        recording_duration: 0,
        play_status: false,
        play_progress: 0,
        play_duration: 0,
        begin_timer: 3,
        recording_complete: false,
      }
    }
    default: return {...state};
  }
};