import * as task13 from '../types/task13';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_DURATION,
      payload : payload,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_TIMER,
      payload : payload,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_ANSWER,
      payload : payload,
    });
  };
};

export const updateAnswer2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_ANSWER2,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updatePlayStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_PLAY_STATUS,
      payload : payload,
    });
  };
};

export const updatePlayProgress = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_PLAY_PROGRESS,
      payload : payload,
    });
  };
};

export const updatePlayEnded = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_PLAY_ENDED,
      payload : payload,
    });
  };
};


export const reset = () => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_RESET,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};

export const updateExamTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_EXAM_TIMER,
      payload : payload,
    });
  };
};

export const updateExamTimerStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_EXAM_TIMER_STARTED,
      payload : payload,
    });
  };
};

export const updateClipboard = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_CLIP_BOARD,
      payload : payload,
    });
  };
};

export const updateClipboard2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task13.TASK13_UPDATE_CLIP_BOARD2,
      payload : payload,
    });
  };
};