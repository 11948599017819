import * as actions from '../actions/types/profile';

const defaultState = {
  username:"",
  email:"",
  first_name:"",
  last_name:"",
  password:"",
  password2:"",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_PROFILE: {
      return {...action.payload};
    }
    case actions.RESET_PROFILE: {
      return {...defaultState};
    }
    default: return {...state};
  }
};