import React from "react";
import { Row, Col, Input, Button, Icon, Collapse } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/app/task6";
import * as appActions from "../../actions/app/app";
import * as EXAM_TYPES from "../../constants/exams";
import wordcount from "wordcount";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as stratActions from "../../actions/app/strategy";
const Panel = Collapse.Panel;
const { TextArea } = Input;

class SummarizeWrittenText extends React.Component {
  componentDidMount() {
    this.clearTimer();
    const { task, currentContent, dispatch, strat_mode_on } = this.props;
    dispatch(apiActions.updateApiSending(false));
    if (!strat_mode_on) {
      dispatch(actions.updateDuration(task[currentContent].countdown_timer));
      this.startTimer();
    }
  }
  componentWillUnmount() {
    this.clearTimer();
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { duration, timer, dispatch } = this.props;
    const newTimer = timer + 1;
    if (newTimer <= duration) {
      dispatch(actions.updateTimer(newTimer));
    } else {
      this.nextTask();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  inputChange = (e) => {
    const { dispatch } = this.props;
    dispatch(actions.updateAnswer(e.target.value));
  };
  inputChange2 = (e) => {
    const { dispatch } = this.props;
    dispatch(actions.updateAnswer2(e.target.value));
  };
  nextTask = () => {
    this.clearTimer();
    const {
      dispatch,
      task,
      currentContent,
      answer,
      answerInstance,
      answer2,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      this.updateAnswer(answer, answer2);
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "writing"
            )
          );
        }, 1500);
      } else {
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(actions.reset());
        dispatch(actions.updateDuration(task[newCurrentTask].countdown_timer));
        if (task[currentContent + 1].exam_type !== EXAM_TYPES.TASK6) {
          this.clearTimer();
        } else {
          this.startTimer();
        }
        dispatch(appActions.updateCurrentContent(newCurrentTask));
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  };
  copyToClipboard(e) {
    const text_area = this.textArea.textAreaRef;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    this.props.dispatch(actions.updateClipboard(selectedText));
  }
  cutToClipboard(e) {
    const { dispatch } = this.props;
    const text_area = this.textArea.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    dispatch(actions.updateClipboard(selectedText));
    const cut_text = value.substr(0, start) + value.substr(end);
    dispatch(actions.updateAnswer(cut_text));
  }
  paste(e) {
    const { dispatch, clip_board } = this.props;
    const text_area = this.textArea.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const result =
      value.substring(0, start) +
      clip_board +
      value.substring(start, value.length);
    dispatch(actions.updateAnswer(result));
  }
  copyToClipboard2(e) {
    const text_area = this.textArea2.textAreaRef;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    this.props.dispatch(actions.updateClipboard2(selectedText));
  }
  cutToClipboard2(e) {
    const { dispatch } = this.props;
    const text_area = this.textArea2.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const end = text_area.selectionEnd;
    const selectedText = text_area.value.substring(start, end);
    dispatch(actions.updateClipboard2(selectedText));
    const cut_text = value.substr(0, start) + value.substr(end);
    dispatch(actions.updateAnswer2(cut_text));
  }
  paste2(e) {
    const { dispatch, clip_board2 } = this.props;
    const text_area = this.textArea2.textAreaRef;
    const value = text_area.value;
    const start = text_area.selectionStart;
    const result =
      value.substring(0, start) +
      clip_board2 +
      value.substring(start, value.length);
    dispatch(actions.updateAnswer2(result));
  }
  ref2 = (text_area) => {
    this.textArea2 = text_area;
  };
  ref = (text_area) => {
    this.textArea = text_area;
  };
  updateAnswer(answer, second_answer) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = second_answer;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "writing"));
  }
  render() {
    const { task, currentContent, answer, answer2, strat_mode_on } = this.props;
    const total_words = wordcount(answer);
    const total_words2 = wordcount(answer2);
    return (
      <div>
        <Row className="text-center">
          <Col span={24}>
            <p className="content text-left">
              <div
                className="margin-zero"
                dangerouslySetInnerHTML={{
                  __html: task[currentContent].content,
                }}
              />
            </p>
          </Col>
          <Col span={12} className="text-left">
            <Button
              className="copy-cut-paste"
              size="small"
              type="dashed"
              onClick={this.copyToClipboard.bind(this)}
            >
              Copy
            </Button>
            &nbsp;
            <Button
              className="copy-cut-paste"
              size="small"
              type="dashed"
              onClick={this.cutToClipboard.bind(this)}
            >
              Cut
            </Button>
            &nbsp;
            <Button
              className="copy-cut-paste"
              size="small"
              type="dashed"
              onClick={this.paste.bind(this)}
            >
              Paste
            </Button>
          </Col>
          <Col span={12} className="text-right">
            <p
              className={total_words < 5 || 75 < total_words ? "countdown" : ""}
            >
              Word Count: {total_words}
            </p>
          </Col>
          <Col span={24}>
            <TextArea
              disabled={strat_mode_on}
              ref={this.ref}
              rows={10}
              className={
                total_words < 5 || 75 < total_words ? "text-overflow" : ""
              }
              onChange={this.inputChange.bind(this)}
              value={answer}
            />
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Write 2nd Answer" key="1">
                    <Row>
                      <Col span={12} className="text-left">
                        <Button
                          className="copy-cut-paste"
                          size="small"
                          type="dashed"
                          onClick={this.copyToClipboard2.bind(this)}
                        >
                          Copy
                        </Button>
                        &nbsp;
                        <Button
                          className="copy-cut-paste"
                          size="small"
                          type="dashed"
                          onClick={this.cutToClipboard2.bind(this)}
                        >
                          Cut
                        </Button>
                        &nbsp;
                        <Button
                          className="copy-cut-paste"
                          size="small"
                          type="dashed"
                          onClick={this.paste2.bind(this)}
                        >
                          Paste
                        </Button>
                      </Col>
                      <Col span={12} className="text-right">
                        <p
                          className={
                            total_words2 < 5 || 75 < total_words2
                              ? "countdown"
                              : ""
                          }
                        >
                          Word Count: {total_words2}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="text-center">
                        <TextArea
                          ref={this.ref2}
                          rows={10}
                          className={
                            total_words2 < 5 || 75 < total_words2
                              ? "text-overflow"
                              : ""
                          }
                          onChange={this.inputChange2.bind(this)}
                          value={answer2}
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              onClick={this.nextTask.bind(this)}
              className="next-btn"
              disabled={this.props.sending}
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    started: state.task6.task6_started,
    timer: state.task6.timer,
    answer: state.task6.answer,
    answer2: state.task6.answer_2,
    duration: state.task6.duration,
    exam_number: state.task6.exam_number,
    user: state.profile,
    clip_board: state.task6.clip_board,
    clip_board2: state.task6.clip_board2,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    strat_mode_on: state.strategy.strat_mode_on,
  };
};

export default connect(mapStateToProps)(SummarizeWrittenText);
