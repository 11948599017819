export const TASK14_UPDATE_STARTED = "TASK14_UPDATE_STARTED";
export const TASK14_RESET = "TASK14_RESET";
export const TASK14_DURATION = "TASK14_DURATION";
export const TASK14_TIMER = "TASK14_TIMER";
export const TASK14_UPDATE_ANSWER = "TASK14_UPDATE_ANSWER";
export const TASK14_UPDATE_CURRENT_INDEX = "TASK14_UPDATE_CURRENT_INDEX";
export const TASK14_UPDATE_CURRENT_ANSWER = "TASK14_UPDATE_CURRENT_ANSWER";
export const TASK14_UPDATE_CURRENT_ANSWER2 = "TASK14_UPDATE_CURRENT_ANSWER2";
export const TASK14_UPDATE_ANSWERS = "TASK14_UPDATE_ANSWERS";
export const TASK14_UPDATE_COUNTDOWN_STARTED = "TASK14_UPDATE_COUNTDOWN_STARTED";
export const TASK14_UPDATE_COUNTDOWN_TIMER = "TASK14_UPDATE_COUNTDOWN_TIMER";
export const TASK14_UPDATE_PLAY_STATUS = "TASK14_UPDATE_PLAY_STATUS";
export const TASK14_UPDATE_PLAY_PROGRESS = "TASK14_UPDATE_PLAY_PROGRESS";
export const TASK14_UPDATE_PLAY_ENDED = "TASK14_UPDATE_PLAY_ENDED";