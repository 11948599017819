import React from 'react';
import { connect } from 'react-redux';
import { Switch, Row, Col, Form } from 'antd';
import * as actions from '../../actions/app/strategy';

class LearningStratSwitch extends React.Component{
  toggleSwitch(){
    this.props.dispatch(actions.updateLearningStratModeOn(!this.props.strat_mode_on));
  }
  render(){
    const { strat_mode_on } = this.props;
    return (
      <Row className="margin-bottom-10">
        <Col span={24} className="text-right">
          <Form layout="inline">
            <Form.Item label="Learning Strategy">
              <Switch
                onChange={this.toggleSwitch.bind(this)}
                defaultChecked={strat_mode_on}
                checked={strat_mode_on}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                size="small" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    strat_mode_on : state.strategy.strat_mode_on,
  }
};

export default connect(mapStateToProps)(LearningStratSwitch);