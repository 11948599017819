import React from 'react';
import { Menu, Icon, Layout } from 'antd';
import { Link } from "react-router-dom";
// import * as actions from '../../actions/app/sidebar';
import { connect } from 'react-redux';

const { SubMenu } = Menu;
const { Sider } = Layout;

class Dashboard extends React.Component {
  state = {
    openKeys:["sub1"],
    selectedKey:["speaking1"]
  }
  componentDidMount(){
    try{
      const location = this.props.location.pathname;
      if (location === '/dashboard/speaking'){
        this.setState({openKeys:["sub1"],selectedKey:["speaking1"]});
      } else if (location === '/dashboard/reading') {
        this.setState({openKeys:["sub1"],selectedKey:["reading3"]});
      } else if (location === '/dashboard/listening'){
        this.setState({openKeys:["sub1"],selectedKey:["listening4"]});
      } else if (location === '/dashboard/writing') {
        this.setState({openKeys:["sub1"],selectedKey:["writing2"]});
      } else if (location === '/dashboard/mic') {
        this.setState({openKeys:["sub1"],selectedKey:["mic"]});
      }
    } catch (e) {}
  }
  handleClick(location){
    if (location === '/dashboard/speaking'){
      this.setState({openKeys:["sub1"],selectedKey:["speaking1"]});
    } else if (location === '/dashboard/reading') {
      this.setState({openKeys:["sub1"],selectedKey:["reading3"]});
    } else if (location === '/dashboard/listening'){
      this.setState({openKeys:["sub1"],selectedKey:["listening4"]});
    } else if (location === '/dashboard/writing') {
      this.setState({openKeys:["sub1"],selectedKey:["writing2"]});
    }  else if (location === '/dashboard/mic') {
      this.setState({openKeys:["sub1"],selectedKey:["mic"]});
    }
  }
  render(){
    const { permits }  = this.props;
    return (
      <Sider width={250} style={{ background: '#fff' }}>
        <Menu
          defaultOpenKeys={this.state.openKeys}
          selectedKeys={this.state.selectedKey}
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
        >
          <SubMenu key="sub1" title={<span><Icon type="trophy" />Practice</span>}>
            { permits.speaking? <Menu.Item key="speaking1"><Link onClick={this.handleClick.bind(this, "/dashboard/speaking")} to="/dashboard/speaking">Speaking (Task 01-05)</Link></Menu.Item> : null}
            { permits.writing? <Menu.Item key="writing2"><Link onClick={this.handleClick.bind(this, "/dashboard/writing")} to="/dashboard/writing">Writing (Task 06-07)</Link></Menu.Item> : null}
            { permits.reading? <Menu.Item key="reading3"><Link onClick={this.handleClick.bind(this, "/dashboard/reading")} to="/dashboard/reading">Reading (Task 08-12)</Link></Menu.Item> : null}
            { permits.listening? <Menu.Item key="listening4"><Link onClick={this.handleClick.bind(this, "/dashboard/listening")} to="/dashboard/listening">Listening (Task 13-20)</Link></Menu.Item> : null}
            <Menu.Item key="mic"><Link onClick={this.handleClick.bind(this, "/dashboard/mic")} to="/dashboard/mic">Test Microphone</Link></Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSelectedKeys: state.sidebar.defaultSelectedKeys,
    defaultOpenKeys: state.sidebar.defaultOpenKeys,
    permits : state.auth.permissions,
  }
};

export default connect(mapStateToProps)(Dashboard);