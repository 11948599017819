import React from "react";
import { Row, Col, Radio, Button, Icon, Collapse } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/app/task8";
import * as strategyActions from "../../actions/app/strategy";
import * as appActions from "../../actions/app/app";
import * as apiActions from "../../actions/app/api";
import * as api from "../../actions/api/requests";
import * as stratActions from "../../actions/app/strategy";

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;

const radioStyle = {
  height: "30px",
  lineHeight: "20px",
  paddingTop: "15px",
};

class ReadingMCQ extends React.Component {
  componentDidMount() {
    this.props.dispatch(apiActions.updateApiSending(false));
    this.clearTimer();
    if (!this.props.strat_mode_on) {
      this.startLearningStratTimer();
    }
  }
  componentWillUnmount() {
    this.props.dispatch(strategyActions.reset());
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  startLearningStratTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { dispatch, reading_timer, max_reading_timer } = this.props;
    const new_timer = reading_timer + 1;
    if (new_timer <= max_reading_timer) {
      dispatch(strategyActions.updateReadingTImer(new_timer));
    } else {
      this.clearTimer();
      this.nextTask();
    }
  }
  onChange = (e) => {
    const { dispatch } = this.props;
    dispatch(actions.updateCurrentAnswer(e.target.value));
  };
  onChange2 = (e) => {
    const { dispatch } = this.props;
    dispatch(actions.updateCurrentAnswer2(e.target.value));
  };
  nextTask() {
    const {
      dispatch,
      value,
      value2,
      currentContent,
      task,
      answerInstance,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      this.updateAnswer(value, value2);
      dispatch(actions.reset());
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "reading"
            )
          );
        }, 1500);
      } else {
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(strategyActions.reset());
        this.clearTimer();
        this.startLearningStratTimer();
      }
    } else {
      this.clearTimer();
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  }
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "reading"));
  }
  render() {
    const { task, currentContent, value, value2, strat_mode_on } = this.props;
    const choices = task[currentContent].answers.map((item, index) => {
      const answerValue = index + 1;
      if (index === 0) {
        return (
          <Radio
            className={
              value === answerValue
                ? "wrap-label no-padding-top selected"
                : "wrap-label no-padding-top"
            }
            style={radioStyle}
            value={answerValue}
          >
            {item}
          </Radio>
        );
      }
      return (
        <Radio
          className={
            value === answerValue ? "wrap-label selected" : "wrap-label"
          }
          style={radioStyle}
          value={answerValue}
        >
          {item}
        </Radio>
      );
    });

    const choices2 = task[currentContent].answers.map((item, index) => {
      const answerValue = index + 1;
      if (index === 0) {
        return (
          <Radio
            className={
              value2 === answerValue
                ? "wrap-label no-padding-top selected"
                : "wrap-label no-padding-top"
            }
            style={radioStyle}
            value={answerValue}
          >
            {item}
          </Radio>
        );
      }
      return (
        <Radio
          className={
            value2 === answerValue ? "wrap-label selected" : "wrap-label"
          }
          style={radioStyle}
          value={answerValue}
        >
          {item}
        </Radio>
      );
    });
    return (
      <div>
        <Row className="text-center margin-top-15">
          <Col span={12} className="padding-15 padding-right-45">
            <p
              className="content text-left margin-zero"
              dangerouslySetInnerHTML={{ __html: task[currentContent].content }}
            ></p>
          </Col>
          <Col span={12} className="padding-15 text-left">
            <p
              className="instruction margin-zero"
              dangerouslySetInnerHTML={{
                __html: task[currentContent].instruction,
              }}
            ></p>
            <br />
            <p
              className="content margin-zero"
              dangerouslySetInnerHTML={{
                __html: task[currentContent].question,
              }}
            ></p>
            <RadioGroup
              onChange={this.onChange}
              value={value}
              disabled={strat_mode_on}
            >
              {choices}
            </RadioGroup>
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="1">
                    <p
                      className="content margin-zero"
                      dangerouslySetInnerHTML={{
                        __html: task[currentContent].question,
                      }}
                    ></p>
                    <RadioGroup onChange={this.onChange2} value={value2}>
                      {choices2}
                    </RadioGroup>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending}
              onClick={this.nextTask.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    value: state.task8.current_answer,
    current_index: state.task8.current_index,
    answers: state.task8.answers,
    value2: state.task8.current_answer2,
    user: state.profile,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    reading_timer: state.strategy.reading_timer,
    max_reading_timer: state.strategy.max_reading_timer,
    strat_mode_on: state.strategy.strat_mode_on,
  };
};

export default connect(mapStateToProps)(ReadingMCQ);
