import * as actions from "../actions/types/strategy";

const defaultState = {
  strat_mode_on: false,
  started: false,
  reading_timer: 0,
  max_reading_timer: 120,
  listening_timer: 0,
  max_listening_timer_1: 600,
  max_listening_timer_2: 30,
  max_listening_timer_3: 30,
  settings: {
    instruction: null,
    vocabulary: null,
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.UPDATE_READING_TIMER: {
      return { ...state, reading_timer: action.payload };
    }
    case actions.UPDATE_LISTENING_TIMER: {
      return { ...state, listening_timer: action.payload };
    }
    case actions.UPDATE_LEARNING_STRAT_STARTED: {
      return { ...state, started: action.payload };
    }
    case actions.UPDATE_LEARNING_STRAT_MODE_ON: {
      return { ...state, strat_mode_on: action.payload };
    }
    case actions.UPDATE_LEARNING_STRAT_SETTINGS: {
      return { ...state, settings: action.payload };
    }
    case actions.LEARNING_STRAT_RESET: {
      return {
        ...state,
        started: false,
        reading_timer: 0,
        listening_timer: 0,
      };
    }
    default:
      return { ...state };
  }
}
