import React from "react";
import Routes from "./Route";
import { Provider } from "react-redux";
import store from "./store/store";
import "./includes/css/style.css";
import { createRoot } from "react-dom/client";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Amplify } from "aws-amplify";

const region = process.env.REACT_APP_REGION;
const endpoint = process.env.REACT_APP_API_URL;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "pte-api",
        region,
        endpoint,
      },
    ],
  },
  Storage: {
    bucket: "pte-indiidual-student-answers",
    region: "ap-souteast-1",
  },
});

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router history={createBrowserHistory()}>
      <Routes />
    </Router>
  </Provider>
);
