export const TASK13_UPDATE_STARTED = "TASK13_UPDATE_STARTED";
export const TASK13_RESET = "TASK13_RESET";
export const TASK13_DURATION = "TASK13_DURATION";
export const TASK13_TIMER = "TASK13_TIMER";
export const TASK13_UPDATE_ANSWER = "TASK13_UPDATE_ANSWER";
export const TASK13_UPDATE_ANSWER2 = "TASK13_UPDATE_ANSWER2";
export const TASK13_UPDATE_COUNTDOWN_STARTED = "TASK13_UPDATE_COUNTDOWN_STARTED";
export const TASK13_UPDATE_COUNTDOWN_TIMER = "TASK13_UPDATE_COUNTDOWN_TIMER";
export const TASK13_UPDATE_PLAY_STATUS = "TASK13_UPDATE_PLAY_STATUS";
export const TASK13_UPDATE_PLAY_PROGRESS = "TASK13_UPDATE_PLAY_PROGRESS";
export const TASK13_UPDATE_PLAY_ENDED = "TASK13_UPDATE_PLAY_ENDED";
export const TASK13_UPDATE_EXAM_NUMBER = "TASK13_UPDATE_EXAM_NUMBER";
export const TASK13_UPDATE_EXAM_TIMER = "TASK13_UPDATE_EXAM_TIMER";
export const TASK13_UPDATE_EXAM_TIMER_STARTED = "TASK13_UPDATE_EXAM_TIMER_STARTED";
export const TASK13_UPDATE_CLIP_BOARD = "TASK13_UPDATE_CLIP_BOARD";
export const TASK13_UPDATE_CLIP_BOARD2 = "TASK13_UPDATE_CLIP_BOARD2";