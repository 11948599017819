import React from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { connect } from "react-redux";
import * as answerInstance from "../../actions/app/answerInstance";
import { deleteExam, getAnswerInstances } from "../../actions/api/requests";
import { formatDate } from "../../utils/format";

class SpeakingAnswers extends React.Component {
  constructor(props) {
    super(props);
  }

  review = (record) => {
    const { answerInstances } = this.props;
    this.props.dispatch(
      answerInstance.updateAnswerInstance(
        answerInstances.find((obj) => obj.id === record.id)
      )
    );
    this.props.history.push("/review");
  };
  delete = async (record) => {
    const { answerInstances } = this.props;
    const answer = answerInstances.find((obj) => obj.id === record.id);
    const result = await deleteExam(this.props.examType, answer.id);
    if (result) {
      message.success("Delete successful.", 10);
      this.props.dispatch(getAnswerInstances(this.props.examType));
    } else {
      message.error("Delete failed.", 10);
    }
  };
  render() {
    const { answerInstances } = this.props;
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Code(s)",
        dataIndex: "data",
        key: "data",
        render: (data) => (
          <>
            {data.map((obj) => (
              <>
                {obj.code}
                <br />
              </>
            ))}
          </>
        ),
      },
      {
        title: "Finished",
        dataIndex: "finished",
        key: "finished",
        render: (finished) => (finished ? "Finished" : "Not finished"),
      },
      {
        title: "Started At",
        dataIndex: "created_at",
        key: "created_at",
        render: (created_at) => formatDate(new Date(created_at)),
        sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Completed At",
        dataIndex: "completed_at",
        key: "completed_at",
        render: (completed_at) =>
          completed_at ? formatDate(new Date(completed_at)) : "Not finished",
        sorter: (a, b) => new Date(a.completed_at) - new Date(b.completed_at),
        defaultSortOrder: "descend",
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Action",
        dataIndex: "completed_at",
        key: "completed_at",
        render: (completed_at, record, index) => (
          <>
            <Button type="primary" onClick={this.review.bind(this, record)}>
              Review
            </Button>{" "}
            <Popconfirm
              placement="top"
              title={"Are you sure you want to delete this exam?"}
              onConfirm={this.delete.bind(this, record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </>
        ),
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={answerInstances.filter((answer) => answer.finished)}
          size="small"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    answerInstances: state.speakingReview.answerInstances,
  };
};

export default connect(mapStateToProps)(SpeakingAnswers);
