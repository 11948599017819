import * as actions from '../actions/types/task8';

const defaultState = {
  current_index:0,
  current_answer:-1,
  answers:[],
  current_answer2:-1,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK8_UPDATE_CURRENT_INDEX: {
      return {...state, current_index:action.payload};
    }
    case actions.TASK8_UPDATE_CURRENT_ANSWER: {
      return {...state, current_answer:action.payload};
    }
    case actions.TASK8_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK8_UPDATE_CURRENT_ANSWER2: {
      return {...state, current_answer2:action.payload};
    }
    case actions.TASK8_RESET: {
      return {
        ...state,
        current_index:0,
        current_answer:-1,
        answers: [],
        current_answer2:-1,
      };
    }
    default: return {...state};
  }
};