import * as task9 from '../types/task9';

export const updateAnswers = (payload) => {
  return dispatch => {
    dispatch({
      type    : task9.TASK9_UPDATE_ANSWERS,
      payload : payload,
    });
  };
};

export const updateCurrentAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task9.TASK9_UPDATE_CURRENT_ANSWER,
      payload : payload,
    });
  };
};

export const updateCurrentAnswer2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task9.TASK9_UPDATE_CURRENT_ANSWER2,
      payload : payload,
    });
  };
};

export const updateCurrentIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : task9.TASK9_UPDATE_CURRENT_INDEX,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : task9.TASK9_RESET,
    });
  };
};