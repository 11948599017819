import * as actions from '../actions/types/auth';

const defaultState = {
  is_authenticated:false,
  token:"",
  username:"",
  permissions:{
    speaking:true,
    listening:true,
    reading:true,
    writing:true,
  }
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_IS_AUTHENTICATED: {
      return {...state, is_authenticated: action.payload};
    }
    case actions.UPDATE_TOKEN: {
      return {...state, token: action.payload};
    }
    case actions.UPDATE_USERNAME: {
      return {...state, username: action.payload};
    }
    case actions.AUTH_UPDATE_PERMISSIONS: {
      return {...state, permissions: action.payload};
    }
    case actions.RESET_AUTH: {
      return {...defaultState};
    }
    default: return {...state};
  }
};