import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import * as appActions from '../../actions/app/app';
import * as EXAM_TYPES from '../../constants/exams';

function format(seconds){
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`
  }
  return `${mm}:${ss}`
}

function pad (string) {
  return ('0' + string).slice(-2)
}

class Timer extends React.Component{
  componentDidMount(){
    clearInterval(this.timer);
    this.timer = null;
    this.startTimer();
  }
  componentWillUnmount(){
    clearInterval(this.timer);
    this.timer = null;
  }
  startTimer(){
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick(){
    const { dispatch, exam_time, main_timer } = this.props;
    const new_time = main_timer + 1;
    if (new_time <= exam_time){
      dispatch(appActions.updateMainTimer(new_time));
    } else {
      // dispatch(appActions.updateMainTimer(0))
      // dispatch(appActions.updateCurrentPage(currentPage+1))
    }
  }
  padZero(num) {
    const zero = 2 - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }
  render(){
    const {
      currentContent,
      task,
      task6_timer,
      task6_duration,
      task7_timer,
      task7_duration,
      exam_timer,
      reading_timer,
      max_reading_timer,
      listening_timer,
      max_listening_timer_2,
      max_listening_timer_3,
      strat_mode_on,
    } = this.props;
    return (
      <Row>
        <Col span={24} className="text-center">
          <span className="essay-timer">
            {
              strat_mode_on?
                null
              :
              task[currentContent].exam_type === EXAM_TYPES.TASK6?
                <b>{format(task6_duration - task6_timer)}</b>
              :
              task[currentContent].exam_type === EXAM_TYPES.TASK7?
                <b>{format(task7_duration - task7_timer)}</b>
              :
              task[currentContent].exam_type === EXAM_TYPES.TASK13 && exam_timer <= task[currentContent].countdown_timer?
                <b>{format(task[currentContent].countdown_timer - exam_timer)}</b>
              :
              task[currentContent].exam_type === EXAM_TYPES.TASK8  ||
              task[currentContent].exam_type === EXAM_TYPES.TASK9  ||
              task[currentContent].exam_type === EXAM_TYPES.TASK10 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK11 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK12?
              <b>{format(max_reading_timer - reading_timer)}</b>
              :
              task[currentContent].exam_type === EXAM_TYPES.TASK14 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK15 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK16 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK17 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK18 ||
              task[currentContent].exam_type === EXAM_TYPES.TASK19?
              <b>{format(max_listening_timer_2 - listening_timer)}</b>
              :
              task[currentContent].exam_type === EXAM_TYPES.TASK20?
              <b>{format(max_listening_timer_3 - listening_timer)}</b>
              :
              null
            }
          </span>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent : state.app.currentContent,
    task           : state.app.examContent,
    main_timer     : state.app.main_timer,
    exam_time      : state.app.exam_time,
    task6_timer    : state.task6.timer,
    task6_duration : state.task6.duration,
    task7_timer    : state.task7.timer,
    task7_duration : state.task7.duration,
    exam_timer     : state.task13.exam_timer,
    reading_timer         : state.strategy.reading_timer,
    max_reading_timer     : state.strategy.max_reading_timer,
    listening_timer       : state.strategy.listening_timer,
    max_listening_timer_2 : state.strategy.max_listening_timer_2,
    max_listening_timer_3 : state.strategy.max_listening_timer_3,
    strat_mode_on  : state.strategy.strat_mode_on,
  }
};

export default connect(mapStateToProps)(Timer);