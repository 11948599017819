import * as task4 from '../types/task4';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updatePlayBeep = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_PLAY_BEEP,
      payload : payload,
    });
  };
};

export const updateRecordingStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_RECORDING_STATUS,
      payload : payload,
    });
  };
};

export const updateRecordingTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_RECORDING_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_RECORDING_DURATION,
      payload : payload,
    });
  };
};

export const updateSequence = () => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_RESET_SEQUENCE,
    });
  };
};

export const updatePlayStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_PLAY_STATUS,
      payload : payload,
    });
  };
};

export const updatePlayDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_PLAY_DURATION,
      payload : payload,
    });
  };
};

export const updatePlayProgress = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_PLAY_PROGRESS,
      payload : payload,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};

export const updateBeginTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_BEGIN_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingComplete = (payload) => {
  return dispatch => {
    dispatch({
      type    : task4.TASK4_UPDATE_RECORDING_COMPLETE,
      payload : payload,
    });
  };
};