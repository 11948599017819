import axios from 'axios'

export default function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token
    axios.defaults.headers.common['Content-Type'] = 'application/json'
  } else {
    delete axios.defaults.headers.common['Authorization']
    delete axios.defaults.headers.common['Content-Type']
  }
}
