import * as actions from '../actions/types/task9';

const defaultState = {
  current_index:0,
  current_answer:[],
  current_answer2:[],
  answers: [],
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK9_UPDATE_CURRENT_INDEX: {
      return {...state, current_index:action.payload};
    }
    case actions.TASK9_UPDATE_CURRENT_ANSWER: {
      return {...state, current_answer:action.payload};
    }
    case actions.TASK9_UPDATE_CURRENT_ANSWER2: {
      return {...state, current_answer2:action.payload};
    }
    case actions.TASK9_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK9_RESET: {
      return {
        ...state,
        current_index:0,
        current_answer:[],
        current_answer2:[],
        answers: [],
      };
    }
    default: return {...state};
  }
};