import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/app/task12';

class BlankSelect extends React.Component{
  handleSelectChange = (e) => {
    const { dispatch, answers, itemNumber } = this.props;
    const newAnswers = answers.slice();
    newAnswers[itemNumber] = e.target.value;
    dispatch(actions.updateAnswers2(newAnswers));
  }
  render(){
    const { task, currentContent, itemNumber, answers } = this.props;
    let option = [];
    option.push(<option key={"option-1"} value={""}></option>);
    if (itemNumber !== undefined){
      // eslint-disable-next-line
      task[currentContent].answers[itemNumber].map((answer, index) => {
        option.push(<option key={"option"+index} value={answer}>{answer}</option>);
      })
    }
    return (
      <select className="width-150" defaultValue="" onChange={this.handleSelectChange.bind(this)} value={answers[itemNumber]}>
        {option}
      </select>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentContent : state.app.currentContent,
    currentPage    : state.app.currentPage,
    task           : state.app.examContent,
    choices        : state.task12.choices2,
    answers        : state.task12.answers2,
    chosenAnswer   : state.task12.chosenAnswer,
  }
};

export default connect(mapStateToProps)(BlankSelect);