import * as task3 from '../types/task3';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updatePlayBeep = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_PLAY_BEEP,
      payload : payload,
    });
  };
};

export const updateRecordingStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_RECORDING_STATUS,
      payload : payload,
    });
  };
};

export const updateRecordingTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_RECORDING_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_RECORDING_DURATION,
      payload : payload,
    });
  };
};

export const updateSequence = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_RESET_SEQUENCE,
      payload : payload,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task3.TASK3_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};
