import React from "react";
import {
  Table,
  Divider,
  Row,
  Col,
  Button,
  Input,
  Select,
  Switch,
  InputNumber,
  Popconfirm,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import * as exams from "../../constants/exams";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as stratActions from "../../actions/app/strategy";
import * as answerInstanceActions from "../../actions/app/answerInstance";

const Option = Select.Option;

class ReadingBuilder extends React.Component {
  componentDidMount() {
    this.props.dispatch(stratActions.updateLearningStratModeOn(false));
    this.props.dispatch(api.getListeningQuestionsCount());
    this.props.dispatch(apiActions.updateApiSending(false));
    this.props.dispatch(answerInstanceActions.reset());
    this.nameInput.focus();
  }
  state = {
    name: "My Listening Exam",
    questions: [
      {
        index: 0,
        exam_type: exams.TASK13,
        subtype: null,
        count: 1,
        repeat: true,
      },
    ],
  };
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  handleTaskChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.exam_type = value;
      }
    });
    this.setState({ questions: data });
  };
  handleSubtypeChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.subtype = value;
      }
    });
    this.setState({ questions: data });
  };
  handleCountChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.count = value;
      }
    });
    this.setState({ questions: data });
  };
  handleRepeatChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.repeat = value;
      }
    });
    this.setState({ questions: data });
  };
  getNewQuestion() {
    return {
      index: this.state.questions.length,
      exam_type: exams.TASK13,
      subtype: null,
      count: 1,
      repeat: true,
    };
  }
  addNewQuestion() {
    const data = this.state.questions.slice();
    data.push(this.getNewQuestion());
    this.setState({ questions: data });
  }
  remove(index) {
    if (this.state.questions.length > 1) {
      const data = this.state.questions.slice();
      const newData = data.filter((item) => {
        return item.index !== index;
      });
      this.setState({ questions: newData });
    }
  }
  confirmProceed() {
    this.generatePractice();
  }
  generatePractice() {
    const { history, dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(api.generateListening(this.state, history));
  }
  errorMessage() {
    // message.error('Please allow this app to use the microphone.', 5);
  }
  render() {
    const { questions } = this.state;
    const { listeningReview, sending } = this.props;
    const columns = [
      {
        title: "Question Type",
        dataIndex: "index",
        key: "qtype",
        render: (index, record) => (
          <Select
            onChange={this.handleTaskChange.bind(this, index)}
            value={record.exam_type}
            style={{ width: "100%" }}
          >
            <Option value={exams.TASK13}>
              Task 13 Summarize Spoken Text ({listeningReview.exam13Count})
            </Option>
            <Option value={exams.TASK14}>
              Task 14 Listening: Multiple Choice - Choose Multiple Answers (
              {listeningReview.exam14Count})
            </Option>
            <Option value={exams.TASK15}>
              Task 15 Listening: Fill In The Blanks (
              {listeningReview.exam15Count})
            </Option>
            <Option value={exams.TASK16}>
              Task 16 Highlight Correct Summary ({listeningReview.exam16Count})
            </Option>
            <Option value={exams.TASK17}>
              Task 17 Listening: Multiple Choice - Choose Single Answer (
              {listeningReview.exam17Count})
            </Option>
            <Option value={exams.TASK18}>
              Task 18 Select Missing Word ({listeningReview.exam18Count})
            </Option>
            <Option value={exams.TASK19}>
              Task 19 Highlight Incorrect Words ({listeningReview.exam19Count})
            </Option>
            <Option value={exams.TASK20}>
              Task 20 Write From Dictation ({listeningReview.exam20Count})
            </Option>
          </Select>
        ),
      },
      {
        title: "Question Subtype",
        dataIndex: "index",
        key: "subtype",
        render: () => <p className="padding-top-10">No subtype</p>,
      },
      {
        title: "Quantity",
        dataIndex: "index",
        key: "quantity",
        render: (index, item) => (
          <InputNumber
            value={item.count}
            onChange={this.handleCountChange.bind(this, index)}
            min={1}
            defaultValue={1}
          />
        ),
      },
      {
        title: "Remove",
        dataIndex: "index",
        key: "remove",
        render: (index) => (
          <a onClick={this.remove.bind(this, index)}>Remove</a>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={6} className="text-left">
            <Tooltip
              trigger={["focus"]}
              title={"Enter the name of this test"}
              placement="topLeft"
            >
              <Input
                ref={(input) => {
                  this.nameInput = input;
                }}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
                placeholder="Please enter the name of this practice session"
              />
            </Tooltip>
          </Col>
        </Row>
        <br />
        <Table columns={columns} dataSource={questions} size="small" />
        <Row>
          <Col span={24} className="text-center">
            <a onClick={this.addNewQuestion.bind(this)}>Add New Question</a>
          </Col>
          <br />
          <Divider />
          <Col span={24} className="text-center">
            {sending ? (
              <Button disabled={sending} type="primary">
                Practice Now
              </Button>
            ) : (
              <Popconfirm
                placement="top"
                title={"Do you want to proceed with the practice test?"}
                onConfirm={this.confirmProceed.bind(this)}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={sending} type="primary">
                  Practice Now
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSelectedKeys: state.sidebar.defaultSelectedKeys,
    defaultOpenKeys: state.sidebar.defaultOpenKeys,
    listeningReview: state.listeningReview,
    sending: state.api.sending,
  };
};

export default connect(mapStateToProps)(ReadingBuilder);
