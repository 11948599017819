import * as actions from '../actions/types/task6';

const defaultState = {
  task6_started:false,
  duration : 0,
  timer: 0,
  answer: "",
  answer_2:"",
  exam_number: 1,
  clip_board: "",
  clip_board2: "",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK6_UPDATE_STARTED: {
      return {...state, task6_started:action.payload};
    }
    case actions.TASK6_UPDATE_EXAM_NUMBER: {
      return {...state, exam_number:action.payload};
    }
    case actions.TASK6_DURATION: {
      return {...state, duration:action.payload};
    }
    case actions.TASK6_TIMER: {
      return {...state, timer:action.payload};
    }
    case actions.TASK6_UPDATE_ANSWER2: {
      return {...state, answer_2:action.payload};
    }
    case actions.TASK6_UPDATE_ANSWER: {
      return {...state, answer:action.payload};
    }
    case actions.TASK6_UPDATE_CLIP_BOARD: {
      return {...state, clip_board:action.payload};
    }
    case actions.TASK6_UPDATE_CLIP_BOARD2: {
      return {...state, clip_board2:action.payload};
    }
    case actions.TASK6_RESET: {
      return {
        ...state,
        duration : 0,
        timer: 0,
        answer: "",
        answer_2:"",
        clip_board: "",
        clip_board2: "",
      };
    }
    default: return {...state};
  }
};