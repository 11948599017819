import * as login from '../types/login';

export const updateLoginForm = (payload) => {
  return dispatch => {
    dispatch({
      type    : login.UPDATE_LOGIN_FORM,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : login.RESET_LOGIN_FORM,
    });
  };
};