import React from "react";
import { Row, Col, Icon, Button, Collapse, Tooltip } from "antd";
import * as Common from "../common";
import { connect } from "react-redux";
import * as api from "../../actions/api/requests";
import * as appActions from "../../actions/app/app";
import * as actions from "../../actions/app/task1";
import * as stratActions from "../../actions/app/strategy";
import * as apiActions from "../../actions/app/api";
import * as EXAM_TYPES from "../../constants/exams";
import { FaMicrophone } from "react-icons/fa";
import { ReactMic } from "react-mic";
import { uploadFile } from "../../actions/api/storage";

const Panel = Collapse.Panel;

class ReadAloud extends React.Component {
  state = {
    recording: false,
  };
  toggleRecording(payload) {
    this.setState({ recording: payload });
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  componentDidMount() {
    this.clearTimer();
    const {
      dispatch,
      currentContent,
      task,
      task1,
      app,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(false));
    if (!strat_mode_on) {
      if (!task1.task1_started) {
        dispatch(
          appActions.updateReadingCountDown(task[currentContent].countdown)
        );
        dispatch(
          appActions.updateReadingCountdownStatus(
            task[currentContent].record_duration
          )
        );
        dispatch(
          appActions.updateRecordCountdown(task[currentContent].record_duration)
        );
        dispatch(actions.updateTask1Started(true));
        dispatch(actions.updateReading(true));
        this.startTimer();
        return;
      } else if (app.reading_countdown_status) {
        dispatch(appActions.updateBeep(false));
        this.startTimer();
      } else if (app.recording) {
        dispatch(
          appActions.updateRecordCountdown(task[currentContent].record_duration)
        );
        this.startTimer2();
      }
    }
  }
  tick() {
    const { dispatch, app } = this.props;
    const time_remaining = app.reading_countdown - 1;
    if (time_remaining >= 0) {
      dispatch(appActions.updateReadingCountDown(time_remaining));
    } else {
      dispatch(appActions.updateReadingStatus(false));
      dispatch(appActions.updateBeep(true));
      this.clearTimer();
    }
  }
  tick2() {
    const { dispatch, app } = this.props;
    const time_remaining = app.recording_countdown - 1;
    if (time_remaining >= 0) {
      dispatch(appActions.updateRecordCountdown(time_remaining));
    } else {
      this.clearTimer();
      dispatch(appActions.updateRecordingStatus(false));
    }
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  startTimer2() {
    this.timer = setInterval(this.tick2.bind(this), 1000);
  }
  onEnded() {
    const { dispatch } = this.props;
    dispatch(appActions.updateReadingCountdownStatus(false));
    dispatch(appActions.updateBeep(false));
    dispatch(appActions.updateRecordingStatus(true));
    this.startTimer2();
  }
  updateAnswer(url) {
    this.clearTimer();
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = url;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "speaking"));
  }
  update2ndAnswer(url) {
    const { dispatch, answerInstance } = this.props;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data[newAnswerInstance.data.length - 1][
      "second_answer"
    ] = url;
    dispatch(api.updateAnswerInstance(newAnswerInstance, "speaking"));
  }
  onStop = (recordedBlob) => {
    const { user } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", recordedBlob.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.username;
      const audioAnswer = new File([xhr.response], "answer.webm");
      const key =
        "student-answers/individual/read-aloud/" +
        userName +
        "/" +
        Date.now() +
        ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          this.updateAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  uploadSecondAnswer = () => {
    const { user } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", this.state.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.username;
      const audioAnswer = new File([xhr.response], "answer.webm");
      const key =
        "student-answers/individual/read-aloud/" +
        userName +
        "/" +
        Date.now() +
        ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          console.log(result);
          this.update2ndAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  nextTask() {
    this.clearTimer();
    const {
      dispatch,
      currentContent,
      task,
      answerInstance,
      strat_mode_on,
    } = this.props;
    if (strat_mode_on) {
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "speaking"
            )
          );
        }, 1500);
      } else {
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(appActions.resetAudioRecorder());
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        if (task[newCurrentTask].exam_type === EXAM_TYPES.TASK1) {
          dispatch(
            appActions.updateReadingCountDown(task[newCurrentTask].countdown)
          );
          dispatch(
            appActions.updateReadingCountdownStatus(
              task[newCurrentTask].record_duration
            )
          );
          dispatch(
            appActions.updateRecordCountdown(
              task[newCurrentTask].record_duration
            )
          );
          dispatch(actions.updateTask1Started(true));
          dispatch(actions.updateReading(true));
          this.startTimer();
        }
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
    }
  }
  handleNextBtn() {
    // this.nextTask();
    const { dispatch, recording, strat_mode_on } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      if (this.state.blobURL) {
        this.uploadSecondAnswer();
      } else {
        dispatch(apiActions.updateApiSending(false));
        this.nextTask();
      }
    } else {
      if (recording) {
        this.clearTimer();
        dispatch(appActions.updateRecordingStatus(false));
      } else {
        this.updateAnswer("");
        this.nextTask();
      }
    }
  }
  componentWillUnmount() {
    this.clearTimer();
  }
  onRecordingStop = (blobObject) => {
    this.setState({ blobURL: blobObject.blobURL });
  };
  render() {
    const { task, currentContent, strat_mode_on } = this.props;
    const { recording } = this.state;
    return (
      <div>
        <Common.AudioRecorder
          {...this.props}
          onStop={this.onStop.bind(this)}
          onEnded={this.onEnded.bind(this)}
        />
        <Row className="margin-top-30">
          <Col span={24}>
            <p className="content">{task[currentContent].text}</p>
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Record 2nd Answer" key="1">
                    <Row>
                      <Col span={8}>
                        <Row className="text-center">
                          <audio
                            ref="audioSource"
                            controls="controls"
                            src={this.state.blobURL}
                          ></audio>
                        </Row>
                        <Row className="text-center">
                          <ReactMic
                            className="soundwave"
                            record={recording}
                            backgroundColor="#1890ff"
                            audioBitsPerSecond={128000}
                            onStop={this.onRecordingStop.bind(this)}
                            strokeColor="#fff"
                            style={{ width: "80%" }}
                          />
                        </Row>
                        <Row className="text-center">
                          <Tooltip title="Start recording">
                            <Button
                              onClick={this.toggleRecording.bind(this, true)}
                              disabled={recording}
                              type="primary"
                              shape="circle"
                              size="large"
                            >
                              <FaMicrophone />
                            </Button>
                          </Tooltip>
                          &nbsp;&nbsp;
                          <Tooltip title="Stop recording">
                            <Button
                              onClick={this.toggleRecording.bind(this, false)}
                              disabled={!recording}
                              type="primary"
                              shape="circle"
                              icon="pause"
                              size="large"
                            />
                          </Tooltip>
                        </Row>
                      </Col>
                      `
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending || this.state.recording}
              onClick={this.handleNextBtn.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    app: state.app,
    task1: state.task1,
    currentContent: state.app.currentContent,
    task: state.app.examContent,
    countdown_status: state.app.reading_countdown_status,
    countdown: state.app.reading_countdown,
    play_beep: state.app.play_beep,
    recording: state.app.recording,
    recording_countdown: state.app.recording_countdown,
    user: state.auth,
    exam_number: state.task1.exam_number,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    strat_mode_on: state.strategy.strat_mode_on,
    instruction: state.strategy.settings.instruction,
    vocabulary: state.strategy.settings.vocabulary,
    beepUrl: state.api.beepUrl,
  };
};

export default connect(mapStateToProps)(ReadAloud);
