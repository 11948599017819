import React from 'react';
import { Row, Col, Input, } from 'antd';

class FillInSelect extends React.Component{
  render(){
    const select = (
      <Input size="small" className="fill-in-input" />
    );
    return (
      <Row className="text-left height-50 gray-border">
        <Row>
          <Col span={24}>
            <p className="fill-select-content margin-auto">
              Ex laboris laborum culpa {select} dolor Lorem enim ad culpa sint magna. Enim pariatur laborum mollit enim do in {select} adipisicing mollit commodo dolor duis duis. Et ipsum cupidatat culpa veniam excepteur dolore. Aliqua sunt incididunt {select} elit magna amet occaecat nulla eu ea cupidatat ut ut ea. Aliquip commodo occaecat nisi in culpa minim nostrud veniam velit. Laborum consequat {select} occaecat velit in proident sunt duis dolor. Sint enim ipsum excepteur enim quis ea do. Cillum fugiat non sint elit est veniam minim {select} velit.
            </p>
          </Col>
        </Row>
      </Row>
    );
  }
}

export default FillInSelect;