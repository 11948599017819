import * as task19 from '../types/task19';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_DURATION,
      payload : payload,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_TIMER,
      payload : payload,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_ANSWER,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updatePlayStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_PLAY_STATUS,
      payload : payload,
    });
  };
};

export const updatePlayProgress = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_PLAY_PROGRESS,
      payload : payload,
    });
  };
};

export const updatePlayEnded = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_PLAY_ENDED,
      payload : payload,
    });
  };
};


export const reset = () => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_RESET,
    });
  };
};

export const updateAnswers = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_ANSWERS,
      payload : payload,
    });
  };
};

export const updateCurrentAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_CURRENT_ANSWER,
      payload : payload,
    });
  };
};

export const updateCurrentAnswer2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_CURRENT_ANSWER2,
      payload : payload,
    });
  };
};

export const updateCurrentIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : task19.TASK19_UPDATE_CURRENT_INDEX,
      payload : payload,
    });
  };
};