export const TASK18_UPDATE_STARTED = "TASK18_UPDATE_STARTED";
export const TASK18_RESET = "TASK18_RESET";
export const TASK18_DURATION = "TASK18_DURATION";
export const TASK18_TIMER = "TASK18_TIMER";
export const TASK18_UPDATE_ANSWER = "TASK18_UPDATE_ANSWER";
export const TASK18_UPDATE_CURRENT_INDEX = "TASK18_UPDATE_CURRENT_INDEX";
export const TASK18_UPDATE_CURRENT_ANSWER = "TASK18_UPDATE_CURRENT_ANSWER";
export const TASK18_UPDATE_CURRENT_ANSWER2 = "TASK18_UPDATE_CURRENT_ANSWER2";
export const TASK18_UPDATE_ANSWERS = "TASK18_UPDATE_ANSWERS";
export const TASK18_UPDATE_COUNTDOWN_STARTED = "TASK18_UPDATE_COUNTDOWN_STARTED";
export const TASK18_UPDATE_COUNTDOWN_TIMER = "TASK18_UPDATE_COUNTDOWN_TIMER";
export const TASK18_UPDATE_PLAY_STATUS = "TASK18_UPDATE_PLAY_STATUS";
export const TASK18_UPDATE_PLAY_PROGRESS = "TASK18_UPDATE_PLAY_PROGRESS";
export const TASK18_UPDATE_PLAY_ENDED = "TASK18_UPDATE_PLAY_ENDED";