import React from "react";
import { connect } from "react-redux";
import { Tabs } from "antd";

import Listening from "./ListeningBuilder";
import SpeakingAnswers from "./SpeakingAnswers";
import * as api from "../../actions/api/requests";

const TabPane = Tabs.TabPane;

class ListeningTabs extends React.Component {
  componentDidMount() {
    this.props.dispatch(api.getAnswerInstances("listening"));
  }
  render() {
    if (!this.props.permitted) {
      this.props.history.push("/dashboard");
    }
    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab="Start a New Practice" key="1">
          <Listening history={this.props.history} />
        </TabPane>
        <TabPane tab="My Practice History" key="2">
          <SpeakingAnswers history={this.props.history} examType="listening" />
        </TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permitted: state.auth.permissions.listening,
  };
};

export default connect(mapStateToProps)(ListeningTabs);
