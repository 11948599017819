import * as task2 from '../types/task2';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateCountdownTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_COUNTDOWN_TIMER,
      payload : payload,
    });
  };
};

export const updateCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_COUNTDOWN_STARTED,
      payload : payload,
    });
  };
};

export const updatePlayStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_PLAY_STATUS,
      payload : payload,
    });
  };
};

export const updatePlayDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_PLAY_DURATION,
      payload : payload,
    });
  };
};

export const updatePlayProgress = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_PLAY_PROGRESS,
      payload : payload,
    });
  };
};

export const updatePlayBeep = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_PLAY_BEEP,
      payload : payload,
    });
  };
};

export const updateRecordingStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_RECORDING_STATUS,
      payload : payload,
    });
  };
};

export const updateRecordingTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_RECORDING_TIMER,
      payload : payload,
    });
  };
};

export const updateRecordingDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_RECORDING_DURATION,
      payload : payload,
    });
  };
};

export const updateSequence = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_RESET_SEQUENCE,
      payload : payload,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};

export const updatePrepareTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_PREPARE_TIMER,
      payload : payload,
    });
  };
};

export const updatePreparing = (payload) => {
  return dispatch => {
    dispatch({
      type    : task2.TASK2_UPDATE_PREPARING,
      payload : payload,
    });
  };
};