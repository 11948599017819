import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { connect } from "react-redux";
import * as actions from "../actions/app/profile";
import * as api from "../actions/api/requests";
import * as apiActions from "../actions/app/api";
import { Link } from "react-router-dom";

function hasWhiteSpace(s) {
  return /\s/g.test(s);
}

const passwordError = () => {
  message.error(
    "Password mismatch. Please enter the correct password for both fields."
  );
};

const whitespaceError = () => {
  message.error("Do not put spaces in the user name");
};

class RegistrationForm extends React.Component {
  componentDidMount() {
    this.props.dispatch(apiActions.updateApiSending(false));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const { dispatch, register_form } = this.props;
      if (hasWhiteSpace(register_form.username)) {
        whitespaceError();
        return;
      }

      if (values.password !== values.password2) {
        passwordError();
        return;
      }
      if (!err) {
        dispatch(apiActions.updateApiSending(true));
        dispatch(
          api.signup(register_form, () => {
            dispatch(actions.reset());
            this.props.history.push("/");
          })
        );
      }
    });
  };
  handleInputChange = (e) => {
    const { register_form } = this.props;
    register_form[e.target.name] = e.target.value;
    this.props.dispatch(actions.updateProfile(register_form));
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { register_form } = this.props;
    return (
      <Row justify="center">
        <Col span={24} className="text-center padding-top-50">
          <Form onSubmit={this.handleSubmit} className="login-form login">
            <Form.Item className="text-center">
              <h2>PTE University Signup</h2>
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("username", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your username!",
                  },
                ],
                initialValue: register_form.username,
              })(
                <Input
                  name="username"
                  onChange={this.handleInputChange}
                  placeholder="Username"
                />
              )}
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your first name!",
                  },
                ],
                initialValue: register_form.first_name,
              })(
                <Input
                  name="first_name"
                  onChange={this.handleInputChange}
                  placeholder="First Name"
                />
              )}
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your last name!",
                  },
                ],
                initialValue: register_form.last_name,
              })(
                <Input
                  name="last_name"
                  onChange={this.handleInputChange}
                  placeholder="Last Name"
                />
              )}
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your email!",
                  },
                ],
                initialValue: register_form.email,
              })(
                <Input
                  name="email"
                  type="email"
                  onChange={this.handleInputChange}
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your password!",
                  },
                ],
                initialValue: register_form.password,
              })(
                <Input
                  name="password"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("password2", {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your password confirmation!",
                  },
                ],
                initialValue: register_form.password2,
              })(
                <Input
                  name="password2"
                  onChange={this.handleInputChange}
                  type="password"
                  placeholder="Confirm password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                disabled={this.props.sending}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Register
              </Button>
              Or <Link to="/">go back to login!</Link>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_registration" })(
  RegistrationForm
);

const mapStateToProps = (state) => {
  return {
    register_form: state.profile,
    sending: state.api.sending,
  };
};

export default connect(mapStateToProps)(WrappedNormalLoginForm);
