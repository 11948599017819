export const TASK2_UPDATE_STARTED = "TASK2_UPDATE_STARTED";
export const TASK2_UPDATE_COUNTDOWN_TIMER = "TASK2_UPDATE_COUNTDOWN_TIMER";
export const TASK2_UPDATE_COUNTDOWN_STARTED = "TASK2_UPDATE_COUNTDOWN_STARTED";
export const TASK2_UPDATE_PLAY_STATUS = "TASK2_UPDATE_PLAY_STATUS";
export const TASK2_UPDATE_PLAY_DURATION = "TASK2_UPDATE_PLAY_DURATION";
export const TASK2_UPDATE_PLAY_PROGRESS = "TASK2_UPDATE_PLAY_PROGRESS";
export const TASK2_UPDATE_PLAY_BEEP = "TASK2_UPDATE_PLAY_BEEP";
export const TASK2_UPDATE_RECORDING_STATUS = "TASK2_UPDATE_RECORDING_STATUS";
export const TASK2_UPDATE_RECORDING_TIMER = "TASK2_UPDATE_RECORDING_TIMER";
export const TASK2_UPDATE_RECORDING_DURATION = "TASK2_UPDATE_RECORDING_DURATION";
export const TASK2_RESET_SEQUENCE = "TASK2_RESET_SEQUENCE";
export const TASK2_UPDATE_EXAM_NUMBER = "TASK2_UPDATE_EXAM_NUMBER";
export const TASK2_UPDATE_PREPARE_TIMER = "TASK2_UPDATE_PREPARE_TIMER";
export const TASK2_UPDATE_PREPARING = "TASK2_UPDATE_PREPARING";