import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { Route } from "react-router-dom";

import Speaking from "./SpeakingTabs";
import Reading from "./ReadingTabs";
import Listening from "./ListeningTabs";
import Writing from "./WritingTabs";
import MicTest from "./MicTest";

const { Header, Content, Footer } = Layout;

class Dashboard extends React.Component {
  doNotCopy(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }
  render() {
    const { match } = this.props;
    return (
      <Layout
        className="layout"
        onCopy={this.doNotCopy.bind(this)}
        onCut={this.doNotCopy.bind(this)}
        onPaste={this.doNotCopy.bind(this)}
      >
        <Header className="header-volcano">
          <Link to="/dashboard/speaking">
            <div className="logo-left">
              <h2>PTE University</h2>
            </div>
          </Link>
          <Link to="/logout">
            <div className="logo-right">
              <h3>Logout</h3>
            </div>
          </Link>
        </Header>
        <Layout>
          <Sidebar location={this.props.location} />
          <Content className="content-container">
            <div style={{ background: "#fff", padding: 24, minHeight: 280 }}>
              <Route path={`${match.path}/speaking`} component={Speaking} />
              <Route path={`${match.path}/reading`} component={Reading} />
              <Route path={`${match.path}/listening`} component={Listening} />
              <Route path={`${match.path}/writing`} component={Writing} />
              <Route path={`${match.path}/mic`} component={MicTest} />
            </div>
          </Content>
        </Layout>
        <Footer className="text-center">
          PTE University ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Dashboard);
