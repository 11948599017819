import React from 'react';
import ReactPlayer from 'react-player'

export const Beep = (props) => {
  return (
    <ReactPlayer
      className="invisible"
      url={props.beepUrl}
      onEnded={props.onEnded}
      playing={props.play_beep}
    />
  );
}