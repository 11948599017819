import React from "react";
import { Layout, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import * as Task from "./tasks";
import { connect } from "react-redux";

const { Header, Content, Footer } = Layout;

class Main extends React.Component {
  doNotCopy(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }
  render() {
    return (
      <Spin spinning={this.props.lock}>
        <Layout
          onCopy={this.doNotCopy.bind(this)}
          onCut={this.doNotCopy.bind(this)}
          onPaste={this.doNotCopy.bind(this)}
          className="layout"
        >
          <Header className="header-volcano">
            <Link to="/dashboard/speaking">
              <div className="logo-left">
                <h2>PTE University</h2>
              </div>
            </Link>
          </Header>
          <Layout>
            <Content className="content-container">
              <Col span={4}>
                <Task.LearningStratNav history={this.props.history} />
              </Col>
              <Col span={20}>
                <Task.Container history={this.props.history} />
              </Col>
            </Content>
          </Layout>
          <Footer className="text-center">
            PTE University ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lock: state.app.lock,
  };
};

export default connect(mapStateToProps)(Main);
