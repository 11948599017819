import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';

class Instruction extends React.Component {
  render(){
    const { task, currentContent } = this.props;
    return (
      <Row className="margin-top-15">
        <Col span={24}>
          <p className="instruction" dangerouslySetInnerHTML={{__html: task[currentContent].instruction}}>
          </p>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent : state.app.currentContent,
    task           : state.app.examContent,
  }
};

export default connect(mapStateToProps)(Instruction);