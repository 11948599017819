import React from "react";
import { Row, Col, Checkbox, Progress, Button, Icon, Collapse } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/app/task14";
import * as appActions from "../../actions/app/app";
import * as EXAM_TYPES from "../../constants/exams";
import ReactPlayer from "react-player";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as strategyActions from "../../actions/app/strategy";
import * as stratActions from "../../actions/app/strategy";
const Panel = Collapse.Panel;

function format(seconds) {
  if (seconds > 0) {
    seconds = Math.round(seconds + 1);
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

export const Player = (props) => {
  return (
    <Row className="text-center padding-10">
      <Row className="graybox text-center">
        <Col span={24} className="margin-top-10">
          <p className="countdown">
            {props.strat_mode_on
              ? "Audio finished playing"
              : props.countdown_started
              ? "Beginning in " + props.timer
              : props.play_ended
              ? "Audio finished playing"
              : props.play_status
              ? "Playing"
              : null}
          </p>
        </Col>
        <Col span={24} className="margin-top-10">
          <p>
            <Progress
              className="width-25"
              percent={
                props.strat_mode_on
                  ? 100
                  : (props.progress / props.duration) * 100
              }
              showInfo={false}
              strokeColor={"#0081A4"}
            />
            {props.strat_mode_on ? (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{format(props.duration)} /{" "}
                {format(props.duration)}
              </span>
            ) : (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{format(props.progress)} /{" "}
                {format(props.duration)}
              </span>
            )}
          </p>
        </Col>
      </Row>
    </Row>
  );
};

class ReadingCheckbox extends React.Component {
  componentDidMount() {
    const {
      task,
      currentContent,
      dispatch,
      started,
      countdown_started,
      play_status,
      progress,
      strategy_started,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(false));
    if (!strat_mode_on) {
      if (!started) {
        dispatch(actions.updateStarted(true));
        dispatch(actions.updateCountdownStarted(true));
        dispatch(actions.updateTimer(7));
        dispatch(
          actions.updateCountdownTimer(task[currentContent].countdown_timer)
        );
        this.startTimer();
      } else if (countdown_started) {
        this.startTimer();
      } else if (play_status) {
        this.player.seekTo(progress);
      }
      if (strategy_started) {
        this.startTimer2();
      }
    }
  }
  componentWillUnmount() {
    this.props.dispatch(strategyActions.reset());
    this.clearTimer();
    this.clearTimer2();
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick2() {
    const { dispatch, listening_timer, max_listening_timer_2 } = this.props;
    const new_timer = listening_timer + 1;
    if (new_timer <= max_listening_timer_2) {
      dispatch(strategyActions.updateListeningTimer(new_timer));
    } else {
      // TODO: handle end of exam
      this.clearTimer2();
      this.nextTask();
    }
  }
  startTimer2() {
    this.timer2 = setInterval(this.tick2.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(actions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      dispatch(actions.updateCountdownStarted(false));
      dispatch(actions.updatePlayStatus(true));
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer2() {
    clearInterval(this.timer2);
    this.timer2 = null;
  }
  onChange = (e) => {
    this.props.dispatch(actions.updateCurrentAnswer(e.target.value));
  };
  onDuration = (duration) => {
    const { dispatch } = this.props;
    dispatch(actions.updateDuration(duration));
  };
  nextTask = () => {
    this.clearTimer();
    this.clearTimer2();
    const {
      dispatch,
      task,
      currentContent,
      current_answer,
      answerInstance,
      current_answer2,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      this.updateAnswer(current_answer, current_answer2);
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "listening"
            )
          );
        }, 1500);
      } else {
        dispatch(actions.reset());
        if (task[newCurrentTask].exam_type === EXAM_TYPES.TASK14) {
          dispatch(actions.updateStarted(true));
          dispatch(actions.updateCountdownStarted(true));
          dispatch(actions.updateTimer(7));
          dispatch(
            actions.updateCountdownTimer(task[newCurrentTask].countdown_timer)
          );
          this.startTimer();
          dispatch(strategyActions.reset());
        }
        dispatch(strategyActions.updateLearningStratStarted(false));
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(stratActions.updateLearningStratModeOn(false));
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  };
  onProgress = (data) => {
    const playedSeconds = data.playedSeconds;
    const { dispatch } = this.props;
    dispatch(actions.updatePlayProgress(playedSeconds));
  };
  ref = (player) => {
    this.player = player;
  };
  onEnded = (e) => {
    this.props.dispatch(actions.updatePlayProgress(this.props.duration));
    this.props.dispatch(actions.updatePlayEnded(true));
    this.props.dispatch(actions.updatePlayStatus(false));
    this.props.dispatch(strategyActions.updateLearningStratStarted(true));
    this.startTimer2();
  };
  handleClick = (e) => {
    const { dispatch, current_answer } = this.props;
    const checks = current_answer.slice();
    const choice = parseInt(e.target.value, 10);
    if (e.target.checked) {
      checks.push(choice);
    } else {
      const index = checks.indexOf(choice);
      if (index !== -1) checks.splice(index, 1);
    }
    dispatch(actions.updateCurrentAnswer(checks));
  };
  handleClick2 = (e) => {
    const { dispatch, current_answer2 } = this.props;
    const checks = current_answer2.slice();
    const choice = parseInt(e.target.value, 10);
    if (e.target.checked) {
      checks.push(choice);
    } else {
      const index = checks.indexOf(choice);
      if (index !== -1) checks.splice(index, 1);
    }
    dispatch(actions.updateCurrentAnswer2(checks));
  };
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(
      api.updateAnswerInstance(newAnswerInstance, "listening", "listening")
    );
  }
  render() {
    const {
      task,
      currentContent,
      current_answer,
      play_status,
      current_answer2,
      strat_mode_on,
    } = this.props;
    const answers = task[currentContent].answers;
    const question = task[currentContent].question;
    const choices = answers.map((answer, index) => {
      const answerValue = index + 1;
      return (
        <Col
          className={
            current_answer.includes(answerValue) ? "selected-gold" : ""
          }
          onClick={this.handleClick}
          span={24}
        >
          <Checkbox
            disabled={strat_mode_on}
            checked={current_answer.includes(answerValue)}
            value={answerValue}
          >
            {answer}
          </Checkbox>
        </Col>
      );
    });
    const choices2 = answers.map((answer, index) => {
      const answerValue = index + 1;
      return (
        <Col
          className={
            current_answer2.includes(answerValue) ? "selected-gold" : ""
          }
          onClick={this.handleClick2}
          span={24}
        >
          <Checkbox
            checked={current_answer2.includes(answerValue)}
            value={answerValue}
          >
            {answer}
          </Checkbox>
        </Col>
      );
    });
    return (
      <div>
        <Row className="text-center margin-top-15">
          <Player {...this.props} audio_url={task[currentContent].audio_url} />
          <Col span={24} className="padding-15 text-left">
            <p>{question}</p>
            <Row>{choices}</Row>
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Play Recording Again" key="2">
                    <Row>
                      <Col span={8} className="text-center">
                        <audio
                          ref="audioSource"
                          controls="controls"
                          src={task[currentContent]["audio_url"]}
                        ></audio>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="1">
                    <Row>{choices2}</Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              onClick={this.nextTask.bind(this)}
              className="next-btn"
              disabled={this.props.sending}
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
        {play_status ? (
          <ReactPlayer
            className="invisible"
            ref={this.ref}
            url={task[currentContent]["audio_url"]}
            playing={play_status && !strat_mode_on}
            onDuration={this.onDuration.bind(this)}
            onProgress={this.onProgress.bind(this)}
            onEnded={this.onEnded.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    started: state.task14.task14_started,
    timer: state.task14.timer,
    answer: state.task14.answer,
    duration: state.task14.duration,
    countdown_started: state.task14.countdown_started,
    countdown_timer: state.task14.countdown_timer,
    play_status: state.task14.play_status,
    progress: state.task14.progress,
    play_ended: state.task14.play_ended,
    current_answer: state.task14.current_answer,
    user: state.profile,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    listening_timer: state.strategy.listening_timer,
    max_listening_timer_2: state.strategy.max_listening_timer_2,
    strategy_started: state.strategy.started,
    current_answer2: state.task14.current_answer2,
    strat_mode_on: state.strategy.strat_mode_on,
  };
};

export default connect(mapStateToProps)(ReadingCheckbox);
