import * as actions from '../actions/types/welcome2';

const defaultState = {
  timer:60,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.WELCOME2_UPDATE_TIMER: {
      return {...state, timer:action.payload};
    }
    default: return {...state};
  }
};