import { combineReducers } from 'redux';

import answerInstance from './answerInstance';
import api from './api';
import app from './app';
import auth from './auth';
import listeningReview from './listeningReview';
import login from './login';
import profile from './profile';
import readingReview from './readingReview';
import sidebar from './sidebar';
import speakingReview from './speakingReview';
import strategy from './strategy';
import task0 from './task0';
import task1 from './task1';
import task2 from './task2';
import task3 from './task3';
import task4 from './task4';
import task5 from './task5';
import task6 from './task6';
import task7 from './task7';
import task8 from './task8';
import task9 from './task9';
import task10 from './task10';
import task11 from './task11';
import task12 from './task12';
import task13 from './task13';
import task14 from './task14';
import task15 from './task15';
import task16 from './task16';
import task17 from './task17';
import task18 from './task18';
import task19 from './task19';
import task20 from './task20';
import tooling from './tooling';
import welcome2 from './welcome2';
import welcome3 from './welcome3';

export default combineReducers({
  answerInstance,
  api,
  app,
  auth,
  listeningReview,
  login,
  profile,
  readingReview,
  sidebar,
  speakingReview,
  strategy,
  task0,
  task1,
  task2,
  task3,
  task4,
  task5,
  task6,
  task7,
  task8,
  task9,
  task10,
  task11,
  task12,
  task13,
  task14,
  task15,
  task16,
  task17,
  task18,
  task19,
  task20,
  tooling,
  welcome2,
  welcome3,
});
