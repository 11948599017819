import * as actions from '../actions/types/task18';

const defaultState = {
  current_index:0,
  current_answer:-1,
  current_answer2:-1,
  answers: [],
  task18_started:false,
  duration : 0,
  timer: 0,
  answer: "",
  countdown_started: false,
  countdown_timer: 0,
  max_countdown_timer: 20,
  play_status: false,
  play_ended: false,
  progress: 0,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK18_UPDATE_CURRENT_INDEX: {
      return {...state, current_index:action.payload};
    }
    case actions.TASK18_UPDATE_CURRENT_ANSWER: {
      return {...state, current_answer:action.payload};
    }
    case actions.TASK18_UPDATE_CURRENT_ANSWER2: {
      return {...state, current_answer2:action.payload};
    }
    case actions.TASK18_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK18_UPDATE_STARTED: {
      return {...state, task18_started:action.payload};
    }
    case actions.TASK18_DURATION: {
      return {...state, duration:action.payload};
    }
    case actions.TASK18_TIMER: {
      return {...state, timer:action.payload};
    }
    case actions.TASK18_UPDATE_ANSWER: {
      return {...state, answer:action.payload};
    }
    case actions.TASK18_UPDATE_COUNTDOWN_STARTED: {
      return {...state, countdown_started:action.payload};
    }
    case actions.TASK18_UPDATE_COUNTDOWN_TIMER: {
      return {...state, countdown_timer:action.payload};
    }
    case actions.TASK18_UPDATE_PLAY_STATUS: {
      return {...state, play_status:action.payload};
    }
    case actions.TASK18_UPDATE_PLAY_PROGRESS: {
      return {...state, progress:action.payload};
    }
    case actions.TASK18_UPDATE_PLAY_ENDED: {
      return {...state, play_ended:action.payload};
    }
    case actions.TASK18_RESET: {
      return {
        ...defaultState,
      };
    }
    default: return {...state};
  }
};