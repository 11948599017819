import * as app from '../types/app';

export const updateStartExam = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_START_EXAM,
      payload : payload,
    });
  };
};

export const updateRecordCountdown = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_RECORD_COUNTDOWN,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_CURRENT_PAGE,
      payload : payload,
    });
  };
};

export const updateCurrentContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_CURRENT_CONTENT,
      payload : payload,
    });
  };
};

export const updateRecordingStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_RECORDING_STATUS,
      payload : payload,
    });
  };
};

export const updateReadingStatus = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_READING_STATUS,
      payload : payload,
    });
  };
};

export const updateReadingCountDown = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_READING_COUNTDOWN,
      payload : payload,
    });
  };
};

export const updateFinishedReading = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_FINISHED_READING,
      payload : payload,
    });
  };
};

export const updateBeep = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_BEEP,
      payload : payload,
    });
  };
};

export const updateReadingCountdownStatus  = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_READING_COUNTDOWN_STATUS,
      payload : payload,
    });
  };
};

export const resetAudioRecorder = () => {
  return dispatch => {
    dispatch({
      type    : app.RESET_AUDIO_RECORDER,
    });
  };
};

export const updateMainTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.APP_UPDATE_MAIN_TIMER,
      payload : payload,
    });
  };
};

export const updateExamTime = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.APP_UPDATE_EXAM_TIME,
      payload : payload,
    });
  };
};

export const updateExamContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.APP_UPDATE_EXAM_CONTENT,
      payload : payload,
    });
  };
};

export const appReset = () => {
  return dispatch => {
    dispatch({
      type    : app.APP_RESET,
    });
  };
};

export const updateAppLoading = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_APP_LOADING,
      payload : payload,
    });
  };
};
