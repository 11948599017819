import * as actions from '../actions/types/task1';

const defaultState = {
  exam_number: 1,
  task1_started:false,
  start_recording:false,
  recording:false,
  reading:false,
  reading_countdown:0,
  finished_reading:false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK1_UPDATE_EXAM_NUMBER:{
      return {...state, exam_number:action.payload};
    }
    case actions.TASK1_RESET: {
      return {
        ...state,
        task1_started:false,
        start_recording:false,
        recording:false,
        reading:false,
        reading_countdown:0,
        finished_reading:false,
      };
    }
    case actions.TASK1_UPDATE_START_RECORDING: {
      return {...state, start_recording:action.payload};
    }
    case actions.TASK1_UPDATE_TASK1_STARTED: {
      return {...state, task1_started:action.payload};
    }
    case actions.TASK1_UPDATE_RECORDING: {
      return {...state, recording:action.payload};
    }
    case actions.TASK1_UPDATE_READING: {
      return {...state, reading:action.payload};
    }
    case actions.TASK1_UPDATE_RECORDING_COUNTDOWN: {
      return {...state, reading_countdown:action.payload};
    }
    case actions.TASK1_UPDATE_FINISHED_READING: {
      return {...state, finished_reading:action.payload};
    }
    default: return {...state};
  }
};