import React from "react";
import { Row, Col, Radio, Progress, Button, Icon, Collapse } from "antd";
import { connect } from "react-redux";
import * as actions from "../../actions/app/task16";
import * as appActions from "../../actions/app/app";
import * as EXAM_TYPES from "../../constants/exams";
import ReactPlayer from "react-player";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as strategyActions from "../../actions/app/strategy";
import * as stratActions from "../../actions/app/strategy";
const Panel = Collapse.Panel;

const RadioGroup = Radio.Group;

function format(seconds) {
  if (seconds > 0) {
    seconds = Math.round(seconds + 1);
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

export const Player = (props) => {
  return (
    <Row className="text-center padding-10">
      <Row className="graybox text-center">
        <Col span={24} className="margin-top-10">
          <p className="countdown">
            {props.strat_mode_on
              ? "Audio finished playing"
              : props.countdown_started
              ? "Beginning in " + props.timer
              : props.play_ended
              ? "Audio finished playing"
              : props.play_status
              ? "Playing"
              : null}
          </p>
        </Col>
        <Col span={24} className="margin-top-10">
          <p>
            <Progress
              className="width-25"
              percent={
                props.strat_mode_on
                  ? 100
                  : (props.progress / props.duration) * 100
              }
              showInfo={false}
              strokeColor={"#0081A4"}
            />
            {props.strat_mode_on || props.play_ended ? (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{format(props.duration)} /{" "}
                {format(props.duration)}
              </span>
            ) : (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;{format(props.progress)} /{" "}
                {format(props.duration)}
              </span>
            )}
          </p>
        </Col>
      </Row>
    </Row>
  );
};

class HighlightCorrectSummary extends React.Component {
  componentDidMount() {
    const {
      task,
      currentContent,
      dispatch,
      started,
      countdown_started,
      play_status,
      progress,
      strategy_started,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(false));
    if (!strat_mode_on) {
      if (!started) {
        dispatch(actions.updateStarted(true));
        dispatch(actions.updateCountdownStarted(true));
        dispatch(actions.updateTimer(10));
        dispatch(
          actions.updateCountdownTimer(task[currentContent].countdown_timer)
        );
        this.startTimer();
      } else if (countdown_started) {
        this.startTimer();
      } else if (play_status) {
        this.player.seekTo(progress);
      }
      if (strategy_started) {
        this.startTimer2();
      }
    }
  }
  componentWillUnmount() {
    this.props.dispatch(strategyActions.reset());
    this.clearTimer();
    this.clearTimer2();
  }
  tick2() {
    const { dispatch, listening_timer, max_listening_timer_2 } = this.props;
    const new_timer = listening_timer + 1;
    if (new_timer <= max_listening_timer_2) {
      dispatch(strategyActions.updateListeningTimer(new_timer));
    } else {
      // TODO: handle end of exam
      this.clearTimer2();
      this.nextTask();
    }
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  startTimer2() {
    this.timer2 = setInterval(this.tick2.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(actions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      dispatch(actions.updateCountdownStarted(false));
      dispatch(actions.updatePlayStatus(true));
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer2() {
    clearInterval(this.timer2);
    this.timer2 = null;
  }
  onChange = (e) => {
    this.props.dispatch(actions.updateCurrentAnswer(e.target.value));
  };
  onChange2 = (e) => {
    this.props.dispatch(actions.updateCurrentAnswer2(e.target.value));
  };
  onDuration = (duration) => {
    const { dispatch } = this.props;
    dispatch(actions.updateDuration(duration));
  };
  nextTask = () => {
    this.clearTimer();
    this.clearTimer2();
    const {
      dispatch,
      task,
      currentContent,
      current_answer,
      answerInstance,
      current_answer2,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      this.updateAnswer(current_answer, current_answer2);
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "listening"
            )
          );
        }, 1500);
      } else {
        if (task[newCurrentTask].exam_type === EXAM_TYPES.TASK16) {
          dispatch(actions.reset());
          dispatch(actions.updateStarted(true));
          dispatch(actions.updateCountdownStarted(true));
          dispatch(actions.updateTimer(10));
          dispatch(
            actions.updateCountdownTimer(task[newCurrentTask].countdown_timer)
          );
          dispatch(strategyActions.reset());
          this.startTimer();
        }
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(strategyActions.updateLearningStratStarted(false));
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  };
  onProgress = (data) => {
    const playedSeconds = data.playedSeconds;
    const { dispatch } = this.props;
    dispatch(actions.updatePlayProgress(playedSeconds));
  };
  ref = (player) => {
    this.player = player;
  };
  onEnded = (e) => {
    this.props.dispatch(actions.updatePlayProgress(this.props.duration));
    this.props.dispatch(actions.updatePlayEnded(true));
    this.props.dispatch(actions.updatePlayStatus(false));
    this.props.dispatch(strategyActions.updateLearningStratStarted(true));
    this.startTimer2();
  };
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "listening"));
  }
  render() {
    const radioStyle = {
      height: "30px",
      lineHeight: "20px",
      paddingTop: "16px",
    };
    const {
      task,
      currentContent,
      current_answer,
      play_status,
      strat_mode_on,
      current_answer2,
    } = this.props;
    const answers = task[currentContent].answers;
    const question = task[currentContent].question;
    const choices = [];
    const choices2 = [];
    // eslint-disable-next-line
    answers.map((answer, index) => {
      if (index === 0) {
        choices.push(
          <Radio
            className={
              current_answer === index + 1
                ? "wrap-label no-padding-top selected"
                : "wrap-label no-padding-top"
            }
            style={radioStyle}
            value={index + 1}
          >
            {answer}
          </Radio>
        );
        choices2.push(
          <Radio
            className={
              current_answer2 === index + 1
                ? "wrap-label no-padding-top selected"
                : "wrap-label no-padding-top"
            }
            style={radioStyle}
            value={index + 1}
          >
            {answer}
          </Radio>
        );
      } else {
        choices.push(
          <Radio
            className={
              current_answer === index + 1
                ? "wrap-label selected"
                : "wrap-label"
            }
            style={radioStyle}
            value={index + 1}
          >
            {answer}
          </Radio>
        );
        choices2.push(
          <Radio
            className={
              current_answer2 === index + 1
                ? "wrap-label selected"
                : "wrap-label"
            }
            style={radioStyle}
            value={index + 1}
          >
            {answer}
          </Radio>
        );
      }
      choices.push(<br />);
    });
    return (
      <div>
        <Row className="margin-top-16">
          <Col span={24}>
            <p className="content text-left">
              <Player
                {...this.props}
                audio_url={task[currentContent].audio_url}
              />
            </p>
          </Col>
          <Col span={24} className="padding-16 text-left">
            <p>{question}</p>
            <RadioGroup
              disabled={strat_mode_on}
              onChange={this.onChange}
              value={current_answer}
            >
              {choices}
            </RadioGroup>
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-160">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Play Recording Again" key="2">
                    <Row>
                      <Col span={8} className="text-center">
                        <audio
                          ref="audioSource"
                          controls="controls"
                          src={task[currentContent]["audio_url"]}
                        ></audio>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="2">
                    <Row>
                      <RadioGroup
                        onChange={this.onChange2}
                        value={current_answer2}
                      >
                        {choices2}
                      </RadioGroup>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              onClick={this.nextTask.bind(this)}
              className="next-btn"
              disabled={this.props.sending}
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
        {play_status ? (
          <ReactPlayer
            className="invisible"
            ref={this.ref}
            url={task[currentContent]["audio_url"]}
            playing={play_status && !strat_mode_on}
            onDuration={this.onDuration.bind(this)}
            onProgress={this.onProgress.bind(this)}
            onEnded={this.onEnded.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    started: state.task16.task16_started,
    timer: state.task16.timer,
    answer: state.task16.answer,
    duration: state.task16.duration,
    countdown_started: state.task16.countdown_started,
    countdown_timer: state.task16.countdown_timer,
    play_status: state.task16.play_status,
    progress: state.task16.progress,
    play_ended: state.task16.play_ended,
    current_answer: state.task16.current_answer,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    listening_timer: state.strategy.listening_timer,
    max_listening_timer_2: state.strategy.max_listening_timer_2,
    strategy_started: state.strategy.started,
    strat_mode_on: state.strategy.strat_mode_on,
    current_answer2: state.task16.current_answer2,
  };
};

export default connect(mapStateToProps)(HighlightCorrectSummary);
