import React from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';

class Progress extends React.Component{
  render(){
    const { currentContent, task } = this.props;
    let progress = ((currentContent) / task.length * 100);
    const remaining = (100-progress).toString() + "%";
    progress = progress + "%";
    return (
      <Row>
        <div className="line">
          <span style={{width:progress}} className="span-line color-1"/>
          <span style={{width:remaining}} className="span-line color-2"/>
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent : state.app.currentContent,
    task           : state.app.examContent,
  }
};

export default connect(mapStateToProps)(Progress);