import * as actions from '../actions/types/login';

const defaultState = {
  username:"",
  password:"",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_LOGIN_FORM: {
      return {...action.payload};
    }
    case actions.RESET_LOGIN_FORM: {
      return {...defaultState};
    }
    default: return {...state};
  }
};