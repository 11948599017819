export const TASK5_UPDATE_STARTED = "TASK5_UPDATE_STARTED";
export const TASK5_UPDATE_COUNTDOWN_TIMER = "TASK5_UPDATE_COUNTDOWN_TIMER";
export const TASK5_UPDATE_COUNTDOWN_STARTED = "TASK5_UPDATE_COUNTDOWN_STARTED";
export const TASK5_UPDATE_PLAY_STATUS = "TASK5_UPDATE_PLAY_STATUS";
export const TASK5_UPDATE_PLAY_DURATION = "TASK5_UPDATE_PLAY_DURATION";
export const TASK5_UPDATE_PLAY_PROGRESS = "TASK5_UPDATE_PLAY_PROGRESS";
export const TASK5_UPDATE_PLAY_BEEP = "TASK5_UPDATE_PLAY_BEEP";
export const TASK5_UPDATE_RECORDING_STATUS = "TASK5_UPDATE_RECORDING_STATUS";
export const TASK5_UPDATE_RECORDING_TIMER = "TASK5_UPDATE_RECORDING_TIMER";
export const TASK5_UPDATE_RECORDING_DURATION = "TASK5_UPDATE_RECORDING_DURATION";
export const TASK5_RESET_SEQUENCE = "TASK5_RESET_SEQUENCE";
// export const TASK5_UPDATE_PLAY_STATUS = "TASK5_UPDATE_PLAY_STATUS";
// export const TASK5_UPDATE_PLAY_DURATION = "TASK5_UPDATE_PLAY_DURATION";
// export const TASK5_UPDATE_PLAY_PROGRESS = "TASK5_UPDATE_PLAY_PROGRESS";
export const TASK5_UPDATE_EXAM_NUMBER = "TASK5_UPDATE_EXAM_NUMBER";
export const TASK5_UPDATE_BEGIN_TIMER = "TASK5_UPDATE_BEGIN_TIMER";
export const TASK5_UPDATE_RECORDING_COMPLETE = "TASK5_UPDATE_RECORDING_COMPLETE";
export const TASK5_UPDATE_PREPARE_TIMER = "TASK5_UPDATE_PREPARE_TIMER";
export const TASK5_UPDATE_PREPARING = "TASK5_UPDATE_PREPARING";