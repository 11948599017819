import * as actions from '../actions/types/task7';

const defaultState = {
  task7_started:false,
  duration : 0,
  timer: 0,
  answer: "",
  answer2: "",
  exam_number:1,
  clip_board:"",
  clip_board2:"",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK7_UPDATE_STARTED: {
      return {...state, task7_started:action.payload};
    }
    case actions.TASK7_DURATION: {
      return {...state, duration:action.payload};
    }
    case actions.TASK7_UPDATE_EXAM_NUMBER: {
      return {...state, exam_number:action.payload};
    }
    case actions.TASK7_TIMER: {
      return {...state, timer:action.payload};
    }
    case actions.TASK7_UPDATE_ANSWER: {
      return {...state, answer:action.payload};
    }
    case actions.TASK7_UPDATE_ANSWER2: {
      return {...state, answer2:action.payload};
    }
    case actions.TASK7_UPDATE_CLIPBOARD: {
      return {...state, clip_board:action.payload};
    }
    case actions.TASK7_UPDATE_CLIPBOARD2: {
      return {...state, clip_board2:action.payload};
    }
    case actions.TASK7_RESET: {
      return {
        ...state,
        duration : 0,
        timer: 0,
        answer: "",
        clip_board:"",
        answer2: "",
        clip_board2:"",
      };
    }
    default: return {...state};
  }
};