import React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';

class LearningStratNav extends React.Component{
  render(){
    const { task, currentContent, strat_mode_on } = this.props;
    const menu_items = [];
    task.forEach((item) => {
      menu_items.push(
        <Menu.Item key={item.code + "T"}>
          {item.code + "T"}
        </Menu.Item>
      );
      menu_items.push(
        <Menu.Item key={item.code+"L"}>
          {item.code + "L"}
        </Menu.Item>
      );
    })
    let selectedKey = task[currentContent].code;
    if (strat_mode_on){
      selectedKey += "L";
    } else {
      selectedKey += "T";
    }

    return (
      <div className="learning-strat-nav">
        <Menu className="no-border-color" selectedKeys={[selectedKey]}>
          {menu_items}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent : state.app.currentContent,
    currentPage    : state.app.currentPage,
    task           : state.app.examContent,
    strat_mode_on  : state.strategy.strat_mode_on,
  }
};

export default connect(mapStateToProps)(LearningStratNav);