import * as task6 from '../types/task6';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_DURATION,
      payload : payload,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_TIMER,
      payload : payload,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_UPDATE_ANSWER,
      payload : payload,
    });
  };
};

export const updateAnswer2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_UPDATE_ANSWER2,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_RESET,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};

export const updateClipboard = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_UPDATE_CLIP_BOARD,
      payload : payload,
    });
  };
};

export const updateClipboard2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task6.TASK6_UPDATE_CLIP_BOARD2,
      payload : payload,
    });
  };
};