import React from "react";
import { Row, Col, Progress, Button, Icon, Collapse, Tooltip } from "antd";
import { ReactMic } from "react-mic";
import { connect } from "react-redux";
import * as api from "../../actions/api/requests";
import * as actions from "../../actions/app/task2";
import * as appActions from "../../actions/app/app";
import * as stratActions from "../../actions/app/strategy";
import * as EXAM_TYPES from "../../constants/exams";
import ReactPlayer from "react-player";
import * as Common from "../common";
import * as apiActions from "../../actions/app/api";
import { FaMicrophone } from "react-icons/fa";
import { uploadFile } from "../../actions/api/storage";

const Panel = Collapse.Panel;

function padZero(num) {
  num = Math.round(num);
  var zero = 2 - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export const AudioRecorder = (props) => {
  let audio_percent = 0;
  if (props.play_duration !== 0) {
    audio_percent = ((props.play_progress + 1) / props.play_duration) * 100;
  }
  const recording_percent =
    (Math.floor(props.recording_timer) / Math.floor(props.recording_duration)) *
    100;
  return (
    <Row className="text-center margin-top-30 padding-10">
      {props.play_beep ? <Common.Beep {...props} /> : null}
      <Row className="graybox text-center">
        <Col span={24} className="margin-top-10">
          <p className="countdown">
            {props.strat_mode_on
              ? "Recording complete"
              : props.countdown_started
              ? "Beginning in " + props.countdown_timer
              : props.play_status
              ? "Playing"
              : props.recording_started
              ? "Recording"
              : props.play_beep
              ? "Recording in 1"
              : props.preparing
              ? "Time to prepare " + props.prepare_timer + " sec"
              : !props.recording_started || props.strat_mode_on
              ? "Recording complete"
              : ""}
          </p>
        </Col>
        <Col span={24} className="margin-top-10">
          {props.play_status ? (
            <p>
              <Progress
                className="width-25"
                percent={props.strat_mode_on ? 100 : audio_percent}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              {props.strat_mode_on ? (
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;00:{padZero(props.play_duration)} /
                  00:{padZero(props.play_duration)}
                </span>
              ) : (
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;00:
                  {props.play_duration === 0
                    ? "00"
                    : padZero(props.play_progress + 1)}{" "}
                  / 00:{padZero(props.play_duration)}
                </span>
              )}
            </p>
          ) : props.recording_started ? (
            <p>
              <Progress
                className="width-25"
                percent={props.strat_mode_on ? 100 : recording_percent}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;00:
              {props.strat_mode_on
                ? padZero(props.recording_duration)
                : padZero(props.recording_timer)}{" "}
              / 00:{padZero(props.recording_duration)}
            </p>
          ) : props.strat_mode_on ? (
            <p>
              <Progress
                className="width-25"
                percent={100}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;00:{padZero(props.recording_duration)} /
              00:{padZero(props.recording_duration)}
            </p>
          ) : (
            <p>
              <Progress
                className="width-25"
                percent={0}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;00:00 / 00:00
            </p>
          )}
        </Col>
        <Col span={24} className="text-center">
          {props.strat_mode_on ? null : (
            <ReactMic
              className="soundwave"
              record={props.recording_started}
              onStop={props.onStop}
              strokeColor={"#006480"}
              backgroundColor={"#fff"}
            />
          )}
        </Col>
      </Row>
      {props.play_status ? (
        <ReactPlayer
          className="invisible"
          url={props.task[props.currentContent]["audio_url"]}
          playing={props.play_status && !props.strat_mode_on}
          onDuration={props.onDuration}
          onEnded={props.handleEndAudio}
          onProgress={props.onProgress}
        />
      ) : null}
    </Row>
  );
};

class RepeatSentence extends React.Component {
  state = {
    recording: false,
  };
  toggleRecording(payload) {
    this.setState({ recording: payload });
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  componentDidMount() {
    const {
      dispatch,
      started,
      countdown_started,
      recording_started,
      preparing,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(false));
    this.clearTimer();
    if (!strat_mode_on) {
      if (!started) {
        dispatch(actions.updateStarted(true));
        dispatch(actions.updateCountdownStarted(true));
        this.startCountdown();
      } else if (countdown_started) {
        this.startCountdown();
      } else if (recording_started) {
        const { task, currentContent } = this.props;
        const duration = task[currentContent]["record_duration"];
        // dispatch(actions.updatePlayBeep(true));
        dispatch(actions.updateRecordingTimer(0));
        dispatch(actions.updateRecordingDuration(duration));
        this.handleBeepEnd();
      } else if (preparing) {
        this.startPreparing();
      }
    }
  }
  startCountdown() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  startPreparing() {
    this.timer = setInterval(this.tick3.bind(this), 1000);
  }
  tick() {
    const { dispatch, countdown_timer } = this.props;
    const new_timer = countdown_timer - 1;
    if (new_timer >= 0) {
      dispatch(actions.updateCountdownTimer(new_timer));
    } else {
      this.clearTimer();
      dispatch(actions.updateCountdownStarted(false));
      dispatch(actions.updatePlayStatus(true));
    }
  }
  tick3() {
    const { dispatch, prepare_timer } = this.props;
    const new_timer = prepare_timer - 1;
    if (new_timer >= 0) {
      dispatch(actions.updatePrepareTimer(new_timer));
    } else {
      this.clearTimer();
      dispatch(actions.updatePreparing(false));
      this.handleBeepEnd();
    }
  }
  updateDuration = (duration) => {
    const { dispatch } = this.props;
    dispatch(actions.updatePlayDuration(Math.ceil(duration)));
  };
  handleEndAudio = (e) => {
    const { dispatch, play_duration } = this.props;
    dispatch(actions.updatePlayProgress(play_duration));
    dispatch(actions.updatePlayStatus(false));
    // dispatch(actions.updatePlayBeep(true));
    dispatch(actions.updatePreparing(true));
    this.startPreparing();
  };
  onProgress = (data) => {
    const playedSeconds = data.playedSeconds;
    const { dispatch } = this.props;
    dispatch(actions.updatePlayProgress(playedSeconds));
  };
  handleBeepEnd = () => {
    const { dispatch, task, currentContent } = this.props;
    const duration = task[currentContent]["record_duration"];
    dispatch(actions.updateRecordingDuration(duration));
    dispatch(actions.updateRecordingStarted(true));
    this.startRecording();
  };
  startRecording() {
    this.timer = setInterval(this.tick2.bind(this), 1000);
  }
  tick2() {
    const { dispatch, recording_timer, recording_duration } = this.props;
    const new_timer = recording_timer + 1;
    if (new_timer <= recording_duration) {
      dispatch(actions.updateRecordingTimer(new_timer));
    } else {
      this.clearTimer();
      dispatch(actions.updateRecordingStarted(false));
    }
  }
  updateAnswer(url) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = url;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "speaking"));
  }
  handleEndRecord = (recordBlob) => {
    this.clearTimer();
    // this.nextTask()
    const { user } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", recordBlob.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.username;
      const audioAnswer = new File([xhr.response], "answer.webm"); //xhr.response is now a blob object
      const key =
        "student-answers/individual/repeat-sentence/" +
        userName +
        "/" +
        Date.now() +
        ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          console.log(result);
          this.updateAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  handleNextButton() {
    const { recording_started, dispatch, strat_mode_on } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      if (this.state.blobURL) {
        this.uploadSecondAnswer();
      } else {
        dispatch(apiActions.updateApiSending(false));
        this.nextTask();
      }
    } else {
      if (recording_started) {
        this.clearTimer();
        this.props.dispatch(actions.updateRecordingStarted(false));
      } else {
        this.updateAnswer("");
        this.nextTask();
      }
    }
  }
  nextTask() {
    this.clearTimer();
    const {
      dispatch,
      currentContent,
      task,
      exam_number,
      answerInstance,
      strat_mode_on,
    } = this.props;
    if (strat_mode_on) {
      this.props.dispatch(actions.updateExamNumber(exam_number + 1));
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "speaking"
            )
          );
        }, 1500);
      } else {
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(stratActions.updateLearningStratStarted(false));
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(actions.updateSequence());
        dispatch(actions.updateStarted(true));
        dispatch(actions.updateCountdownStarted(true));
        if (task[newCurrentTask].exam_type !== EXAM_TYPES.TASK2) {
          this.clearTimer();
        } else {
          this.startCountdown();
        }
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
    }
  }
  update2ndAnswer(url) {
    const { dispatch, answerInstance } = this.props;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data[newAnswerInstance.data.length - 1][
      "second_answer"
    ] = url;
    dispatch(api.updateAnswerInstance(newAnswerInstance, "speaking"));
  }
  uploadSecondAnswer = () => {
    const { user } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", this.state.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.username;
      const audioAnswer = new File([xhr.response], "answer.webm"); //xhr.response is now a blob object
      const key =
        "student-answers/individual/repeat-sentence/" +
        userName +
        "/" +
        Date.now() +
        ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          console.log(result);
          this.update2ndAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  onRecordingStop = (blobObject) => {
    this.setState({ blobURL: blobObject.blobURL });
  };

  render() {
    const { task, currentContent, strat_mode_on } = this.props;
    const { recording } = this.state;
    return (
      <div>
        <AudioRecorder
          {...this.props}
          onDuration={this.updateDuration.bind(this)}
          handleEndAudio={this.handleEndAudio.bind(this)}
          onProgress={this.onProgress.bind(this)}
          onEnded={this.handleBeepEnd.bind(this)}
          onStop={this.handleEndRecord.bind(this)}
        />
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Play Recording Again" key="2">
                    <Row>
                      <Col span={8} className="text-center">
                        <audio
                          ref="audioSource"
                          controls="controls"
                          src={task[currentContent]["audio_url"]}
                        ></audio>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Record 2nd Answer" key="3">
                    <Row>
                      <Col span={8}>
                        <Row className="text-center">
                          <audio
                            ref="audioSource"
                            controls="controls"
                            src={this.state.blobURL}
                          ></audio>
                        </Row>
                        <Row className="text-center">
                          <ReactMic
                            className="soundwave"
                            record={recording}
                            backgroundColor="#1890ff"
                            audioBitsPerSecond={128000}
                            onStop={this.onRecordingStop.bind(this)}
                            strokeColor="#fff"
                            style={{ width: "80%" }}
                          />
                        </Row>
                        <Row className="text-center">
                          <Tooltip title="Start recording">
                            <Button
                              onClick={this.toggleRecording.bind(this, true)}
                              disabled={recording}
                              type="primary"
                              shape="circle"
                              size="large"
                            >
                              <FaMicrophone />
                            </Button>
                          </Tooltip>
                          &nbsp;&nbsp;
                          <Tooltip title="Stop recording">
                            <Button
                              onClick={this.toggleRecording.bind(this, false)}
                              disabled={!recording}
                              type="primary"
                              shape="circle"
                              icon="pause"
                              size="large"
                            />
                          </Tooltip>
                        </Row>
                      </Col>
                      `
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending || this.state.recording}
              onClick={this.handleNextButton.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    started: state.task2.task2_started,
    countdown_timer: state.task2.countdown_timer,
    countdown_started: state.task2.countdown_started,
    task: state.app.examContent,
    currentContent: state.app.currentContent,
    play_status: state.task2.play_status,
    play_duration: state.task2.play_duration,
    play_progress: state.task2.play_progress,
    play_beep: state.task2.play_beep,
    recording_started: state.task2.recording_started,
    recording_timer: state.task2.recording_timer,
    recording_duration: state.task2.recording_duration,
    exam_number: state.task2.exam_number,
    user: state.auth,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    prepare_timer: state.task2.prepare_timer,
    preparing: state.task2.preparing,
    strat_mode_on: state.strategy.strat_mode_on,
    instruction: state.strategy.settings.instruction,
    vocabulary: state.strategy.settings.vocabulary,
    beepUrl: state.api.beepUrl,
  };
};

export default connect(mapStateToProps)(RepeatSentence);
