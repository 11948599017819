import React from "react";
import { Form, Icon, Input, Button, Row, Col, message } from "antd";
import { connect } from "react-redux";
import * as actions from "../actions/app/login";
import * as api from "../actions/api/requests";
import * as apiActions from "../actions/app/api";
import { Link } from "react-router-dom";

const serverError = (error) => {
  message.error(error);
};

class NormalLoginForm extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.reset());
    this.props.dispatch(apiActions.updateApiSending(false));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { dispatch, login_form, history } = this.props;
        dispatch(apiActions.updateApiSending(true));
        dispatch(api.login(login_form, history, serverError));
      }
    });
  };
  handleInputChange = (e) => {
    const { login_form } = this.props;
    login_form[e.target.name] = e.target.value;
    this.props.dispatch(actions.updateLoginForm(login_form));
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { login_form, sending } = this.props;
    return (
      <Row justify="center">
        <Col span={24} className="text-center padding-top-50">
          <Form onSubmit={this.handleSubmit} className="login-form login">
            <Form.Item className="text-center">
              <h2>PTE University Login</h2>
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please input your username!" },
                ],
                initialValue: login_form.username,
              })(
                <Input
                  name="username"
                  onChange={this.handleInputChange}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Username"
                />
              )}
            </Form.Item>
            <Form.Item className="text-center">
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your password!" },
                ],
                initialValue: login_form.password,
              })(
                <Input
                  name="password"
                  onChange={this.handleInputChange}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                disabled={sending}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              Or <Link to="register">register now!</Link>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
);

const mapStateToProps = (state) => {
  return {
    login_form: state.login,
    sending: state.api.sending,
  };
};

export default connect(mapStateToProps)(WrappedNormalLoginForm);
