import React from 'react';
import {
  Card,
  Row,
  Col,
} from 'antd';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
import * as actions from '../actions/app/tooling';
import ReactQuill from 'react-quill';

var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],
  ['clean']
];

class WysiwygEditor extends React.Component {
  handleChange(value) {
    this.props.dispatch(actions.updateWysiwygText(value));
  }
  render() {
    const { text } = this.props;
    return (
      <div>
      <Row justify="center">
        <Col span={12} className="padding-10">
          <h4>Place Text Here</h4>
          <ReactQuill value={text}
                      modules={{toolbar:toolbarOptions}}
                      onChange={this.handleChange.bind(this)}
                      theme="snow" />
        </Col>
        <Col span={12} className="padding-10">
          <h4>What it will look like</h4>
          <Card size="small" title="Sample output" bordered={true} style={{ width: '100%' }}>
            <p className="margin-zero" dangerouslySetInnerHTML={{__html: text}}></p>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} className="padding-10">
          <h4>Replace your text with this</h4>
          <textarea value={text} rows={10} style={{ width: '100%' }} />
        </Col>
      </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    text : state.tooling.wysiwyg_text,
  }
};

export default connect(mapStateToProps)(WysiwygEditor);