import React from "react";
import { Row, Col, Button, Icon, Collapse } from "antd";
import { connect } from "react-redux";
import parse from "html-react-parser";
import * as Common from "../common";
import * as actions from "../../actions/app/task12";
import * as strategyActions from "../../actions/app/strategy";
import * as appActions from "../../actions/app/app";
import * as EXAM_TYPES from "../../constants/exams";
import * as apiActions from "../../actions/app/api";
import * as api from "../../actions/api/requests";
import * as stratActions from "../../actions/app/strategy";

const Panel = Collapse.Panel;

class FillInSelect extends React.Component {
  componentDidMount() {
    const { dispatch, task, currentContent, strat_mode_on } = this.props;
    dispatch(apiActions.updateApiSending(false));
    this.clearTimer();
    if (!strat_mode_on) {
      dispatch(
        actions.updateAnswers(new Array(task[currentContent].blanks).fill(""))
      );
      dispatch(
        actions.updateAnswers2(new Array(task[currentContent].blanks).fill(""))
      );
      this.startLearningStratTimer();
    }
  }
  componentWillUnmount() {
    this.props.dispatch(strategyActions.reset());
    clearInterval(this.timer);
    this.timer = null;
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  startLearningStratTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { dispatch, reading_timer, max_reading_timer } = this.props;
    const new_timer = reading_timer + 1;
    if (new_timer <= max_reading_timer) {
      dispatch(strategyActions.updateReadingTImer(new_timer));
    } else {
      this.clearTimer();
      this.nextTask();
    }
  }
  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.innerHTML = htmlString.trim();
    // Change this to div.childNodes to support multiple top-level nodes
    return div.firstChild;
  }
  nextTask() {
    const {
      dispatch,
      task,
      currentContent,
      answers,
      answerInstance,
      strat_mode_on,
      answers2,
    } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      this.updateAnswer(answers, answers2);
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "reading"
            )
          );
        }, 1500);
      } else {
        if (task[currentContent + 1].exam_type === EXAM_TYPES.TASK12) {
          dispatch(actions.reset());
          dispatch(
            actions.updateAnswers(
              new Array(task[newCurrentTask].blanks).fill("")
            )
          );
          dispatch(
            actions.updateAnswers2(
              new Array(task[newCurrentTask].blanks).fill("")
            )
          );
          dispatch(strategyActions.reset());
          this.clearTimer();
          this.startLearningStratTimer();
        }
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(stratActions.updateLearningStratModeOn(false));
      }
    } else {
      this.clearTimer();
      dispatch(stratActions.updateLearningStratModeOn(true));
      dispatch(apiActions.updateApiSending(false));
    }
  }
  updateAnswer(answer, answer2) {
    const { dispatch, answerInstance, task, currentContent } = this.props;
    let newAnswerInstance;
    const data = task[currentContent];
    data["student_answer"] = answer;
    data["second_answer"] = answer2;
    newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "reading"));
  }
  resetAnswer() {
    const { dispatch, task, currentContent } = this.props;
    dispatch(
      actions.updateAnswers(new Array(task[currentContent].blanks).fill(""))
    );
  }
  render() {
    const { task, currentContent, strat_mode_on } = this.props;
    let content = task[currentContent].content;
    if (
      content.indexOf("<p>") !== 0 &&
      content.indexOf("</p>") !== content.length - 4
    ) {
      content = "<p>" + content + "</p>";
    }
    const htmlContent = this.createElementFromHTML(content);
    const inputs = htmlContent.getElementsByTagName("input");
    let n = inputs.length;
    while (n--) {
      inputs[n].id = n;
    }
    const paragraph = parse(htmlContent.outerHTML, {
      replace: function(domNode, index) {
        if (domNode.attribs && domNode.attribs.class === "replace") {
          return <Common.BlankSelect itemNumber={domNode.attribs.id} />;
        }
      },
    });
    const paragraph2 = parse(htmlContent.outerHTML, {
      replace: function(domNode, index) {
        if (domNode.attribs && domNode.attribs.class === "replace") {
          return <Common.BlankSelect2 itemNumber={domNode.attribs.id} />;
        }
      },
    });
    return (
      <div>
        <Row className="text-left height-50 gray-border">
          <Row>
            <Col span={24}>
              <p className="fill-select-content margin-auto">{paragraph}</p>
            </Col>
          </Row>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Submit 2nd Answer" key="1">
                    <Row className="text-left height-50 gray-border">
                      <Col span={24}>
                        <p className="fill-select-content margin-auto">
                          {paragraph2}
                        </p>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending}
              onClick={this.nextTask.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
    choices: state.task12.choices,
    chosenAnswer: state.task12.chosenAnswer,
    answers: state.task12.answers,
    answers2: state.task12.answers2,
    user: state.profile,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    reading_timer: state.strategy.reading_timer,
    max_reading_timer: state.strategy.max_reading_timer,
    strat_mode_on: state.strategy.strat_mode_on,
  };
};

export default connect(mapStateToProps)(FillInSelect);
