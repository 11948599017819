import * as task7 from '../types/task7';

export const updateStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_UPDATE_STARTED,
      payload : payload,
    });
  };
};

export const updateDuration = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_DURATION,
      payload : payload,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_TIMER,
      payload : payload,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_UPDATE_ANSWER,
      payload : payload,
    });
  };
};

export const updateAnswer2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_UPDATE_ANSWER2,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_RESET,
    });
  };
};

export const updateExamNumber = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_UPDATE_EXAM_NUMBER,
      payload : payload,
    });
  };
};

export const updateClipboard = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_UPDATE_CLIPBOARD,
      payload : payload,
    });
  };
};

export const updateClipboard2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : task7.TASK7_UPDATE_CLIPBOARD2,
      payload : payload,
    });
  };
};