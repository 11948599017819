import * as actions from '../actions/types/task10';

const defaultState = {
  current_index:0,
  current_answer:[],
  sentence:-1,
  sentenceCon:[],
  answer: -1,
  answers: [],
  sentenceCon2:[],
  answer2: -1,
  answers2: [],
  exam_score: 0,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK10_UPDATE_CURRENT_INDEX: {
      return {...state, current_index:action.payload};
    }
    case actions.TASK10_UPDATE_EXAM_SCORE: {
      return {...state, exam_score:action.payload};
    }
    case actions.TASK10_UPDATE_CURRENT_ANSWER: {
      return {...state, current_answer:action.payload};
    }
    case actions.TASK10_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK10_UPDATE_ANSWERS2: {
      return {...state, answers2:action.payload};
    }
    case actions.TASK10_UPDATE_SENTENCE: {
      return {...state, sentence:action.payload};
    }
    case actions.TASK10_UPDATE_SENTENCE_CON: {
      return {...state, sentenceCon:action.payload};
    }
    case actions.TASK10_UPDATE_SENTENCE_CON2: {
      return {...state, sentenceCon2:action.payload};
    }
    case actions.TASK10_UPDATE_ANSWER: {
      return {...state, answer:action.payload};
    }
    case actions.TASK10_RESET: {
      return {
        ...state,
        current_index:0,
        current_answer:[],
        sentence:-1,
        sentenceCon:[],
        answer: -1,
        answers: [],
        sentenceCon2:[],
        answer2: -1,
        answers2: [],
        exam_score: 0,
      };
    }
    default: return {...state};
  }
};