export const TASK10_UPDATE_STARTED = "TASK10_UPDATE_STARTED";
export const TASK10_RESET = "TASK10_RESET";
export const TASK10_DURATION = "TASK10_DURATION";
export const TASK10_TIMER = "TASK10_TIMER";
export const TASK10_UPDATE_ANSWER = "TASK10_UPDATE_ANSWER";
export const TASK10_UPDATE_CURRENT_INDEX = "TASK10_UPDATE_CURRENT_INDEX";
export const TASK10_UPDATE_CURRENT_ANSWER = "TASK10_UPDATE_CURRENT_ANSWER";
export const TASK10_UPDATE_ANSWERS = "TASK10_UPDATE_ANSWERS";

export const TASK10_UPDATE_SENTENCE = "TASK10_UPDATE_SENTENCE";
export const TASK10_UPDATE_SENTENCE_CON = "TASK10_UPDATE_SENTENCE_CON";
export const TASK10_UPDATE_EXAM_SCORE = "TASK10_UPDATE_EXAM_SCORE";

export const TASK10_UPDATE_ANSWERS2 = "TASK10_UPDATE_ANSWERS2";
export const TASK10_UPDATE_SENTENCE_CON2 = "TASK10_UPDATE_SENTENCE_CON2";