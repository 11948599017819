import * as actions from "../actions/types/api";

const defaultState = {
  sending: false,
  beepUrl:
    "https://s3-ap-southeast-1.amazonaws.com/ptetestmocktestonline/base-test/beep.mp3",
  deviceLogs: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.UPDATE_API_SENDING: {
      return { ...state, sending: action.payload };
    }
    case actions.UPDATE_BEEP_URL: {
      return { ...state, beepUrl: action.payload };
    }
    case actions.UPDATE_API_DEVICE_LOG: {
      return { ...state, deviceLogs: action.payload };
    }
    default:
      return { ...state };
  }
}
