import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/app/task11';

class Blank2 extends React.Component{
  state = {
    dragEnter: false,
  }
  onDragEnter(){
    this.setState({dragEnter: true,})
  }
  onDragExit(){
    this.setState({dragEnter: false,})
  }
  handleDragStart = (answer, e) => {
    this.props.dispatch(actions.updateChosenAnswer(answer));
  }
  handleDrop(index, e){
    this.onDragExit();
    const { chosenAnswer, dispatch  } = this.props;
    const answers = this.props.answers.slice();
    const choices = this.props.choices.slice();
    if (answers[index] !== null){
      choices.push(answers[index]);
    }
    answers[index] = chosenAnswer;
    dispatch(actions.updateAnswers(answers));
    choices.splice(choices.indexOf(chosenAnswer),1);
    dispatch(actions.updateChoices(choices))
    dispatch(actions.updateChosenAnswer(""))
  }
  handleDrop2(index, e){
    this.onDragExit();
    const { chosenAnswer, dispatch  } = this.props;
    const answers = this.props.answers2.slice();
    const choices = this.props.choices2.slice();
    if (answers[index] !== null){
      choices.push(answers[index]);
    }
    answers[index] = chosenAnswer;
    dispatch(actions.updateAnswers2(answers));
    choices.splice(choices.indexOf(chosenAnswer),1);
    dispatch(actions.updateChoices2(choices));
    dispatch(actions.updateChosenAnswer(""));
  }
  render(){
    const { itemNumber, answers, answers2, second_answer } = this.props;
    let anotherClass = this.state.dragEnter? "fill-blank-box-black" : "";
    return (
      <span
        onDragEnter={this.onDragEnter.bind(this)}
        onDragExit={this.onDragExit.bind(this)}
        onDragLeave={this.onDragExit.bind(this)}
        onDragStart={this.handleDragStart.bind(this, answers[itemNumber])}
        draggable={second_answer?answers2[itemNumber]!==null:answers[itemNumber]!==null}
        key={"blank2"+itemNumber}
        className={"fill-blank-box " + anotherClass}
        onDrop={second_answer? this.handleDrop2.bind(this, itemNumber) : this.handleDrop.bind(this, itemNumber)}>
        {
          second_answer?
            answers2[itemNumber]===null?"\u00a0":answers2[itemNumber]
            :
            answers[itemNumber]===null?"\u00a0":answers[itemNumber]
        }
      </span>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    choices: state.task11.choices,
    answers: state.task11.answers,
    choices2: state.task11.choices2,
    answers2: state.task11.answers2,
    chosenAnswer: state.task11.chosenAnswer,
    strat_mode_on  : state.strategy.strat_mode_on,
  }
};

export default connect(mapStateToProps)(Blank2);