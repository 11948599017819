import React from "react";
import {
  Table,
  Divider,
  Row,
  Col,
  Button,
  Input,
  Select,
  Switch,
  InputNumber,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import { connect } from "react-redux";
import * as exams from "../../constants/exams";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as stratActions from "../../actions/app/strategy";
import * as answerInstanceActions from "../../actions/app/answerInstance";

const Option = Select.Option;

class SpeakingBuilder extends React.Component {
  componentDidMount() {
    this.props.dispatch(stratActions.updateLearningStratModeOn(false));
    this.props.dispatch(api.getSpeakingQuestionsCount());
    this.props.dispatch(apiActions.updateApiSending(false));
    this.props.dispatch(answerInstanceActions.reset());
    this.nameInput.focus();
  }
  state = {
    name: "My Speaking Exam",
    questions: [
      {
        index: 0,
        exam_type: exams.TASK1,
        subtype: null,
        count: 1,
        repeat: true,
      },
    ],
  };
  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };
  handleTaskChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        if (value === exams.TASK3) {
          item.subtype = "any";
        } else if (value === exams.TASK4) {
          item.subtype = "any";
        } else {
          item.subtype = null;
        }
        item.exam_type = value;
      }
    });
    this.setState({ questions: data });
  };
  handleSubtypeChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.subtype = value;
      }
    });
    this.setState({ questions: data });
  };
  handleCountChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.count = value;
      }
    });
    this.setState({ questions: data });
  };
  handleRepeatChange = (index, value) => {
    const data = this.state.questions.slice();
    data.forEach((item) => {
      if (item.index === index) {
        item.repeat = value;
      }
    });
    this.setState({ questions: data });
  };
  getNewQuestion() {
    return {
      index: this.state.questions.length,
      exam_type: exams.TASK1,
      subtype: null,
      count: 1,
      repeat: true,
    };
  }
  addNewQuestion() {
    const data = this.state.questions.slice();
    data.push(this.getNewQuestion());
    this.setState({ questions: data });
  }
  remove(index) {
    if (this.state.questions.length > 1) {
      const data = this.state.questions.slice();
      const newData = data.filter((item) => {
        return item.index !== index;
      });
      this.setState({ questions: newData });
    }
  }
  confirmProceed() {
    if (this.props.beepUrl === "") {
      message.error(
        "Some configurations have not loaded properly. Please refresh the page.",
        8
      );
    }
    try {
      navigator.getUserMedia = navigator.getUserMedia(
        { audio: true },
        this.generatePractice.bind(this),
        this.errorMessage.bind(this)
      );
      return;
    } catch (e) {}
    try {
      navigator.webkitGetUserMedia(
        { audio: true },
        this.generatePractice.bind(this),
        this.errorMessage.bind(this)
      );
      return;
    } catch (e) {}
    try {
      navigator.mozGetUserMedia(
        { audio: true },
        this.generatePractice.bind(this),
        this.errorMessage.bind(this)
      );
      return;
    } catch (e) {}
    try {
      navigator.msGetUserMedia(
        { audio: true },
        this.generatePractice.bind(this),
        this.errorMessage.bind(this)
      );
      return;
    } catch (e) {}
    message.error("Unable to detect microphone.", 5);
  }
  generatePractice() {
    const { history, dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(api.generateSpeaking(this.state, history));
  }
  errorMessage() {
    message.error("Please allow this app to use the microphone.", 5);
  }
  render() {
    const { questions } = this.state;
    const { speakingReview, sending } = this.props;
    const columns = [
      {
        title: "Question Type",
        dataIndex: "index",
        key: "qtype",
        render: (index, record) => (
          <Select
            onChange={this.handleTaskChange.bind(this, index)}
            value={record.exam_type}
            style={{ width: "100%" }}
          >
            <Option value={exams.TASK1}>
              Task 01 Read Aloud ({speakingReview.exam1Count})
            </Option>
            <Option value={exams.TASK2}>
              Task 02 Repeat Sentence ({speakingReview.exam2Count})
            </Option>
            <Option value={exams.TASK3}>
              Task 03 Describe Image ({speakingReview.exam3Count})
            </Option>
            <Option value={exams.TASK4}>
              Task 04 Re-tell Lecture ({speakingReview.exam4Count})
            </Option>
            <Option value={exams.TASK5}>
              Task 05 Answer Short Question ({speakingReview.exam5Count})
            </Option>
          </Select>
        ),
      },
      {
        title: "Question Subtype",
        dataIndex: "index",
        key: "subtype",
        render: (index, record) =>
          record.exam_type === exams.TASK1 ||
          record.exam_type === exams.TASK2 ||
          record.exam_type === exams.TASK5 ? (
            <p className="padding-top-10">No subtype</p>
          ) : record.exam_type === exams.TASK3 ? (
            <Select
              onChange={this.handleSubtypeChange.bind(this, index)}
              value={record.subtype}
              style={{ width: "100%" }}
            >
              <Option key={"any"}>Any</Option>
              <Option key={exams.TASK3SUBTYPE1}>
                Chart/Graph/Table with a Time Period
              </Option>
              <Option key={exams.TASK3SUBTYPE2}>
                Chart/Graph Table without a Time Period
              </Option>
              <Option key={exams.TASK3SUBTYPE3}>Diagram</Option>
              <Option key={exams.TASK3SUBTYPE4}>Map</Option>
              <Option key={exams.TASK3SUBTYPE5}>Photograph (Image)</Option>
            </Select>
          ) : record.exam_type === exams.TASK4 ? (
            <Select
              onChange={this.handleSubtypeChange.bind(this, index)}
              value={record.subtype}
              style={{ width: "100%" }}
            >
              <Option key={"any"}>Any</Option>
              <Option key={exams.TASK4SUBTYPE1}>{exams.TASK4SUBTYPE1}</Option>
              <Option key={exams.TASK4SUBTYPE2}>{exams.TASK4SUBTYPE2}</Option>
            </Select>
          ) : null,
      },
      {
        title: "Quantity",
        dataIndex: "index",
        key: "quantity",
        render: (index, item) => (
          <InputNumber
            value={item.count}
            onChange={this.handleCountChange.bind(this, index)}
            min={1}
            defaultValue={1}
          />
        ),
      },
      {
        title: "Remove",
        dataIndex: "index",
        key: "remove",
        render: (index) => (
          <a onClick={this.remove.bind(this, index)}>Remove</a>
        ),
      },
    ];
    return (
      <div>
        <Row>
          <Col span={6} className="text-left">
            <Tooltip
              trigger={["focus"]}
              title={"Enter the name of this test"}
              placement="topLeft"
            >
              <Input
                ref={(input) => {
                  this.nameInput = input;
                }}
                value={this.state.name}
                onChange={this.handleNameChange.bind(this)}
                placeholder="Please enter the name of this practice session"
              />
            </Tooltip>
          </Col>
        </Row>
        <br />
        <Table columns={columns} dataSource={questions} size="small" />
        <Row>
          <Col span={24} className="text-center">
            <a onClick={this.addNewQuestion.bind(this)}>Add New Question</a>
          </Col>
          <br />
          <Divider />
          <Col span={24} className="text-center">
            {sending ? (
              <Button disabled={sending} type="primary">
                Practice Now
              </Button>
            ) : (
              <Popconfirm
                placement="top"
                title={"Do you want to proceed with the practice test?"}
                onConfirm={this.confirmProceed.bind(this)}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={sending} type="primary">
                  Practice Now
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSelectedKeys: state.sidebar.defaultSelectedKeys,
    defaultOpenKeys: state.sidebar.defaultOpenKeys,
    speakingReview: state.speakingReview,
    sending: state.api.sending,
    beepUrl: state.api.beepUrl,
  };
};

export default connect(mapStateToProps)(SpeakingBuilder);
