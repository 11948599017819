import { API } from "aws-amplify";

const api = "pte-api";

export const getSignedUrl = async (key) => {
  return await API.get(
    api,
    `/generate-presigned-url?key=${encodeURIComponent(key)}`
  ).then((response) => {
    return response.url;
  });
};

export async function uploadFile(file, key) {
  const url = await getSignedUrl(key);
  const image = await fetch(url, {
    body: file,
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-Disposition": `attachment; filename="${file.name}"`,
    },
  });

  return image.url.split("?")[0];
}
