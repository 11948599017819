import * as actions from '../actions/types/task3';

const defaultState = {
  task3_started:false,
  countdown_timer: 15,
  countdown_started: false,
  play_beep: false,
  recording_started: false,
  recording_timer: 0,
  recording_duration: 0,
  exam_number: 1,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK3_UPDATE_STARTED: {
      return {...state, task3_started:action.payload};
    }
    case actions.TASK3_UPDATE_EXAM_NUMBER: {
      return {...state, exam_number:action.payload};
    }
    case actions.TASK3_UPDATE_COUNTDOWN_TIMER: {
      return {...state, countdown_timer:action.payload};
    }
    case actions.TASK3_UPDATE_COUNTDOWN_STARTED: {
      return {...state, countdown_started:action.payload};
    }
    case actions.TASK3_UPDATE_PLAY_BEEP: {
      return {...state, play_beep:action.payload};
    }
    case actions.TASK3_UPDATE_RECORDING_STATUS: {
      return {...state, recording_started:action.payload};
    }
    case actions.TASK3_UPDATE_RECORDING_TIMER: {
      return {...state, recording_timer:action.payload};
    }
    case actions.TASK3_UPDATE_RECORDING_DURATION: {
      return {...state, recording_duration:action.payload};
    }
    case actions.TASK3_RESET_SEQUENCE:{
      return {
        ...state,
        task3_started:false,
        countdown_timer: 15,
        countdown_started: false,
        play_beep: false,
        recording_started: false,
        recording_timer: 0,
        recording_duration: 0,
        exam_number: 1,
      }
    }
    default: return {...state};
  }
};