import React from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Divider,
  Card,
  Radio,
  Checkbox,
  Menu,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import * as exams from "../../constants/exams";
import { formatDate } from "../../utils/format";

const RadioGroup = Radio.Group;

const { Header, Content, Footer } = Layout;

const radioStyle = {
  height: "30px",
  lineHeight: "20px",
  paddingTop: "15px",
};

function transformToBlank(str) {
  return str.replace(new RegExp("<input class='replace'/>", "g"), "________");
}

function transformPossibleAnswers(str) {
  const n = str.length;
  let result = "";
  for (let i = 0; i < n; i++) {
    if (str[i].includes("{n}")) {
      const orStr = str[i].split("{n}");
      result += "[";
      result += orStr.toString() + " ";
      result += "]";
    } else {
      result += str[i] + " ";
    }
  }
  return result;
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh} hours ${pad(mm)} minutes ${ss} seconds`;
  }
  return `${mm} minutes ${ss} seconds`;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

class Review extends React.Component {
  state = {
    selectedIndex: 0,
  };
  updateSelectedIndex(i) {
    this.setState({ selectedIndex: i });
  }
  doNotCopy(e) {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  }
  render() {
    const { answerInstance } = this.props;
    let nav_btns;
    if (answerInstance.data) {
      nav_btns = answerInstance.data
        .map((item, index) => {
          return (
            <Menu.Item
              key={"navbtnanswer" + index}
              onClick={this.updateSelectedIndex.bind(this, index)}
            >
              {item.code}
            </Menu.Item>
          );
        })
        .filter((item) => item !== null);
    } else {
      nav_btns = [];
    }
    let answer = [];
    let time_taken = "";
    let created_at = new Date(answerInstance.created_at);
    let completed_at = new Date(answerInstance.completed_at);
    try {
      answer = answerInstance.data[this.state.selectedIndex];
      created_at = new Date(answerInstance.created_at);
      completed_at = new Date(answerInstance.completed_at);
      time_taken = answerInstance.completed_at
        ? format((completed_at.getTime() - created_at.getTime()) / 1000)
        : "Did not finish";
    } catch (e) {}

    return (
      <Layout
        className="layout"
        onCopy={this.doNotCopy.bind(this)}
        onCut={this.doNotCopy.bind(this)}
        onPaste={this.doNotCopy.bind(this)}
      >
        <Header className="header-volcano">
          <Link to="/dashboard/speaking">
            <div className="logo-left">
              <h2>PTE University</h2>
            </div>
          </Link>
          <Link to="/logout">
            <div className="logo-right">
              <h3>Logout</h3>
            </div>
          </Link>
        </Header>
        <Layout>
          <Sidebar />
          <Content className="content-container">
            <Row style={{ display: "flex" }}>
              <Col span={4}>
                <div
                  className="border-right"
                  style={{ background: "#fff", padding: 24, height: "100%" }}
                >
                  <Row>
                    <Col span={24} className="text-center">
                      <h3>Quiz Navigation</h3>
                    </Col>
                    <Col span={24}>
                      <Menu
                        className="no-border-color"
                        selectedKeys={[
                          "navbtnanswer" + this.state.selectedIndex,
                        ]}
                      >
                        {nav_btns}
                      </Menu>
                    </Col>
                    <Col span={24} className="padding-top-15 text-center">
                      <Link to="/dashboard/speaking">
                        <Button type="danger" size="small">
                          Finish Review
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col span={20}>
                <div style={{ background: "#fff", padding: 24 }}>
                  <Col span={20}>
                    <p>
                      <b>Started On:</b> {formatDate(created_at)}
                    </p>
                    <p>
                      <b>State:</b>{" "}
                      {answerInstance.finished ? "Finished" : "Not finished"}
                    </p>
                    <p>
                      <b>Completed On:</b>{" "}
                      {answerInstance.completed_at
                        ? formatDate(completed_at)
                        : "Not finished"}
                    </p>
                    <p>
                      <b>Time Taken:</b> {time_taken}
                    </p>
                    <p>
                      <b>Grade:</b>{" "}
                      {answerInstance.grade
                        ? answerInstance.grade
                        : "Not yet graded"}
                    </p>
                  </Col>
                  <Col span={4} className="text-right">
                    <Link to="/dashboard/speaking">
                      <Button type="danger" size="small">
                        Finish Review
                      </Button>
                    </Link>
                  </Col>
                  <Divider />
                  <h4>{answer?.code}</h4>
                  {answer?.exam_type === exams.TASK1 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 01 Reading: Read Aloud)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.text }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"sa1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.student_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ssa1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.second_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Model Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ma1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.model_answer_audio}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK2 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 02 Reading: Repeat Sentence)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q2" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"sa2" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.student_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ssa1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.second_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Sentence"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer_text,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK3 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 03 Reading: Describe Image)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <img src={answer?.image_url} alt="content"></img>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"sa3" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.student_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ssa1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.second_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Model Answer (Text)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer_text,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Model Answer (Audio)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ma3" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.model_answer_audio}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK4 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 04 Reading: Re-tell Lecture)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q4" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                        <br />
                        <img src={answer?.image_url} alt="content"></img>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"sa4" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.student_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ssa1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.second_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Model Answer (Text)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer_text,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Model Answer (Audio)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ma3" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.model_answer_audio}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK5 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 05 Reading: Answer Short Question)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q5" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"sa5" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.student_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <audio key={"ssa1" + this.state.selectedIndex} controls>
                          <source
                            src={answer?.second_answer}
                            type="audio/webm"
                          />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Question (Text)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer_text,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK8 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 12 Reading: Reading & Writing: Fill In The Blanks)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.content }}
                        ></p>
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.question }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>
                          Your answer is&nbsp;
                          {answer?.student_answer ===
                          answer?.correct_answer[0] ? (
                            <span
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <b>&nbsp;&nbsp;CORRECT&nbsp;&nbsp;</b>
                            </span>
                          ) : (
                            <span
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              <b>&nbsp;&nbsp;INCORRECT&nbsp;&nbsp;</b>
                            </span>
                          )}
                        </p>
                        <RadioGroup value={answer?.student_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.student_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.student_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>
                          Your answer is&nbsp;
                          {answer?.second_answer ===
                          answer?.correct_answer[0] ? (
                            <span
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            >
                              <b>&nbsp;&nbsp;CORRECT&nbsp;&nbsp;</b>
                            </span>
                          ) : (
                            <span
                              style={{ backgroundColor: "red", color: "white" }}
                            >
                              <b>&nbsp;&nbsp;INCORRECT&nbsp;&nbsp;</b>
                            </span>
                          )}
                        </p>
                        <RadioGroup value={answer?.second_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.second_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.second_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>{answer?.answers[answer?.correct_answer[0] - 1]}</p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK9 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 09 Reading: Multiple-Choice: Choose Multiple Answers)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <br />
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.content }}
                        ></p>
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.question }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          {answer?.answers.map((item, index) => {
                            const chosen_answer = answer?.student_answer?.includes(
                              index + 1
                            );
                            const student_correct = answer?.correct_answer?.includes(
                              index + 1
                            );
                            let numberClass = "";
                            if (chosen_answer && student_correct) {
                              numberClass = "selected-mcq-correct";
                            } else if (chosen_answer && !student_correct) {
                              numberClass = "selected-mcq-wrong";
                            }
                            return (
                              <Col
                                key={"checkbox" + index}
                                className={numberClass}
                                span={24}
                              >
                                <Checkbox
                                  disabled={!chosen_answer}
                                  checked={chosen_answer}
                                  className="padding-top-15"
                                >
                                  {item}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          {answer?.answers.map((item, index) => {
                            const chosen_answer = answer?.second_answer?.includes(
                              index + 1
                            );
                            const student_correct = answer?.correct_answer?.includes(
                              index + 1
                            );
                            let numberClass = "";
                            if (chosen_answer && student_correct) {
                              numberClass = "selected-mcq-correct";
                            } else if (chosen_answer && !student_correct) {
                              numberClass = "selected-mcq-wrong";
                            }
                            return (
                              <Col
                                key={"checkbox" + index}
                                className={numberClass}
                                span={24}
                              >
                                <Checkbox
                                  disabled={!chosen_answer}
                                  checked={chosen_answer}
                                  className="padding-top-15"
                                >
                                  {item}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>
                          <ul>
                            {answer?.correct_answer?.map((item) => {
                              return <li>{answer?.answers[item - 1]}</li>;
                            })}
                          </ul>
                        </p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK10 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 10 Reading: Re-order Paragraphs)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ol>
                          {answer?.student_answer?.map((item) => {
                            return <li>{answer?.answers[item - 1]}</li>;
                          })}
                        </ol>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ol>
                          {answer?.second_answer?.map((item) => {
                            return <li>{answer?.answers[item - 1]}</li>;
                          })}
                        </ol>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>
                          <ol>
                            {answer?.correct_answer?.map((item) => {
                              return <li>{answer?.answers[item - 1]}</li>;
                            })}
                          </ol>
                        </p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK11 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 11 Reading: Reading: Fill In The Blanks)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <p
                          className="fill-blank-content margin-auto"
                          dangerouslySetInnerHTML={{
                            __html: transformToBlank(answer?.content),
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ol>
                          {answer?.student_answer?.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ol>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ol>
                          {answer?.second_answer?.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ol>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>
                          <ol>
                            {answer?.correct_answer?.map((item) => {
                              return <li>{item}</li>;
                            })}
                          </ol>
                        </p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK12 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 08 Reading: Multiple-Choice: Choose Single Answer)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <p
                          className="fill-blank-content margin-auto"
                          dangerouslySetInnerHTML={{
                            __html: transformToBlank(answer?.content),
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ol>
                          {answer?.student_answer?.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ol>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ol>
                          {answer?.second_answer?.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </ol>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p>
                          <ol>
                            {answer?.correct_answer?.map((item) => {
                              return <li>{item}</li>;
                            })}
                          </ol>
                        </p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK13 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 13 Listening: Summarize Spoken Text)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q13" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.student_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.second_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Model Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK14 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 14 Listening: Listening: Multiple-Choice: Choose Multiple Answers)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          {answer?.answers.map((item, index) => {
                            const chosen_answer = answer?.student_answer?.includes(
                              index + 1
                            );
                            const student_correct = answer?.correct_answer?.includes(
                              index + 1
                            );
                            let numberClass = "";
                            if (chosen_answer && student_correct) {
                              numberClass = "selected-mcq-correct";
                            } else if (chosen_answer && !student_correct) {
                              numberClass = "selected-mcq-wrong";
                            }
                            return (
                              <Col
                                key={"checkbox" + index}
                                className={numberClass}
                                span={24}
                              >
                                <Checkbox
                                  disabled={!chosen_answer}
                                  checked={chosen_answer}
                                  className="padding-top-15"
                                >
                                  {item}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          {answer?.answers.map((item, index) => {
                            const chosen_answer = answer?.second_answer?.includes(
                              index + 1
                            );
                            const student_correct = answer?.correct_answer?.includes(
                              index + 1
                            );
                            let numberClass = "";
                            if (chosen_answer && student_correct) {
                              numberClass = "selected-mcq-correct";
                            } else if (chosen_answer && !student_correct) {
                              numberClass = "selected-mcq-wrong";
                            }
                            return (
                              <Col
                                key={"checkbox" + index}
                                className={numberClass}
                                span={24}
                              >
                                <Checkbox
                                  disabled={!chosen_answer}
                                  checked={chosen_answer}
                                  className="padding-top-15"
                                >
                                  {item}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ul>
                          {answer?.correct_answer?.map((item) => {
                            return <li>{answer?.answers[item - 1]}</li>;
                          })}
                        </ul>
                      </Card>
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK15 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 15 Listening: Listening: Fill In The Blanks)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                        <br />
                        <p
                          className="fill-blank-content margin-auto"
                          dangerouslySetInnerHTML={{
                            __html: transformToBlank(answer?.content),
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          <ul>
                            {answer?.student_answer?.map((item, index) => {
                              return <li key={"answerList" + index}>{item}</li>;
                            })}
                          </ul>
                        </Row>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <Row>
                          <ul>
                            {answer?.second_answer?.map((item, index) => {
                              return <li key={"answerList" + index}>{item}</li>;
                            })}
                          </ul>
                        </Row>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <ul>
                          {answer?.correct_answer?.map((item, index) => {
                            return (
                              <li key={"correctAnswerList" + index}>{item}</li>
                            );
                          })}
                        </ul>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK16 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 16 Listening: Highlight Correct Summary)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <RadioGroup value={answer?.student_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.student_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.student_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <RadioGroup value={answer?.second_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.second_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.second_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html:
                              answer?.answers[answer?.correct_answer[0] - 1],
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK17 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 17 Listening: Listening: Multiple-Choice: Choose Single Answers)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                        <br />
                        <br />
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.question }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <RadioGroup value={answer?.student_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.student_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.student_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <RadioGroup value={answer?.second_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.second_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.second_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html:
                              answer?.answers[answer?.correct_answer[0] - 1],
                          }}
                        ></p>
                      </Card>
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK18 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 18 Listening: Select Missing Word)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <RadioGroup value={answer?.student_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.student_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.student_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <RadioGroup value={answer?.second_answer}>
                          {answer?.answers.map((item, index) => {
                            const student_correct =
                              answer?.second_answer ===
                              answer?.correct_answer[0];
                            const chosen_answer =
                              index + 1 === answer?.second_answer;
                            return (
                              <Radio
                                value={index + 1}
                                disabled={!chosen_answer}
                                style={radioStyle}
                                className={
                                  !chosen_answer
                                    ? "wrap-label"
                                    : !student_correct
                                    ? "wrap-label mcq-wrong"
                                    : "wrap-label mcq-correct"
                                }
                              >
                                {item}
                              </Radio>
                            );
                          })}
                        </RadioGroup>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Recording (Transcripts)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html:
                              answer?.answers[answer?.correct_answer[0] - 1],
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Explanation"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.explanation,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK19 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 19 Listening: Highlight Incorrect Words)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        {answer?.content.split(" ").map((word, index) => {
                          return (
                            <span
                              className={
                                answer?.student_answer?.indexOf(index + 1) ===
                                -1
                                  ? "word"
                                  : answer?.correct_answer?.indexOf(
                                      index + 1
                                    ) === -1
                                  ? "word word-wrong"
                                  : "word word-correct"
                              }
                              key={"word-" + index}
                            >
                              {word}
                            </span>
                          );
                        })}
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        {answer?.content.split(" ").map((word, index) => {
                          return (
                            <span
                              className={
                                answer?.second_answer?.indexOf(index + 1) === -1
                                  ? "word"
                                  : answer?.correct_answer?.indexOf(
                                      index + 1
                                    ) === -1
                                  ? "word word-wrong"
                                  : "word word-correct"
                              }
                              key={"word-" + index}
                            >
                              {word}
                            </span>
                          );
                        })}
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        {answer?.content.split(" ").map((word, index) => {
                          return (
                            <span
                              className={
                                answer?.correct_answer?.indexOf(index + 1) ===
                                -1
                                  ? "word"
                                  : "word word-selected"
                              }
                              key={"word-" + index}
                            >
                              {word}
                            </span>
                          );
                        })}
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="What Was Said Instead"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.transcript,
                          }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK20 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 20 Listening: Write From Dictation)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <audio key={"q14" + this.state.selectedIndex} controls>
                          <source src={answer?.audio_url} type="audio/webm" />
                        </audio>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.student_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.second_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: transformPossibleAnswers(
                              answer?.correct_answer
                            ),
                          }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK6 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 06 Writing: Summarize Written Text)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <br />
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.content }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.student_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.second_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : answer?.exam_type === exams.TASK7 ? (
                    <div>
                      <Card
                        size="small"
                        title="Question (Task 07 Writing: Write Essay)"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <h4
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.instruction,
                          }}
                        ></h4>
                        <br />
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.content }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.student_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Your 2nd Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.second_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Correct Answer"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{
                            __html: answer?.model_answer,
                          }}
                        ></p>
                      </Card>
                      <br />
                      <Card
                        size="small"
                        title="Learning Tips"
                        bordered={true}
                        style={{ width: "100%" }}
                      >
                        <p
                          className="margin-zero"
                          dangerouslySetInnerHTML={{ __html: answer?.ls_tips }}
                        ></p>
                      </Card>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
        <Footer className="text-center">
          PTE University ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    answerInstance: state.answerInstance,
  };
};

export default connect(mapStateToProps)(Review);
