import * as actions from "../types/api";

export const updateApiSending = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_API_SENDING,
      payload: payload,
    });
  };
};

export const updateBeepUrl = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_BEEP_URL,
      payload: payload,
    });
  };
};

export const updateDeviceLogs = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actions.UPDATE_API_DEVICE_LOG,
      payload: payload,
    });
  };
};
