import React from 'react';
import { Input } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../../actions/app/task15';

class BlankInput extends React.Component{
  handleOnchange = (index, e) => {
    const i = parseInt(index, 10);
    const { dispatch, answers } = this.props;
    const newAnswers = answers.slice();
    newAnswers[i] = e.target.value;
    dispatch(actions.updateAnswers(newAnswers));
  }
  render(){
    const { itemNumber, answers, strat_mode_on } = this.props;
    const i = parseInt(itemNumber, 10);
    return (
      <Input disabled={strat_mode_on} value={answers[i]} size="small" className="fill-in-input" onChange={this.handleOnchange.bind(this, itemNumber)} />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    choices: state.task15.choices,
    answers: state.task15.answers,
    chosenAnswer: state.task15.chosenAnswer,
    strat_mode_on: state.strategy.strat_mode_on,
  }
};

export default connect(mapStateToProps)(BlankInput);