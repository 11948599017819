import * as actions from '../actions/types/task0';

const defaultState = {
  timer:25,
  recording_status: false,
  recording_timer:30,
  play_beep: false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK0_UPDATE_TIMER: {
      return {...state, timer:action.payload};
    }
    case actions.TASK0_UPDATE_RECORDING_TIMER: {
      return {...state, recording_timer:action.payload};
    }
    case actions.TASK0_UPDATE_RECORDING_STATUS: {
      return {...state, recording_status:action.payload};
    }
    case actions.TASK0_UPDATE_PLAY_BEEP: {
      return {...state, play_beep:action.payload};
    }
    default: return {...state};
  }
};