import { createStore, applyMiddleware } from "redux";
import setAuthorizationToken from "../utils/setAuthorizationToken";

import reducer from "../reducers";
import throttle from "lodash/throttle";
// import logger from 'redux-logger';
import thunk from "redux-thunk";

import { PERSISTED_STATE_KEY } from "../constants/keys";

import { message } from "antd";

message.config({
  maxCount: 1,
});

let persistedState = {};
let defaultState = {};

if (sessionStorage.getItem(PERSISTED_STATE_KEY)) {
  persistedState = JSON.parse(sessionStorage.getItem(PERSISTED_STATE_KEY));
} else {
  persistedState = defaultState;
}

if (persistedState !== defaultState) {
  setAuthorizationToken(persistedState.auth.token);
}

const middleware = applyMiddleware(thunk);

const store = createStore(reducer, persistedState, middleware);

store.subscribe(
  throttle(() => {
    const state = store.getState();
    sessionStorage.setItem(PERSISTED_STATE_KEY, JSON.stringify(state));
  }),
  1000
);

export default store;
