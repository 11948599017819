import * as actions from '../actions/types/task13';

const defaultState = {
  task13_started:false,
  duration : 0,
  timer: 0,
  answer: "",
  answer2: "",
  countdown_started: false,
  countdown_timer: 0,
  max_countdown_timer: 20,
  play_status: false,
  play_ended: false,
  progress: 0,
  exam_number: 1,
  exam_timer: 0,
  exam_timer_started: false,
  clip_board: "",
  clip_board2: "",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK13_UPDATE_STARTED: {
      return {...state, task13_started:action.payload};
    }
    case actions.TASK13_UPDATE_EXAM_NUMBER: {
      return {...state, exam_number:action.payload};
    }
    case actions.TASK13_DURATION: {
      return {...state, duration:action.payload};
    }
    case actions.TASK13_TIMER: {
      return {...state, timer:action.payload};
    }
    case actions.TASK13_UPDATE_ANSWER: {
      return {...state, answer:action.payload};
    }
    case actions.TASK13_UPDATE_ANSWER2: {
      return {...state, answer2:action.payload};
    }
    case actions.TASK13_UPDATE_COUNTDOWN_STARTED: {
      return {...state, countdown_started:action.payload};
    }
    case actions.TASK13_UPDATE_COUNTDOWN_TIMER: {
      return {...state, countdown_timer:action.payload};
    }
    case actions.TASK13_UPDATE_PLAY_STATUS: {
      return {...state, play_status:action.payload};
    }
    case actions.TASK13_UPDATE_PLAY_PROGRESS: {
      return {...state, progress:action.payload};
    }
    case actions.TASK13_UPDATE_PLAY_ENDED: {
      return {...state, play_ended:action.payload};
    }
    case actions.TASK13_UPDATE_EXAM_TIMER: {
      return {...state, exam_timer:action.payload};
    }
    case actions.TASK13_UPDATE_EXAM_TIMER_STARTED: {
      return {...state, exam_timer_started:action.payload};
    }
    case actions.TASK13_UPDATE_CLIP_BOARD: {
      return {...state, clip_board:action.payload};
    }
    case actions.TASK13_UPDATE_CLIP_BOARD2: {
      return {...state, clip_board2:action.payload};
    }
    case actions.TASK13_RESET: {
      return {
        ...defaultState
      };
    }
    default: return {...state};
  }
};