import React from 'react';
import {
  // Select,
  Row,
  Col,
  Input,
} from 'antd';
import 'react-quill/dist/quill.snow.css';
import { connect } from 'react-redux';
const { TextArea } = Input;

class Paragraph extends React.Component {
  state = {
    text: '',
    current_answer: [],
  }
  handleInputChange(e){
    this.setState({text:e.target.value, current_answer:[]});
  }
  handleClick(index){
    const answers = this.state.current_answer.slice();
    if (answers.indexOf(index + 1) !== -1){
      answers.splice(answers.indexOf(index + 1), 1);
    } else {
      answers.push(index + 1);
    }
    this.setState({current_answer:answers})
  }
  render() {
    const { current_answer, text } = this.state;
    const string_array = text.split(" ");
    const clickable_span = string_array.map((word, index) => {
      if (word === '\\n'){
        return <br/>;
      }
      return (
        <span
          onClick={this.handleClick.bind(this, index)}
          className={current_answer.indexOf(index+1) === -1? "word":"word word-selected"}
          key={"word-"+index}
        >
          {word}
        </span>
      );
    });
    return (
      <Row justify="center">
        <Col span={12} className="padding-20">
          <h4>Place the paragraph here</h4>
          <TextArea
            rows={10}
            onChange={this.handleInputChange.bind(this)}
            value={this.state.text}
          />
        </Col>
        <Col span={12} className="padding-20">
          <h4>Click on the word you want to add as an answer</h4>
          <Col span={24}>
            {clickable_span}
          </Col>
        </Col>
        <Col span={24} className="padding-20 text-center">
          <h4>Generated JSON</h4>
          <TextArea value={"["+ current_answer.toString() +"]"} rows={5} className={"width-50-percent"} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    json : state.tooling.json,
  }
};

export default connect(mapStateToProps)(Paragraph);