export const TASK16_UPDATE_STARTED = "TASK16_UPDATE_STARTED";
export const TASK16_RESET = "TASK16_RESET";
export const TASK16_DURATION = "TASK16_DURATION";
export const TASK16_TIMER = "TASK16_TIMER";
export const TASK16_UPDATE_ANSWER = "TASK16_UPDATE_ANSWER";
export const TASK16_UPDATE_CURRENT_INDEX = "TASK16_UPDATE_CURRENT_INDEX";
export const TASK16_UPDATE_CURRENT_ANSWER = "TASK16_UPDATE_CURRENT_ANSWER";
export const TASK16_UPDATE_CURRENT_ANSWER2 = "TASK16_UPDATE_CURRENT_ANSWER2";
export const TASK16_UPDATE_ANSWERS = "TASK16_UPDATE_ANSWERS";
export const TASK16_UPDATE_COUNTDOWN_STARTED = "TASK16_UPDATE_COUNTDOWN_STARTED";
export const TASK16_UPDATE_COUNTDOWN_TIMER = "TASK16_UPDATE_COUNTDOWN_TIMER";
export const TASK16_UPDATE_PLAY_STATUS = "TASK16_UPDATE_PLAY_STATUS";
export const TASK16_UPDATE_PLAY_PROGRESS = "TASK16_UPDATE_PLAY_PROGRESS";
export const TASK16_UPDATE_PLAY_ENDED = "TASK16_UPDATE_PLAY_ENDED";