import * as actions from '../actions/types/task12';

const defaultState = {
  choices:[],
  answers: [],
  choices2:[],
  answers2: [],
  chosenAnswer:"",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK12_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK12_UPDATE_CHOICES: {
      return {...state, choices:action.payload};
    }
    case actions.TASK12_UPDATE_ANSWERS2: {
      return {...state, answers2:action.payload};
    }
    case actions.TASK12_UPDATE_CHOICES2: {
      return {...state, choices2:action.payload};
    }
    case actions.TASK12_UPDATE_CHOSEN_ANSWER: {
      return {...state, chosenAnswer:action.payload};
    }
    case actions.TASK12_RESET: {
      return {...defaultState};
    }
    default: return {...state};
  }
};