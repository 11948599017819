import * as profile from '../types/profile';

export const updateProfile = (payload) => {
  return dispatch => {
    dispatch({
      type    : profile.UPDATE_PROFILE,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : profile.RESET_PROFILE,
    });
  };
};

