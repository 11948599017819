import React from "react";
import { Row, Col, Tooltip, Button } from "antd";
import { ReactMic } from "react-mic";
import { FaMicrophone } from "react-icons/fa";
import { connect } from "react-redux";
import * as apiActions from "../../actions/app/api";

class MicTest extends React.Component {
  componentDidMount() {
    const { deviceLogs, dispatch } = this.props;
    const newLogs = deviceLogs.slice();
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      newLogs.push("enumerateDevices() not supported.");
      dispatch(apiActions.updateDeviceLogs(newLogs));
      return;
    }
    navigator.mediaDevices
      .enumerateDevices()
      .then(function(devices) {
        devices.forEach(function(device) {
          newLogs.push(
            device.kind + ": " + device.label + " id = " + device.deviceId
          );
          dispatch(apiActions.updateDeviceLogs(newLogs));
        });
      })
      .catch(function(err) {
        console.log(error);
        // console.log(err.name + ": " + err.message);
        newLogs.push(err.name + ": " + err.message);
        dispatch(apiActions.updateDeviceLogs(newLogs));
      });
  }

  componentWillUnmount() {
    this.props.dispatch(apiActions.updateDeviceLogs([]));
  }
  state = {
    recording: false,
    audioUrl: null,
  };
  toggleRecording(payload) {
    this.setState({ recording: payload });
  }
  onRecordingStop = (blobObject) => {
    this.setState({ audioUrl: blobObject.blobURL });
  };
  render() {
    const { recording } = this.state;
    return (
      <Row>
        <Col span={24} className="text-center">
          <h4>
            Press the mic button to start recording and then the pause button
            when you are done. Press the play button when you want to hear the
            recording.
            <br />
            Refresh the page if you are unable to hear your voice. If it still
            does not work, please make sure your microphone is working and try
            again.
          </h4>
          <br />
          <audio
            ref="audioSource"
            controls="controls"
            src={this.state.audioUrl}
          ></audio>
          <br />
          <ReactMic
            className="mic-test-soundwave"
            record={recording}
            backgroundColor="#1890ff"
            audioBitsPerSecond={128000}
            onStop={this.onRecordingStop.bind(this)}
            strokeColor="#fff"
            style={{ width: "30%", maxWidth: "10%" }}
          />
        </Col>
        <Col span={24} className="text-center">
          <Tooltip title="Start recording">
            <Button
              onClick={this.toggleRecording.bind(this, true)}
              disabled={recording}
              type="primary"
              shape="circle"
              size="large"
            >
              <FaMicrophone />
            </Button>
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip title="Stop recording">
            <Button
              onClick={this.toggleRecording.bind(this, false)}
              disabled={!recording}
              type="primary"
              shape="circle"
              icon="pause"
              size="large"
            />
          </Tooltip>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deviceLogs: state.api.deviceLogs,
  };
};

export default connect(mapStateToProps)(MicTest);
