import * as actions from '../types/readingReview';

export const updateAnswerInstances = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.READING_REVIEW_UPDATE_ANSWER_INSTANCES,
      payload : payload,
    });
  };
};

export const updateExam8Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.READING_REVIEW_UPDATE_EXAM8_COUNT,
      payload : payload,
    });
  };
};

export const updateExam9Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.READING_REVIEW_UPDATE_EXAM9_COUNT,
      payload : payload,
    });
  };
};

export const updateExam10Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.READING_REVIEW_UPDATE_EXAM10_COUNT,
      payload : payload,
    });
  };
};

export const updateExam11Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.READING_REVIEW_UPDATE_EXAM11_COUNT,
      payload : payload,
    });
  };
};

export const updateExam12Count = (payload) => {
  return dispatch => {
    dispatch({
      type    : actions.READING_REVIEW_UPDATE_EXAM12_COUNT,
      payload : payload,
    });
  };
};