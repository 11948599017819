import React from "react";
import { Row, Col, Progress, Button, Icon, Collapse, Tooltip } from "antd";
import * as Common from "../common";
import { connect } from "react-redux";
import { ReactMic } from "react-mic";
import * as actions from "../../actions/app/task4";
import * as appActions from "../../actions/app/app";
import ReactPlayer from "react-player";
import * as EXAM_TYPES from "../../constants/exams";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as stratActions from "../../actions/app/strategy";
import { FaMicrophone } from "react-icons/fa";
import { uploadFile } from "../../actions/api/storage";

const Panel = Collapse.Panel;

function padZero(num) {
  var zero = 2 - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export const AudioPlayer = (props) => {
  let recording_percent =
    (Math.floor(props.recording_timer) / Math.floor(props.recording_duration)) *
    100;
  let play_duration_m = 0;
  let play_duration_s = 0;
  let play_progress_m = 0;
  let play_progress_s = 0;
  let audio_percent = 0;
  if (props.play_status) {
    let play_progress = Math.round(props.play_progress);
    play_duration_m = props.play_duration / 60;
    play_duration_m = Math.floor(play_duration_m);
    play_duration_s = props.play_duration % 60;
    play_duration_s = Math.floor(play_duration_s);
    play_progress_m = Math.floor(play_progress / 60);
    play_progress_s = play_progress % 60;
    audio_percent = (play_progress / props.play_duration) * 100;
  }
  return (
    <Row className="text-center margin-top-30 padding-10">
      {props.play_beep ? <Common.Beep {...props} /> : null}
      <Row className="graybox text-center">
        <Col span={24} className="margin-top-10">
          <p className="countdown">
            {props.strat_mode_on
              ? "Recording complete"
              : props.play_status
              ? "Playing"
              : props.countdown_started
              ? "Recording in " + props.countdown_timer
              : props.play_beep
              ? "Recording in 0"
              : props.recording_started
              ? "Recording"
              : props.begin_timer > 0
              ? "Beginning in " + props.begin_timer
              : props.recording_complete
              ? "Recording complete"
              : null}
          </p>
        </Col>
        <Col span={24} className="margin-top-10">
          {props.strat_mode_on ? (
            <p>
              <Progress
                className="width-25"
                percent={100}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;00:{padZero(props.recording_duration)} /
              00:{padZero(props.recording_duration)}
            </p>
          ) : props.play_status ? (
            <p>
              <Progress
                className="width-25"
                percent={audio_percent}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;{padZero(play_progress_m)}:
              {padZero(play_progress_s)} / {padZero(play_duration_m)}:
              {padZero(play_duration_s)}
            </p>
          ) : props.recording_started ? (
            <p>
              <Progress
                className="width-25"
                percent={props.strat_mode_on ? 100 : recording_percent}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;00:
              {props.strat_mode_on
                ? padZero(props.recording_duration)
                : padZero(props.recording_timer)}{" "}
              / 00:{padZero(props.recording_duration)}
            </p>
          ) : null}
        </Col>
        <Col span={24} className="text-center">
          {props.strat_mode_on ? null : (
            <ReactMic
              className="soundwave"
              record={props.recording_started}
              onStop={props.onStop}
              strokeColor={"#006480"}
              backgroundColor={"#fff"}
              style={{ maxWidth: "90%" }}
            />
          )}
        </Col>
      </Row>
    </Row>
  );
};

class RetellLecture extends React.Component {
  state = {
    recording: false,
  };
  toggleRecording(payload) {
    this.setState({ recording: payload });
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  componentDidMount() {
    this.clearTimer();
    const {
      dispatch,
      task4_started,
      countdown_started,
      recording_started,
      play_status,
      play_progress,
      begin_timer,
      strat_mode_on,
    } = this.props;
    dispatch(apiActions.updateApiSending(false));
    if (!strat_mode_on) {
      if (!task4_started) {
        dispatch(actions.updateStarted(true));
        this.startBeginCountdown();
      } else if (task4_started && begin_timer > 0) {
        this.startBeginCountdown();
      } else if (play_status) {
        this.player.seekTo(play_progress);
      } else if (countdown_started) {
        this.startCountdown();
      } else if (recording_started) {
        const { task, currentContent } = this.props;
        const duration = task[currentContent]["record_duration"];
        dispatch(actions.updatePlayBeep(true));
        dispatch(actions.updateRecordingTimer(0));
        dispatch(actions.updateRecordingDuration(duration));
      }
    }
  }
  startBeginCountdown() {
    this.timer = setInterval(this.tick3.bind(this), 1000);
  }
  tick3() {
    const { dispatch, begin_timer } = this.props;
    const new_timer = begin_timer - 1;
    if (new_timer >= 0) {
      dispatch(actions.updateBeginTimer(new_timer));
    } else {
      this.clearTimer();
      dispatch(actions.updatePlayStatus(true));
    }
  }
  startCountdown() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { dispatch, countdown_timer } = this.props;
    const new_timer = countdown_timer - 1;
    if (new_timer >= 0) {
      dispatch(actions.updateCountdownTimer(new_timer));
    } else {
      this.clearTimer();
      dispatch(actions.updateCountdownStarted(false));
      dispatch(actions.updatePlayBeep(true));
    }
  }
  handleEndBeep = () => {
    const { dispatch, task, currentContent } = this.props;
    const duration = task[currentContent]["record_duration"];
    dispatch(actions.updatePlayBeep(false));
    dispatch(actions.updateRecordingDuration(duration));
    dispatch(actions.updateRecordingStarted(true));
    this.startRecording();
  };
  startRecording() {
    this.timer = setInterval(this.tick2.bind(this), 1000);
  }
  tick2() {
    const { dispatch, recording_timer, recording_duration } = this.props;
    const new_timer = recording_timer + 1;
    if (new_timer <= recording_duration) {
      dispatch(actions.updateRecordingTimer(new_timer));
    } else {
      this.clearTimer();
      dispatch(actions.updateRecordingStarted(false));
    }
  }
  updateAnswer(url) {
    this.clearTimer();
    const { dispatch, answerInstance, task, currentContent } = this.props;
    const data = task[currentContent];
    data["student_answer"] = url;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data.push(data);
    dispatch(api.updateAnswerInstance(newAnswerInstance, "speaking"));
  }
  handleEndRecord = (recordBlob) => {
    const { user, dispatch } = this.props;
    dispatch(actions.updateRecordingComplete(true));
    var xhr = new XMLHttpRequest();
    xhr.open("GET", recordBlob.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.username;
      const audioAnswer = new File([xhr.response], "answer.webm");
      const key =
        "student-answers/individual/retell-lecture/" +
        userName +
        "/" +
        Date.now() +
        ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          this.updateAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  componentWillUnmount() {
    this.clearTimer();
  }
  nextTask() {
    this.clearTimer();
    const {
      dispatch,
      currentContent,
      exam_number,
      task,
      answerInstance,
      strat_mode_on,
    } = this.props;
    if (strat_mode_on) {
      dispatch(actions.updateExamNumber(exam_number + 1));
      const newCurrentTask = currentContent + 1;
      if (newCurrentTask >= task.length) {
        dispatch(appActions.updateAppLoading(true));
        answerInstance["finished"] = true;
        answerInstance["completed_at"] = new Date();
        setTimeout(() => {
          dispatch(
            api.updateAnswerInstanceWithHistory(
              answerInstance,
              this.props.history,
              "speaking"
            )
          );
        }, 1500);
      } else {
        dispatch(stratActions.updateLearningStratModeOn(false));
        dispatch(appActions.updateCurrentContent(newCurrentTask));
        dispatch(actions.updateSequence());
        dispatch(actions.updateStarted(true));
        if (task[newCurrentTask].exam_type === EXAM_TYPES.TASK4) {
          // dispatch(actions.updatePlayStatus(true));
          this.startBeginCountdown();
        }
      }
    } else {
      dispatch(stratActions.updateLearningStratModeOn(true));
    }
  }
  onDuration = (duration) => {
    const { dispatch } = this.props;
    dispatch(actions.updatePlayDuration(duration));
  };
  handleEndAudio() {
    const { dispatch, task, currentContent, play_duration } = this.props;
    dispatch(actions.updatePlayProgress(play_duration));
    dispatch(actions.updatePlayStatus(false));
    dispatch(actions.updateCountdownTimer(task[currentContent].read_duration));
    dispatch(actions.updateCountdownStarted(true));
    this.startCountdown();
  }
  onProgress = (data) => {
    const playedSeconds = data.playedSeconds;
    const { dispatch } = this.props;
    dispatch(actions.updatePlayProgress(playedSeconds));
  };
  ref = (player) => {
    this.player = player;
  };
  handleNextBtn() {
    const { recording_started, dispatch, strat_mode_on } = this.props;
    dispatch(apiActions.updateApiSending(true));
    if (strat_mode_on) {
      if (this.state.blobURL) {
        this.uploadSecondAnswer();
      } else {
        dispatch(apiActions.updateApiSending(false));
        this.nextTask();
      }
    } else {
      if (recording_started) {
        this.clearTimer();
        this.props.dispatch(actions.updateRecordingStarted(false));
      } else {
        this.updateAnswer("");
        this.nextTask();
      }
    }
  }
  update2ndAnswer(url) {
    const { dispatch, answerInstance } = this.props;
    const newAnswerInstance = { ...answerInstance };
    newAnswerInstance.data[newAnswerInstance.data.length - 1][
      "second_answer"
    ] = url;
    dispatch(api.updateAnswerInstance(newAnswerInstance, "speaking"));
  }
  uploadSecondAnswer = () => {
    const { user } = this.props;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", this.state.blobURL);
    xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      const userName = user.username;
      const audioAnswer = new File([xhr.response], "answer.webm");
      const key =
        "student-answers/individual/retell-lecture/" +
        userName +
        "/" +
        Date.now() +
        ".webm";
      uploadFile(audioAnswer, key)
        .then((result) => {
          this.update2ndAnswer(result);
          this.nextTask();
        })
        .catch((error) => {
          console.log(error);
          return alert(
            "There was an error uploading your audio: " +
              error +
              "\n\n Please refresh the page multiple times until the audio plays again."
          );
        });
    };
    xhr.send();
  };
  onRecordingStop = (blobObject) => {
    this.setState({ blobURL: blobObject.blobURL });
  };
  render() {
    const { task, currentContent, play_status, strat_mode_on } = this.props;
    const { recording } = this.state;
    return (
      <div>
        <Row>
          <Col span={12} className="text-center">
            <img
              src={task[currentContent].image_url}
              style={{ maxWidth: "100%" }}
              alt="Describe This"
            />
          </Col>
          <Col span={12}>
            <AudioPlayer
              {...this.props}
              onEnded={this.handleEndBeep.bind(this)}
              onStop={this.handleEndRecord.bind(this)}
            />
          </Col>
        </Row>
        <Row className="margin-top-30 padding-top-150">
          {strat_mode_on ? (
            <div>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Instructions (Learning Strategy)" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_instruction,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Learning Tips" key="1">
                    <p className="content text-left">
                      <div
                        className="margin-zero"
                        dangerouslySetInnerHTML={{
                          __html: task[currentContent].ls_tips,
                        }}
                      />
                    </p>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Play Recording Again" key="2">
                    <Row>
                      <Col span={8} className="text-center">
                        <audio
                          ref="audioSource"
                          controls="controls"
                          src={task[currentContent]["audio_url"]}
                        ></audio>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24} className="margin-bottom-10">
                <Collapse>
                  <Panel header="Record 2nd Answer" key="3">
                    <Row>
                      <Col span={8}>
                        <Row className="text-center">
                          <audio
                            ref="audioSource"
                            controls="controls"
                            src={this.state.blobURL}
                          ></audio>
                        </Row>
                        <Row className="text-center">
                          <ReactMic
                            className="soundwave"
                            record={recording}
                            backgroundColor="#1890ff"
                            audioBitsPerSecond={128000}
                            onStop={this.onRecordingStop.bind(this)}
                            strokeColor="#fff"
                            style={{ width: "80%" }}
                          />
                        </Row>
                        <Row className="text-center">
                          <Tooltip title="Start recording">
                            <Button
                              onClick={this.toggleRecording.bind(this, true)}
                              disabled={recording}
                              type="primary"
                              shape="circle"
                              size="large"
                            >
                              <FaMicrophone />
                            </Button>
                          </Tooltip>
                          &nbsp;&nbsp;
                          <Tooltip title="Stop recording">
                            <Button
                              onClick={this.toggleRecording.bind(this, false)}
                              disabled={!recording}
                              type="primary"
                              shape="circle"
                              icon="pause"
                              size="large"
                            />
                          </Tooltip>
                        </Row>
                      </Col>
                      `
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </div>
          ) : null}
          <Col span={24} className="text-right">
            <Button
              disabled={this.props.sending || this.state.recording}
              onClick={this.handleNextBtn.bind(this)}
              className="next-btn"
            >
              Next
              <Icon type="caret-right" />
            </Button>
          </Col>
        </Row>
        {play_status && !strat_mode_on ? (
          <ReactPlayer
            className="invisible"
            ref={this.ref}
            url={task[currentContent]["audio_url"]}
            playing={play_status}
            onDuration={this.onDuration.bind(this)}
            onEnded={this.handleEndAudio.bind(this)}
            onProgress={this.onProgress.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countdown_timer: state.task4.countdown_timer,
    countdown_started: state.task4.countdown_started,
    task: state.app.examContent,
    currentContent: state.app.currentContent,
    play_status: state.task4.play_status,
    play_duration: state.task4.play_duration,
    play_progress: state.task4.play_progress,
    play_beep: state.task4.play_beep,
    recording_started: state.task4.recording_started,
    recording_timer: state.task4.recording_timer,
    recording_duration: state.task4.recording_duration,
    task4_started: state.task4.task4_started,
    user: state.auth,
    exam_number: state.task4.exam_number,
    begin_timer: state.task4.begin_timer,
    recording_complete: state.task4.recording_complete,
    answerInstance: state.answerInstance,
    sending: state.api.sending,
    strat_mode_on: state.strategy.strat_mode_on,
    instruction: state.strategy.settings.instruction,
    vocabulary: state.strategy.settings.vocabulary,
    beepUrl: state.api.beepUrl,
  };
};

export default connect(mapStateToProps)(RetellLecture);
