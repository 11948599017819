export const TASK20_UPDATE_STARTED = "TASK20_UPDATE_STARTED";
export const TASK20_RESET = "TASK20_RESET";
export const TASK20_DURATION = "TASK20_DURATION";
export const TASK20_TIMER = "TASK20_TIMER";
export const TASK20_UPDATE_ANSWER = "TASK20_UPDATE_ANSWER";
export const TASK20_UPDATE_CURRENT_INDEX = "TASK20_UPDATE_CURRENT_INDEX";
export const TASK20_UPDATE_CURRENT_ANSWER = "TASK20_UPDATE_CURRENT_ANSWER";
export const TASK20_UPDATE_CURRENT_ANSWER2 = "TASK20_UPDATE_CURRENT_ANSWER2";
export const TASK20_UPDATE_ANSWERS = "TASK20_UPDATE_ANSWERS";
export const TASK20_UPDATE_COUNTDOWN_STARTED = "TASK20_UPDATE_COUNTDOWN_STARTED";
export const TASK20_UPDATE_COUNTDOWN_TIMER = "TASK20_UPDATE_COUNTDOWN_TIMER";
export const TASK20_UPDATE_PLAY_STATUS = "TASK20_UPDATE_PLAY_STATUS";
export const TASK20_UPDATE_PLAY_PROGRESS = "TASK20_UPDATE_PLAY_PROGRESS";
export const TASK20_UPDATE_PLAY_ENDED = "TASK20_UPDATE_PLAY_ENDED";