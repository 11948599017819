import * as loginActions from "../app/login";
import * as authActions from "../app/auth";
import * as EXAM from "../../constants/exams";
import * as app from "../app/app";
import * as apiActions from "../app/api";
import * as answerInstanceActions from "../app/answerInstance";
import * as speakingReview from "../app/speakingReview";
import * as readingReview from "../app/readingReview";
import * as listeningReview from "../app/listeningReview";
import * as task1 from "../app/task1";
import * as task2 from "../app/task2";
import * as task3 from "../app/task3";
import * as task4 from "../app/task4";
import * as task5 from "../app/task5";
import * as task6 from "../app/task6";
import * as task7 from "../app/task7";
import * as task8 from "../app/task8";
import * as task9 from "../app/task9";
import * as task10 from "../app/task10";
import * as task11 from "../app/task11";
import * as task12 from "../app/task12";
import * as task13 from "../app/task12";
import * as task14 from "../app/task14";
import * as task15 from "../app/task15";
import * as task16 from "../app/task16";
import * as task17 from "../app/task17";
import * as task18 from "../app/task18";
import * as task19 from "../app/task19";
import * as task20 from "../app/task20";
import { message } from "antd";
import { Auth, API } from "aws-amplify";

const api = "pte-api";

const serverError = (msg) => {
  message.error(msg, 10);
};

const success = (msg) => {
  message.success(msg, 10);
};

export function signup(user, reset) {
  return function(dispatch) {
    const { username, password, first_name, last_name, email } = user;
    Auth.signUp({
      username,
      password,
      attributes: {
        given_name: first_name,
        family_name: last_name,
        email,
        "custom:admin_approved": "false",
      },
    })
      .then(function(response) {
        dispatch(apiActions.updateApiSending(false));
        success(
          "Successfully signed up. Please verify your email before logging in."
        );
        reset();
      })
      .catch(function(error) {
        console.log(error);
        serverError("Something went wrong. Please try again.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function login(user, history) {
  return function(dispatch) {
    Auth.signIn(user.username, user.password)
      .then(function(response) {
        if (response.attributes["custom:admin_approved"] !== "true") {
          Auth.signOut();
          throw new Error("Not admin approved. Cant login.");
        }
        dispatch(apiActions.updateApiSending(false));
        dispatch(loginActions.reset());
        dispatch(authActions.updateIsAuthenticated(true));
        dispatch(authActions.updateUsername(user.username));
        history.push("/dashboard/speaking");
      })
      .catch(function(error) {
        console.log(error);
        dispatch(apiActions.updateApiSending(false));
        serverError(
          "Invalid credentials or email has not been verified. Please try again."
        );
      });
  };
}

export function generateSpeaking(data, history) {
  return function(dispatch) {
    API.post(api, "/exam", { body: { ...data, type: "speaking" } })
      .then(function(response) {
        if (response.exams.length !== 0) {
          dispatch(app.appReset());
          dispatch(task1.reset());
          dispatch(task2.updateSequence());
          dispatch(task3.updateSequence());
          dispatch(task4.updateSequence());
          dispatch(task5.updateSequence());
          dispatch(app.updateExamContent(response.exams));
          dispatch(
            answerInstanceActions.updateAnswerInstance({
              id: response.answer.id,
              data: [],
            })
          );
          dispatch(apiActions.updateApiSending(false));
          history.push("/exam");
        } else {
          dispatch(apiActions.updateApiSending(false));
          serverError("Already finished all the available exams.");
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(apiActions.updateApiSending(false));
        serverError(error);
      });
  };
}

export function generateReading(data, history) {
  return function(dispatch) {
    API.post(api, "/exam", { body: { ...data, type: "reading" } })
      .then(function(response) {
        if (response.exams.length !== 0) {
          dispatch(app.appReset());
          dispatch(task8.reset());
          dispatch(task9.reset());
          dispatch(task10.reset());
          dispatch(task11.reset());
          dispatch(task12.reset());
          const exams = response.exams;
          exams.forEach((exam) => {
            exam.answers = JSON.parse(exam.answers);
            exam.correct_answer = JSON.parse(exam.correct_answer);
          });
          dispatch(app.updateExamContent(exams));
          dispatch(
            answerInstanceActions.updateAnswerInstance({
              id: response.answer.id,
              data: [],
            })
          );
          dispatch(apiActions.updateApiSending(false));
          history.push("/exam");
        } else {
          dispatch(apiActions.updateApiSending(false));
          serverError("Already finished all the available exams.");
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(apiActions.updateApiSending(false));
        serverError(error);
      });
  };
}

export function generateListening(data, history) {
  return function(dispatch) {
    API.post(api, "/exam", { body: { ...data, type: "listening" } })
      .then(function(response) {
        if (response.exams.length !== 0) {
          dispatch(app.appReset());
          dispatch(task13.reset());
          dispatch(task14.reset());
          dispatch(task15.reset());
          dispatch(task16.reset());
          dispatch(task17.reset());
          dispatch(task18.reset());
          dispatch(task19.reset());
          dispatch(task20.reset());
          const exams = response.exams;
          exams.forEach((exam) => {
            if (exam.exam_type === EXAM.TASK14) {
              exam.answers = JSON.parse(exam.answers);
              exam.correct_answer = JSON.parse(exam.correct_answer);
            } else if (exam.exam_type === EXAM.TASK15) {
              exam.correct_answer = JSON.parse(exam.correct_answer);
            } else if (exam.exam_type === EXAM.TASK16) {
              exam.answers = JSON.parse(exam.answers);
              exam.correct_answer = JSON.parse(exam.correct_answer);
            } else if (exam.exam_type === EXAM.TASK17) {
              exam.answers = JSON.parse(exam.answers);
              exam.correct_answer = JSON.parse(exam.correct_answer);
            } else if (exam.exam_type === EXAM.TASK18) {
              exam.answers = JSON.parse(exam.answers);
              exam.correct_answer = JSON.parse(exam.correct_answer);
            } else if (exam.exam_type === EXAM.TASK19) {
              exam.correct_answer = JSON.parse(exam.correct_answer);
            } else if (exam.exam_type === EXAM.TASK20) {
              exam.correct_answer = JSON.parse(exam.correct_answer);
            }
          });
          dispatch(app.updateExamContent(exams));
          dispatch(
            answerInstanceActions.updateAnswerInstance({
              id: response.answer.id,
              data: [],
            })
          );
          dispatch(apiActions.updateApiSending(false));
          history.push("/exam");
        } else {
          dispatch(apiActions.updateApiSending(false));
          serverError("Already finished all the available exams.");
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(apiActions.updateApiSending(false));
        serverError(error.response);
      });
  };
}

export function generateWriting(data, history) {
  return function(dispatch) {
    API.post(api, "/exam", { body: { ...data, type: "writing" } })
      .then(function(response) {
        if (response.exams.length !== 0) {
          dispatch(app.appReset());
          dispatch(task6.reset());
          dispatch(task7.reset());
          const exams = response.exams;
          dispatch(app.updateExamContent(exams));
          dispatch(
            answerInstanceActions.updateAnswerInstance({
              id: response.answer.id,
              data: [],
            })
          );
          dispatch(apiActions.updateApiSending(false));
          history.push("/exam");
        } else {
          dispatch(apiActions.updateApiSending(false));
          serverError("Already finished all the available exams.");
        }
      })
      .catch(function(error) {
        console.log(error);
        dispatch(apiActions.updateApiSending(false));
        serverError(error.response.error);
      });
  };
}

export function updateAnswerInstance(answerInstance, examType) {
  return function(dispatch) {
    answerInstance.data = JSON.stringify(answerInstance.data);
    API.patch(api, `/answer/${examType}`, { body: answerInstance })
      .then(function(response) {
        const answer = response;
        answer.data = JSON.parse(answer.data);
        dispatch(answerInstanceActions.updateAnswerInstance(answer));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function(error) {
        console.log(error);
        alert(error);
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateAnswerInstanceWithHistory(
  answerInstance,
  history,
  examType
) {
  return function(dispatch) {
    answerInstance.data = JSON.stringify(answerInstance.data);
    API.patch(api, `/answer/${examType}`, { body: answerInstance })
      .then(function(response) {
        const answer = response;
        answer.data = JSON.parse(answer.data);
        dispatch(answerInstanceActions.updateAnswerInstance(answer));
        history.push("/review");
        dispatch(apiActions.updateApiSending(false));
        dispatch(app.updateAppLoading(false));
      })
      .catch(function(error) {
        alert(error);
        dispatch(apiActions.updateApiSending(false));
        dispatch(app.updateAppLoading(false));
        console.log(error);
      });
  };
}

export function getAnswerInstances(examType) {
  return function(dispatch) {
    API.get(api, `/answer/${examType}`, {})
      .then(function(response) {
        const answers = response;
        answers.forEach((answer) => {
          if (answer.data.trim()) {
            answer.data = JSON.parse(answer.data);
          }
        });
        dispatch(speakingReview.updateAnswerInstances(answers));
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
}

export function getSpeakingQuestionsCount() {
  return function(dispatch) {
    API.get(api, "/exam-count", {})
      .then(function(response) {
        const count = response;
        dispatch(speakingReview.updateExam1Count(count.exam1));
        dispatch(speakingReview.updateExam2Count(count.exam2));
        dispatch(speakingReview.updateExam3Count(count.exam3));
        dispatch(speakingReview.updateExam4Count(count.exam4));
        dispatch(speakingReview.updateExam5Count(count.exam5));
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
}

export function getQuestionsCount() {
  return function(dispatch) {
    API.get(api, "/exam-count", {})
      .then(function(response) {
        const count = response;
        dispatch(readingReview.updateExam8Count(count.exam8));
        dispatch(readingReview.updateExam9Count(count.exam9));
        dispatch(readingReview.updateExam10Count(count.exam10));
        dispatch(readingReview.updateExam11Count(count.exam11));
        dispatch(readingReview.updateExam12Count(count.exam12));
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
}

export function getListeningQuestionsCount() {
  return function(dispatch) {
    API.get(api, "/exam-count", {})
      .then(function(response) {
        dispatch(
          listeningReview.updateExamCount({
            exam13Count: response.exam13,
            exam14Count: response.exam14,
            exam15Count: response.exam15,
            exam16Count: response.exam16,
            exam17Count: response.exam17,
            exam18Count: response.exam18,
            exam19Count: response.exam19,
            exam20Count: response.exam20,
          })
        );
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
}

export function getWritingQuestionsCount() {
  return function(dispatch) {
    API.get(api, "/exam-count", {})
      .then(function(response) {
        dispatch(
          listeningReview.updateExamCount({
            exam1Count: response.exam1,
            exam2Count: response.exam2,
            exam3Count: response.exam3,
            exam4Count: response.exam4,
            exam5Count: response.exam5,
            exam6Count: response.exam6,
            exam7Count: response.exam7,
            exam8Count: response.exam8,
            exam9Count: response.exam9,
            exam10Count: response.exam10,
            exam11Count: response.exam11,
            exam12Count: response.exam12,
            exam13Count: response.exam13,
            exam14Count: response.exam14,
            exam15Count: response.exam15,
            exam16Count: response.exam16,
            exam17Count: response.exam17,
            exam18Count: response.exam18,
            exam19Count: response.exam19,
            exam20Count: response.exam20,
          })
        );
      })
      .catch(function(error) {
        alert(error);
        console.log(error);
      });
  };
}

export async function deleteExam(examType, examId) {
  return await API.del(
    api,
    encodeURI(`/answer/${examType}/${examId.split("#")[1]}`),
    {}
  )
    .then((response) => {
      return true;
    })
    .catch((error) => {
      console.log("Unable to delete answer", error);
      return null;
    });
}
