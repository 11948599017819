import React from "react";
import * as Common from "../common";
import * as Tasks from "./index";
import * as EXAM_TYPES from "../../constants/exams";
import * as actions from "../../actions/app/app";
import { connect } from "react-redux";

class Container extends React.Component {
  handleNext = () => {
    const { dispatch, currentContent } = this.props;
    dispatch(actions.updateCurrentContent(currentContent + 1));
  };
  handleBack = () => {
    const { dispatch, currentPage } = this.props;
    dispatch(actions.updateCurrentPage(currentPage - 1));
  };
  render() {
    const { task, currentContent } = this.props;
    if (currentContent >= task.length) {
      this.props.history.push("/dashboard/speaking");
    }
    return (
      <div className="main-content">
        {
          //<Common.LearningStratSwitch/>
        }
        <Common.Timer />
        <Common.Progress />
        {task[currentContent].exam_type !== EXAM_TYPES.TASK8 ? (
          <Common.Instruction />
        ) : null}
        {task[currentContent].exam_type === EXAM_TYPES.TASK0 ? (
          <Tasks.TaskZero />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK1 ? (
          <Tasks.ReadAloud history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK2 ? (
          <Tasks.RepeatSentence history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK3 ? (
          <Tasks.DescribeImage history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK4 ? (
          <Tasks.RetellLecture history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK5 ? (
          <Tasks.AnswerShortQuestions history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK6 ? (
          <Tasks.SummarizeWrittenText history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK7 ? (
          <Tasks.Essay history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK8 ? (
          <Tasks.ReadingMCQ history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK9 ? (
          <Tasks.ReadingCheckbox history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK10 ? (
          <Tasks.ArrangeSentences history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK11 ? (
          <Tasks.FillInBlanks history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK12 ? (
          <Tasks.FillInSelect history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK13 ? (
          <Tasks.SummarizeSpokenText history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK14 ? (
          <Tasks.ListeningCheckbox history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK15 ? (
          <Tasks.ListeningFillInBlank history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK16 ? (
          <Tasks.HighlightCorrectSummary history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK17 ? (
          <Tasks.ListeningMCQ history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK18 ? (
          <Tasks.SelectMissingWord history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK19 ? (
          <Tasks.HighlightWords history={this.props.history} />
        ) : task[currentContent].exam_type === EXAM_TYPES.TASK20 ? (
          <Tasks.ListeningRepeatSentence history={this.props.history} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.app.currentContent,
    currentPage: state.app.currentPage,
    task: state.app.examContent,
  };
};

export default connect(mapStateToProps)(Container);
