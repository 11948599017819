import * as actions from '../actions/types/task15';

const defaultState = {
  choices:[],
  answers: [],
  choices2:[],
  answers2: [],
  chosenAnswer:"",
  countdown_started: false,
  play_status: false,
  play_ended: false,
  progress: 0,
  duration: 0,
  timer: 0,
  countdown_timer: 0,
  started: false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TASK15_UPDATE_STARTED:{
      return {...state, started:action.payload};
    }
    case actions.TASK15_UPDATE_ANSWERS: {
      return {...state, answers:action.payload};
    }
    case actions.TASK15_UPDATE_CHOICES: {
      return {...state, choices:action.payload};
    }
    case actions.TASK15_UPDATE_ANSWERS2: {
      return {...state, answers2:action.payload};
    }
    case actions.TASK15_UPDATE_CHOICES2: {
      return {...state, choices2:action.payload};
    }
    case actions.TASK15_UPDATE_CHOSEN_ANSWER: {
      return {...state, chosenAnswer:action.payload};
    }
    case actions.TASK15_RESET: {
      return {...defaultState};
    }
    case actions.TASK15_DURATION: {
      return {...state, duration:action.payload};
    }
    case actions.TASK15_UPDATE_PLAY_STATUS: {
      return {...state, play_status:action.payload};
    }
    case actions.TASK15_UPDATE_PLAY_PROGRESS: {
      return {...state, progress:action.payload};
    }
    case actions.TASK15_UPDATE_PLAY_ENDED: {
      return {...state, play_ended:action.payload};
    }
    case actions.TASK15_TIMER:{
      return {...state, timer:action.payload};
    }
    case actions.TASK15_UPDATE_COUNTDOWN_STARTED:{
      return {...state, countdown_started:action.payload};
    }
    case actions.TASK15_UPDATE_COUNTDOWN_TIMER:{
      return {...state, countdown_timer:action.payload};
    }
    default: return {...state};
  }
};