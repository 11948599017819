export const TASK15_RESET = "TASK15_RESET";
export const TASK15_UPDATE_CHOICES = "TASK15_UPDATE_CHOICES";
export const TASK15_UPDATE_ANSWERS = "TASK15_UPDATE_ANSWERS";
export const TASK15_UPDATE_CHOSEN_ANSWER = "TASK15_UPDATE_CHOSEN_ANSWER";
export const TASK15_UPDATE_PLAY_STATUS = "TASK15_UPDATE_PLAY_STATUS";
export const TASK15_UPDATE_PLAY_PROGRESS = "TASK15_UPDATE_PLAY_PROGRESS";
export const TASK15_UPDATE_PLAY_ENDED = "TASK15_UPDATE_PLAY_ENDED";
export const TASK15_DURATION = "TASK15_DURATION";
export const TASK15_TIMER = "TASK15_TIMER";
export const TASK15_UPDATE_COUNTDOWN_STARTED = "TASK15_UPDATE_COUNTDOWN_STARTED";
export const TASK15_UPDATE_COUNTDOWN_TIMER = "TASK15_UPDATE_COUNTDOWN_TIMER";
export const TASK15_UPDATE_STARTED = "TASK15_UPDATE_STARTED";

export const TASK15_UPDATE_CHOICES2 = "TASK15_UPDATE_CHOICES2";
export const TASK15_UPDATE_ANSWERS2 = "TASK15_UPDATE_ANSWERS2";