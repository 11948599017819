import React from 'react';
import { Row, Col, Progress } from 'antd';
import { ReactMic } from 'react-mic';

import * as Common from './index';

function padZero(num) {
  var zero = 2 - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export const AudioRecorder = (props) => {
    const { recording_countdown, task, currentContent  } = props;
    const record_duration = padZero(task[currentContent].record_duration);
    const time_remaining = padZero(record_duration - recording_countdown);
    const percent_done = ((record_duration - recording_countdown) / record_duration) * 100;
    return (
      <Row className="text-center margin-top-30 padding-10">
        {props.countdown_status?<Common.Beep {...props} />:null}
        <Row className="graybox text-center">
          <Col span={24} className="margin-top-10">
           {
            props.strat_mode_on?
              <p className="countdown">
                Recording complete
              </p>
            :
             props.countdown_status?
              <p className="countdown">
                Recording in {props.countdown}
              </p>
             :
             props.recording?
              <p className="countdown">
                Recording
              </p>
             :
             !props.recording || props.strat_mode_on?
              <p className="countdown">
                Recording complete
              </p>
             :
              <p className="countdown">
                Please wait
              </p>
           }
          </Col>
          <Col span={24} className="margin-top-10">
            <p>
              <Progress
                className="width-25"
                percent={props.strat_mode_on?100:percent_done}
                showInfo={false}
                strokeColor={"#0081A4"}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;00:{props.strat_mode_on?record_duration:time_remaining} / 00:{record_duration}
            </p>
          </Col>
          <Col span={24} className="text-center">
            {
              props.strat_mode_on?
                null
              :
              <ReactMic
                className="soundwave"
                record={props.recording}
                onStop={props.onStop}
                strokeColor={"#006480"}
                backgroundColor={"#fff"}
              />
            }
          </Col>
        </Row>
      </Row>
    );
}