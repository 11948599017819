import * as strategy from '../types/strategy';

export const updateReadingTImer = (payload) => {
  return dispatch => {
    dispatch({
      type    : strategy.UPDATE_READING_TIMER,
      payload : payload,
    });
  };
};

export const updateListeningTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : strategy.UPDATE_LISTENING_TIMER,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : strategy.LEARNING_STRAT_RESET,
    });
  };
};

export const updateLearningStratStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : strategy.UPDATE_LEARNING_STRAT_STARTED,
      payload : payload,
    });
  };
};

export const updateLearningStratModeOn = (payload) => {
  return dispatch => {
    dispatch({
      type    : strategy.UPDATE_LEARNING_STRAT_MODE_ON,
      payload : payload,
    });
  };
};

export const updateLearningStratSettings = (payload) => {
  return dispatch => {
    dispatch({
      type    : strategy.UPDATE_LEARNING_STRAT_SETTINGS,
      payload : payload,
    });
  };
};

